import axios from 'axios'
import qs from 'qs'

const BASE_API_PATH = `/api`

export const apiRoutes = {
  quotes: async (params: Record<string, any>) => {
    const url = `${BASE_API_PATH}/quotes/funnel/`
    const { data } = await axios.get(`${url}?${qs.stringify(params)}`)
    return data
  },
  quotesWithoutProductDetermination: async (
    params: Record<string, any>,
    productString?: string
  ) => {
    const url = `${BASE_API_PATH}/quotes/`
    const { data } = await axios.get(
      `${url}?${qs.stringify({
        ...params,
        productString,
      })}`
    )

    return data
  },

  validatePhoneTwilio: async (phoneNumber: string) => {
    const url = `${BASE_API_PATH}/twilio/validatePhone/`
    const { data } = await axios.post(url, { phoneNumber })
    return data
  },
}
