import { FINAL_EXPENSE_FAKE_PREINTERVIEW } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useFeFakePreInterview = () => {
  const { isLoading, variation } = useDecisionForFlag({
    name: FINAL_EXPENSE_FAKE_PREINTERVIEW.EXPERIMENT_KEY,
    fallbackVariation: FINAL_EXPENSE_FAKE_PREINTERVIEW.VARIATIONS.CONTROL,
  })

  const getClickthroughUrl = (defaultUrl = '') => {
    if (variation === FINAL_EXPENSE_FAKE_PREINTERVIEW.VARIATIONS.DELAYED_PII) {
      return '/app/flow/final-expense-delayed-pii'
    } else if (
      variation === FINAL_EXPENSE_FAKE_PREINTERVIEW.VARIATIONS.GROWTH_WINS
    ) {
      return '/app/flow/final-expense-growth-wins'
    } else {
      return defaultUrl
    }
  }

  return {
    isLoading,
    variation,
    getClickthroughUrl,
  }
}
