import React from 'react'

import Link from 'next/link'

import { COVERAGE_SLIDER, TOOLTIP, WHOLE_LIFE_BLURB } from '@/constants'
import EmailModal from '@/shared/Estimate/EmailModal/EmailModal'
import { useEmailModal } from '@/shared/Estimate/EmailModal/useEmailModal'
import { EstimateWidgetTooltip } from '@/shared/Estimate/EstimateWidgetTooltip'
import { PriceRangeDisplay } from '@/shared/Estimate/PriceRangeDisplay'
import { CoverageSlider, TermSlider } from '@/shared/Estimate/Sliders'
import classNames from 'classnames'
import { Body2, Caption2, Spacer, TitleMedium2 } from 'ethos-design-system'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { Product } from 'lib/@getethos/constants'

import {
  EstimateWidgetImageLayout,
  EstimateWidgetModuleData,
} from '@/components/EstimateWidget/EstimateWidget'
import { FinalExpenseCoverageSlider } from '@/components/EstimateWidget/common/FinalExpenseCoverageSlider'
import { DATA_TID } from '@/components/EstimateWidget/common/data-tid'
import { useEstimateQuotesContext } from '@/components/EstimateWidget/contexts/EstimateQuotesContext'
import { useEstimateWidgetContext } from '@/components/EstimateWidget/contexts/EstimateWidgetContext'
import { EstimateWidgetProgress } from '@/components/EstimateWidget/types/widget'

import { useDirectToAppUx } from '@/hooks/features/useDirectToAppUx'

import { Disclaimer } from '../../Disclaimer'
import styles from '../../Floating.module.scss'
import { TermButtons } from '../../TermButtons'
import { SofiDisclaimer } from '../components/partners/SofiDisclaimer'
import { DirectToAppRangeForm } from '../experiments/DirectToAppRangeForm/DirectToAppRangeForm'
import { FinalExpenseRangeForm } from '../experiments/FinalExpenseRangeForm/FinalExpenseRangeForm'
import { FloatingRangeFormError } from './components/FloatingRangeFormError'
import { FormButtons } from './components/FormButtons'

interface FloatingRangeFormRefactorProps {
  moduleData: EstimateWidgetModuleData
  estimateCopy: string
  asta?: boolean
}

const AstaTitle = () => {
  return (
    <div className={'block w-full pb-6 pt-2 text-center md:pt-20'}>
      <TitleMedium2.Serif.Book500>Your estimate</TitleMedium2.Serif.Book500>
      <Caption2.Regular400 elementClasses={styles.priceDisclaimer}>
        This is an initial estimate. You’ll get your final rate and approval
        decision in just a few minutes.
      </Caption2.Regular400>
    </div>
  )
}

export const getStyles = ({
  asta,
  showSinglePrice,
  finalExpense,
}: {
  asta?: boolean
  showSinglePrice?: boolean
  finalExpense?: boolean
}) => {
  const singlePriceClasses = [styles.estimateCopy]
  let containerStyles = 'p-6 bg-white rounded-lg'
  const formStyles = [styles.rangeForm]
  const pricesClassName = asta ? [styles.astaPrices] : [styles.prices]

  if (showSinglePrice) {
    singlePriceClasses.push(styles.estimateCopySinglePrice)
  }

  if (asta) {
    formStyles.push(styles.asta)
    containerStyles +=
      ' max-w-full md:max-w-sm xl:max-w-xl w-144 border border-solid border-1 border-gray-2 rounded-sm p-6'
  } else {
    containerStyles += ' max-w-sm'
  }

  if (finalExpense) {
    containerStyles += ' !max-w-full w-full lg:w-[535px]'
  }

  pricesClassName.push(styles.pricesMarginLean)

  return {
    singlePriceClasses,
    containerStyles,
    formStyles,
    pricesClassName,
  }
}

export const FloatingRangeFormRefactor: React.FC<
  FloatingRangeFormRefactorProps
> = ({ moduleData, estimateCopy, asta }) => {
  const {
    widgetProgressControls: { setWidgetProgress },
    userDataControls: { userData },
  } = useEstimateWidgetContext()

  const { isTreatment } = useDirectToAppUx()

  const {
    userSelectedValues,
    maxCoverage,
    handleCoverageChange,
    handleTermChange,
    previousRanges,
    ranges,
    terms,
    product,
    error,
    setError,
  } = useEstimateQuotesContext()

  const {
    openEmailModal,
    email,
    emailModal,
    emailModalClose,
    emailSuccess,
    saveEmailFormCallback,
  } = useEmailModal({
    userData: userData,
    minPrice: ranges.min,
    maxPrice: ranges.max,
    term: userSelectedValues?.term.toString(),
    coverage: userSelectedValues?.coverage.toString(),
  })

  const {
    showSinglePrice = false,
    dailyPremium = false,
    variation,
    coverageLabel,
    continueLabel,
    saveLabel,
    cypressForm,
    disableSave,
    salamanderForm,
    dailyDollars,
    backgroundColor,
    termLabel,
    disclaimerColor,
    disclaimerText,
  } = moduleData

  const formButtonLabels = {
    continueLabel: continueLabel || 'Continue',
    saveLabel: saveLabel || 'Save',
    editAnswersLabel: asta ? 'Edit my answers' : 'Edit Answers',
  }

  const isFinalExpense =
    moduleData.imageLayout === EstimateWidgetImageLayout.FinalExpense

  const formButtonFlags = {
    asta,
    cypressForm,
    disableSave,
    salamanderForm,
    useRatesCopy: moduleData.ratesCopy,
  }

  const { containerStyles, formStyles, pricesClassName, singlePriceClasses } =
    getStyles({ asta, showSinglePrice, finalExpense: isFinalExpense })

  const shouldRenderCoverageSlider =
    product !== Product.GI && product !== Product.FE

  const shouldRenderWholeLife = product === Product.GI

  const shouldRenderFinalExpenseSlider = product === Product.FE

  if (error)
    return (
      <div
        className={classNames(
          formStyles.join(' '),
          isTreatment ? 'flex justify-center' : ''
        )}
      >
        <div
          className={classNames(
            containerStyles,
            isTreatment ? 'w-full max-w-[500px]' : ''
          )}
        >
          <div
            className={classNames(styles.rangeFormInner, {
              '!justify-center': isFinalExpense,
            })}
          >
            <FloatingRangeFormError
              errorMessage={error}
              onEditAnswers={() => {
                estimateWidgetAnalytics.editAnswers()
                setWidgetProgress(EstimateWidgetProgress.INFO)
                setError(null)
              }}
              finalExpense={isFinalExpense}
            />
          </div>
        </div>
      </div>
    )

  if (isFinalExpense) return <FinalExpenseRangeForm moduleData={moduleData} />
  if (isTreatment) return <DirectToAppRangeForm moduleData={moduleData} />

  return (
    <>
      <SofiDisclaimer />
      <div className={formStyles.join(' ')}>
        {asta && <AstaTitle />}
        <div className={containerStyles}>
          <div className={styles.rangeFormInner}>
            <div className={styles.rangeFormFieldsContainer}>
              <div className={styles.rangeFormFields}>
                <div className={styles.rangeFormFieldsTopLean}>
                  {!asta && (
                    <h3 className={singlePriceClasses.join(' ')}>
                      {showSinglePrice ? (
                        <Body2.Medium500>{estimateCopy}</Body2.Medium500>
                      ) : (
                        <Caption2.Medium500>{estimateCopy}</Caption2.Medium500>
                      )}
                      <EstimateWidgetTooltip
                        details={
                          dailyPremium
                            ? TOOLTIP.ESTIMATE_RATE_DAILY.MESSAGE
                            : TOOLTIP.ESTIMATE_RATE.MESSAGE
                        }
                        label={
                          dailyPremium
                            ? TOOLTIP.ESTIMATE_RATE_DAILY.LABEL
                            : TOOLTIP.ESTIMATE_RATE.LABEL
                        }
                        popperBoxStyles={styles.tooltipPopperBoxStyles}
                        placement="right"
                        softCorners
                      />
                    </h3>
                  )}
                </div>
                <div className={pricesClassName.join(' ')}>
                  <PriceRangeDisplay
                    styles={styles}
                    minRangeStart={previousRanges.min}
                    minRangeEnd={ranges.min}
                    maxRangeStart={previousRanges.max}
                    maxRangeEnd={ranges.max}
                    variation={variation}
                    dailyDollars={dailyDollars}
                    dailyPremium={dailyPremium}
                    showSinglePrice={showSinglePrice}
                    asta={asta}
                  />
                </div>
                {shouldRenderFinalExpenseSlider && (
                  <FinalExpenseCoverageSlider
                    coverageLabel={coverageLabel || ''}
                    styles={styles}
                    variation={variation}
                    termLabel={termLabel}
                    coverage={userSelectedValues?.coverage || 0}
                    maxCoverage={maxCoverage}
                    handleCoverageChange={handleCoverageChange}
                  />
                )}
                {shouldRenderWholeLife && (
                  <>
                    <div className="text-gray-3">
                      <Caption2.Regular400>{termLabel}</Caption2.Regular400>
                    </div>
                    <Spacer.H4 />
                    <Body2.Regular400>Whole Life</Body2.Regular400>
                    <Spacer.H32 />
                    <div className="text-gray-3">
                      <Caption2.Regular400>
                        {WHOLE_LIFE_BLURB.INTRO}
                      </Caption2.Regular400>
                    </div>
                    <Spacer.H24 />
                    <Link href={WHOLE_LIFE_BLURB.LINK}>
                      <span className="text-forest underline">
                        {WHOLE_LIFE_BLURB.CTA}
                      </span>
                    </Link>
                  </>
                )}
                {shouldRenderCoverageSlider && (
                  <div className={styles.sliders}>
                    <CoverageSlider
                      coverageLabel={coverageLabel || ''}
                      coverage={userSelectedValues?.coverage || 0}
                      minCoverage={COVERAGE_SLIDER.MIN}
                      maxCoverage={maxCoverage}
                      coverageStep={COVERAGE_SLIDER.STEP}
                      handleCoverageChange={handleCoverageChange}
                      dataTid={DATA_TID.COVERAGE_INPUT}
                      styles={styles}
                      variation={variation}
                      enableTooltip
                    />
                    {asta ? (
                      <TermButtons
                        maxTerm={terms[terms.length - 1]}
                        initialTerm={userSelectedValues?.term || terms[0]}
                        termToggle={handleTermChange}
                      />
                    ) : (
                      <TermSlider
                        termLabel={termLabel || ''}
                        term={userSelectedValues?.term || terms[0]}
                        minTerm={terms[0]}
                        maxTerm={terms[terms.length - 1]}
                        handleTermChange={handleTermChange}
                        dataTid={DATA_TID.TERM_INPUT}
                        availableTerms={terms}
                        backgroundColor={backgroundColor}
                        styles={styles}
                        variation={variation}
                        enableTooltip
                      />
                    )}
                  </div>
                )}
              </div>
              <FormButtons
                openEmailModal={openEmailModal}
                styles={styles}
                labels={formButtonLabels}
                flags={formButtonFlags}
              />
            </div>
          </div>
        </div>
        {!asta && (
          <Disclaimer color={disclaimerColor}>{disclaimerText}</Disclaimer>
        )}
        <EmailModal
          emailModal={emailModal}
          emailModalClose={emailModalClose}
          email={email}
          emailSuccess={emailSuccess}
          saveEmailFormCallback={saveEmailFormCallback}
          emailHeading={moduleData.emailHeading || ''}
          emailSubHeading={moduleData.emailSubHeading || ''}
          emailSendLabel={moduleData.emailSendLabel || ''}
          variation={moduleData.variation}
          emailFieldDataTid={DATA_TID.EMAIL_INPUT}
          buttonTid={DATA_TID.EMAIL_BUTTON}
        />
      </div>
    </>
  )
}
