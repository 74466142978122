/**
 * Represents the Ethos products.
 */
export enum Product {
  Term = 'Term',
  SI = 'SI',
  GI = 'GI',
  FE = 'FE',
}

export enum DesiredCoverageAmount {
  Unknown = 'Unknown',
  $100_000 = '100000',
  $250_000 = '250000',
  $500_000 = '500000',
  $1_000_000 = '1000000',
  $1_500_000 = '1500000',
  $2_000_000 = '2000000',
  $2_500_000 = '2500000',
  $3_000_000 = '3000000',
  $3_500_000 = '3500000',
  $4_000_000 = '4000000',
  $4_500_000 = '4500000',
  $5_000_000 = '5000000',
}

/**
 * Credit score buckets
 */
export enum CreditScore {
  High = 'High',
  Average = 'Average',
  Low = 'Low',
  NoAnswer = 'NoAnswer',
}

export enum HealthClass {
  PreferredPlus = 'PreferredPlus',
  Preferred = 'Preferred',
  Standard = 'Standard',
}

export enum TobaccoClass {
  Tobacco = 'Tobacco',
  NonTobacco = 'NonTobacco',
}

/** 760 = TRL 32 | 710 = TRL 48 | 650 = TRL 64.
 * See https://docs.google.com/spreadsheets/d/1ChsZXC8c-5lksr3i1mmQ_AV5DWTg-BUpVPJHJKobO14/edit
 * and https://docs.google.com/document/d/1xa0cL9g-LOMpoDd2Rgz7MqE-rGCf2UrcHr5gOJ51rhc/edit */
export enum QuoteFunnelCreditScore {
  High = 760,
  Average = 710,
  Low = 650,
}

// Max age for LGA Term product users
export const LGA_AGE_LIMIT = 65

/** Final Expense MIN & MAX age */
export const FE_AGE_LIMIT = {
  MIN: 66,
  MAX: 85,
}

/** Ages at which GI begins and ends */
export const GI_AGE_LIMIT = {
  MIN: 66,
  MAX: 85,
}

/** Generic error message */
export const GENERIC_ERROR =
  'Something went wrong on our end! Please try again or contact us for assistance.'

/**
 * URL formatting final values
 */
export enum Gender {
  Male = 'Male',
  Female = 'Female',
}
export enum Health {
  Average = '1',
  Great = '2',
  Excellent = '3',
}
export enum Smoker {
  True = 'true',
  False = 'false',
}

// export const LOCAL_STORAGE = {
//   VISIBLE_MODULES: 'visibleModules',
// }

export const FORBES_UTM_TERM = '1955282'

export const PRODUCTS_FOR_QUOTE = {
  TAWL: 'trustage|tawl',
}
