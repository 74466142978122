import React from 'react'

import { Button } from 'ethos-design-system'

import { DATA_TID } from '../common/data-tid'
import styles from './HeroBoxed.module.scss'

interface UserInfoFields {
  field: (name: string) => JSX.Element
  getFormIsValid: () => boolean
  disclaimerText?: string
  scrollToTop: () => void
  showDisclaimer: boolean
  displayEstimatedCredit?: boolean
  ctaText: string
}

// Version 1: no estimated credit question
const OriginalFields = ({
  field,
  getFormIsValid,
  scrollToTop,
  ctaText,
}: UserInfoFields): JSX.Element => (
  <div className={styles.fieldsV1} data-tid={DATA_TID.FIELDS1}>
    <div className={styles.row}>
      <div className={[styles.field, styles.gender].join(' ')}>
        {field('gender')}
      </div>
      <div className={[styles.field, styles.birthDate].join(' ')}>
        {field('birthDate')}
      </div>
      <div className={[styles.field, styles.zipCode].join(' ')}>
        {field('zipCode')}
      </div>
    </div>
    <div className={styles.row}>
      <div className={[styles.field, styles.health].join(' ')}>
        {field('health')}
      </div>
      <div className={[styles.field, styles.smoker].join(' ')}>
        {field('smoker')}
      </div>
    </div>

    <div className="flex w-full justify-end pb-6 pt-2">
      <Button.Medium.Black
        disabled={!getFormIsValid()}
        arrowIcon
        data-tid={DATA_TID.SUBMIT_BUTTON}
        type="submit"
        onClick={scrollToTop}
      >
        {ctaText}
      </Button.Medium.Black>
    </div>
  </div>
)

// Version 2: include estimated credit question and new field order
const WithCreditScore = ({
  field,
  getFormIsValid,
  scrollToTop,
  ctaText,
}: UserInfoFields): JSX.Element => (
  <div className={styles.fieldsV2} data-tid={DATA_TID.FIELDS1}>
    <div className={styles.container}>
      <div className={[styles.field, styles.gender].join(' ')}>
        {field('gender')}
      </div>
      <div className={[styles.field, styles.health].join(' ')}>
        {field('health')}
      </div>
      <div className={[styles.field, styles.birthDate].join(' ')}>
        {field('birthDate')}
      </div>
      <div className={styles.lineBreak} />
      <div className={[styles.field, styles.smoker].join(' ')}>
        {field('smoker')}
      </div>
      <div className={[styles.field, styles.estimatedCredit].join(' ')}>
        {field('estimatedCredit')}
      </div>
      <div className={[styles.field, styles.zipCode].join(' ')}>
        {field('zipCode')}
      </div>
    </div>

    <div className="flex w-full justify-end pb-6 pt-2">
      <Button.Medium.Black
        disabled={!getFormIsValid()}
        arrowIcon
        data-tid={DATA_TID.SUBMIT_BUTTON}
        type="submit"
        onClick={scrollToTop}
      >
        {ctaText}
      </Button.Medium.Black>
    </div>
  </div>
)

export const UserInfoFields = (props: UserInfoFields): JSX.Element =>
  props.displayEstimatedCredit ? (
    <WithCreditScore {...props} />
  ) : (
    <OriginalFields {...props} />
  )
