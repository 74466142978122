import { useCallback, useMemo, useRef } from 'react'

import { useModuleContext } from '@/contexts/ModuleContext'
import { StorageKeys, Store } from '@/services/storage'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
// import { createLead } from 'lib/@getethos/api/createLead'
import { browserLogger } from 'lib/Logger/browserLogs'

import { EstimateWidgetModuleData } from '@/components/EstimateWidget/EstimateWidget'
import { useEstimateQuotesContext } from '@/components/EstimateWidget/contexts/EstimateQuotesContext'
import { useEstimateWidgetContext } from '@/components/EstimateWidget/contexts/EstimateWidgetContext'
import { EstimateWidgetProgress } from '@/components/EstimateWidget/types/widget'

import { useFeEstWidgetWithPhone } from '@/hooks/features/useFeEstWidgetWithPhone'
import { useUpdatedCpFeLp } from '@/hooks/features/useUpdatedCpFeLp'

// import { LeadSource, prepareDataForSalesForce } from '../LeadForm/utils'
import finalExpenseInfoFormStyles from './FinalExpenseInfoForm.module.scss'
import { FinalExpenseForm } from './Forms/FinalExpenseForm'
import { FinalExpenseStateForm } from './Forms/FinalExpenseStateForm'
import FinalExpenseFormWithPhone from './Forms/FinalExpenseWithPhone'

export const FinalExpenseInfoWrapper = ({
  moduleData,
}: {
  moduleData: EstimateWidgetModuleData
}) => {
  const { isTreatment1, isTreatment2 } = useUpdatedCpFeLp()
  const { isTreatment1: isEstWidgetWithPhoneTreatment1 } =
    useFeEstWidgetWithPhone({
      demoTreatmentVariation: moduleData.treatmentVariant,
    })

  const touchedFieldsRef = useRef<Array<string>>([])

  const {
    widgetProgressControls: { setWidgetProgress },
    userDataControls: { setUserData, userData },
  } = useEstimateWidgetContext()

  const { userSelectedValues } = useEstimateQuotesContext()

  const {
    moduleControls: { setHideModules },
    footerControls: { hideFooter },
  } = useModuleContext()

  const store = useMemo(() => new Store('localStorage'), [])

  const goToResults = useCallback(() => {
    if (isTreatment1 || isTreatment2) {
      setHideModules((module) => module?.onePageCandidate)
      hideFooter()
      setWidgetProgress(EstimateWidgetProgress.LEAD_FORM)
      estimateWidgetAnalytics.continueToLeadForm({
        properties: {
          triedToEditAnswers: !!userSelectedValues,
        },
      })
      return
    }
    setWidgetProgress(EstimateWidgetProgress.PRICES)
    estimateWidgetAnalytics.continueToPrices({
      properties: {
        triedToEditAnswers: !!userSelectedValues,
      },
    })
  }, [
    hideFooter,
    isTreatment2,
    isTreatment1,
    setHideModules,
    setWidgetProgress,
    userSelectedValues,
  ])

  const trackAnalyticsEvent = useCallback(
    async (data: Record<string, unknown>) => {
      const wasLeadFormSubmitted = store.getItem<boolean>(
        StorageKeys.LeadFormSubmitted
      )
      if (wasLeadFormSubmitted) return

      // const analyticsData = prepareDataForSalesForce(
      //   data,
      //   LeadSource.FE_WIDGET_WITH_PHONE
      // )
      try {
        // await createLead({ data: analyticsData })
        // store.setItem(StorageKeys.LeadFormSubmitted, true)
      } catch (error) {
        browserLogger.error(
          '[trackAnalyticsEvent] Error submitting analytics',
          { data },
          error as Error
        )
      }
    },
    [store]
  )

  const handleSubmit = useCallback(
    (formValues: Record<string, unknown>, trackSalesforce = false) => {
      if (formValues?.region === 'NY') {
        window.location.assign(`/life/youre-in-new-york/`)
        return
      }

      const shouldHardCodeRegion =
        !isTreatment2 && !isTreatment1 && !isEstWidgetWithPhoneTreatment1 // If it's not treatment 1 or 2, we should hard code the region to 'AL'

      const modifiedValues = {
        ...formValues,
        ...(shouldHardCodeRegion && {
          region: 'AL',
        }),
      }

      if (trackSalesforce) {
        trackAnalyticsEvent(modifiedValues)
      }

      setUserData(modifiedValues)
      goToResults()
    },
    [
      isTreatment2,
      isTreatment1,
      isEstWidgetWithPhoneTreatment1,
      setUserData,
      goToResults,
      trackAnalyticsEvent,
    ]
  )

  const handleOnBlur = useCallback(
    (field: string) => {
      if (touchedFieldsRef.current.includes(field)) return
      estimateWidgetAnalytics.userInteractedWithField({
        properties: {
          triedToEditAnswers: !!userSelectedValues,
          field,
        },
      })
      touchedFieldsRef.current.push(field)
    },
    [userSelectedValues]
  )

  const renderForm = useMemo(() => {
    if (isTreatment1 || isTreatment2) {
      return (
        <FinalExpenseStateForm
          onSubmit={handleSubmit}
          handleOnBlur={handleOnBlur}
          defaultValues={{
            region: userData?.region,
          }}
        />
      )
    }

    if (isEstWidgetWithPhoneTreatment1) {
      return (
        <FinalExpenseFormWithPhone
          onSubmit={(formValues) => handleSubmit(formValues, true)}
          handleOnBlur={handleOnBlur}
          defaultValues={{
            region: userData?.region,
            firstName: userData?.firstName,
            birthDate: userData?.birthDate,
            phone: userData?.phone,
          }}
        />
      )
    }

    return (
      <FinalExpenseForm
        onSubmit={handleSubmit}
        handleOnBlur={handleOnBlur}
        defaultValues={{
          birthDate: userData?.birthDate,
          gender: userData?.gender,
        }}
      />
    )
  }, [
    handleOnBlur,
    handleSubmit,
    isEstWidgetWithPhoneTreatment1,
    isTreatment1,
    isTreatment2,
    userData,
  ])

  const title =
    isTreatment1 || isTreatment2
      ? 'FREE ONLINE ESTIMATE'
      : 'FREE INSTANT ESTIMATE'

  return (
    <div className={finalExpenseInfoFormStyles.container}>
      <p className="body-l-bold m-0 mb-8 !leading-3 text-[rgba(5,89,79,1)]">
        {title}
      </p>
      {renderForm}
    </div>
  )
}
