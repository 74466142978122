import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react'

type WillsModalContextType = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const WillsModalContext = createContext<WillsModalContextType>({
  isModalOpen: false,
  setIsModalOpen: () => {},
})

export const useWillsModalContext = () => useContext(WillsModalContext)

const WillsModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <WillsModalContext.Provider value={{ isModalOpen, setIsModalOpen }}>
      {children}
    </WillsModalContext.Provider>
  )
}

export default WillsModalProvider
