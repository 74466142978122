import { FC } from 'react'

import classNames from 'classnames'
import { Body2, Button, Spacer, TitleLarge2 } from 'ethos-design-system'

interface FloatingRangeFormErrorProps {
  errorMessage: string
  onEditAnswers: () => void
  finalExpense?: boolean
}

export const FloatingRangeFormError: FC<FloatingRangeFormErrorProps> = ({
  errorMessage,
  onEditAnswers,
  finalExpense,
}) => {
  return (
    <div
      className={classNames('flex flex-col flex-wrap', {
        'w-full': finalExpense,
      })}
    >
      <Spacer.H80 />
      <Spacer.H24 />
      <TitleLarge2.Serif.Book500>We&apos;re sorry!</TitleLarge2.Serif.Book500>
      <Spacer.H32 />
      <Body2.Regular400>{errorMessage}</Body2.Regular400>
      <Spacer.H80 />
      <Spacer.H24 />
      <Button.Medium.Black fullWidth refreshIcon onClick={onEditAnswers}>
        Edit Answers
      </Button.Medium.Black>
      <Spacer.H80 />
    </div>
  )
}
