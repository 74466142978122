import React, { useEffect, useState } from 'react'

import { Body, Media, TitleMedium, TitleSmall } from 'ethos-design-system'
import PropTypes from 'prop-types'
import qs from 'qs'
import ReactResizeDetector from 'react-resize-detector'
import slugify from 'slugify'

import { MAX_ACCORDION_QUESTIONS, SLUGIFY_SETTINGS } from '../../constants'
import { isEnterKeyPress } from '../../helpers/isEnterKeyPress'
import { prepareFaqTabsData } from '../../helpers/prepareFaqTabsData'
import { AccordionToggleIcon } from '../../icons'
import FaqQuestionsFeed from './FaqQuestionsFeed'
import styles from './FaqTabs.module.scss'

/**
 * FaqTabs is an interactive module for the Help Center home page.
 * It provides a mobile accordion and navigable tab system on desktop.
 * The accordion and tab sections contain lists of FAQs sorted by category.
 *
 * @param {Object} moduleData - set by users in `/admin` for this module
 * @param {Object} singlesData - graphQL query results for all FAQ singles
 *
 * @return {JSX.Element}
 */
const FaqTabs = ({ moduleData, singlesData }) => {
  // Breakpoint for transitioning between mobile accordion and desktop tabs
  const laptopStart = Media.BREAKPOINTS.LAPTOP_RANGE_START

  // base tab data and managed state variables
  const tabsData = moduleData.tabs
  const [resized, setResized] = useState(false)
  const [width, setWidth] = useState(0)
  const [tabs, setTabs] = useState(false)

  // used by ReactResizeDetector on initial load and subsequent resizes to handle
  // reformatting of tab data and module state change triggers
  const onResize = (resizeWidth) => {
    if (typeof window !== 'undefined' && window.outerWidth !== resizeWidth) {
      resizeWidth = window.outerWidth
    }

    // maximum number of questions based on viewport size
    const maxQuestions =
      resizeWidth >= laptopStart ? 0 : MAX_ACCORDION_QUESTIONS

    // determine if the viewport has crossed our media query boundary
    const lastWidth = width
    const resizedUp = resizeWidth >= laptopStart && lastWidth < laptopStart
    const resizedDown = resizeWidth < laptopStart && lastWidth >= laptopStart
    const changedViewport = resizedUp || resizedDown
    // if no tab data (on initial load) or the viewport changes, update our tab data
    if (!tabs || changedViewport) {
      const newTabs = prepareFaqTabsData({
        tabsData: tabsData,
        singlesData: singlesData,
        trimQuestions: maxQuestions,
      })

      // update state
      setResized(true)
      setWidth(resizeWidth)
      setTabs(newTabs)
    }
  }

  // check for initial load width/tab data and set root classes
  const minWidth = width > 0
  const resizedReady = minWidth && tabs && resized
  const rootClasses = [styles.root]
  if (resizedReady) {
    rootClasses.push(styles.loaded)
  }

  return (
    <>
      <ReactResizeDetector
        handleWidth
        handleHeight={false}
        onResize={(e) => onResize(e)}
        refreshMode="throttle"
        refreshRate={500}
        targetDomEl="body"
      />
      <div className={rootClasses.join(' ')}>
        {resizedReady && <FaqTabsContent tabs={tabs} width={width} />}
      </div>
    </>
  )
}

FaqTabs.propTypes = {
  moduleData: PropTypes.object.isRequired,
  singlesData: PropTypes.array,
}

/**
 * FaqTabsContent is the inner content of the FaqTabs module.
 *
 * @param {Object} tabs - Prepared FAQs tab data, massaged combination of module data and graphQL query
 * @param {Object} width - Window width for handling transition between mobile and desktop layouts
 *
 * @return {JSX.Element}
 */
const FaqTabsContent = ({ tabs, width }) => {
  // Since 0 is the first section, -1 will result in no open sections.
  // This is done for the mobile accordion, which is fully collapsed on load.
  const initialTab = width >= Media.BREAKPOINTS.LAPTOP_RANGE_START ? 0 : -1
  const [selectedTab, setSelectedTab] = useState(initialTab)

  useEffect(() => {
    const queryString = qs.parse(
      location.search.substr(1, location.search.length)
    )
    if (queryString.category) {
      const categoryIndex = tabs
        .map((tab) => slugify(tab.faqCategory, SLUGIFY_SETTINGS))
        .indexOf(queryString.category)
      setSelectedTab(categoryIndex)
    }
  }, [tabs])

  // Open and close mobile accordion sections
  const toggleAccordion = (index, event) => {
    if (!event || isEnterKeyPress(event)) {
      if (selectedTab === index) {
        return setSelectedTab(-1)
      } else {
        return setSelectedTab(index)
      }
    }
  }

  // Handles a keypress on a desktop navigation tab
  const navItemKeyPress = (index, event) => {
    if (isEnterKeyPress(event)) {
      return setSelectedTab(index)
    }
  }

  // Base selected nav item classes
  const selectedNavItemClasses = [styles.NavItem, styles.Selected]

  // Conditional for determining if the current mapped item is the selected/active section/tab
  const isSelectedTab = (index) => {
    return (
      index === selectedTab ||
      (index === 0 && (!selectedTab || selectedTab === -1))
    )
  }

  // The desktop navigation tabs
  const TabsNav = () => {
    return (
      <div className={styles.Nav}>
        <div className={styles.container}>
          {tabs.map((tab, idx) => (
            <div
              className={
                isSelectedTab(idx)
                  ? selectedNavItemClasses.join(' ')
                  : styles.NavItem
              }
              key={`singleNavItem${idx}`}
              onClick={() => setSelectedTab(idx)}
              onKeyPress={(e) => navItemKeyPress(idx, e)}
              tabIndex={0}
              role="button"
            >
              <Body.Regular400>
                <div className={styles.Label}>
                  {tab.faqCategoryTitle
                    ? tab.faqCategoryTitle
                    : tab.faqCategory.trim()}
                </div>
              </Body.Regular400>
            </div>
          ))}
        </div>
      </div>
    )
  }

  // Selected tab content/accordion section classes
  const selectedPanelClasses = [styles.TabPanel, styles.Selected]

  // Inner tab content/accordion section questions feed classes
  const faqQuestionsFeedClasses = {
    questionsClass: styles.Questions,
    viewAllClass: styles.ViewAll,
    singleClass: styles.Single,
    questionClass: styles.Question,
    innerClass: styles.QuestionInner,
  }

  // Conditional to handle going from mobile with all sections collapsed to desktop
  // The latter always requires a selected tab, while the former can have no open sections
  const isDesktopNoSelectedTab = (index, width) => {
    return (
      (index === 0 &&
        !selectedTab &&
        width >= Media.BREAKPOINTS.LAPTOP_RANGE_START) ||
      (selectedTab === -1 && width >= Media.BREAKPOINTS.LAPTOP_RANGE_START)
    )
  }

  // Conditional to check if the current tab/accordion section content is selected/active
  const isSelectedTabPanel = (index, width) => {
    return index === selectedTab || isDesktopNoSelectedTab(index, width)
  }

  // Responsive tab content. Converts between mobile accordion sections and desktop tabs panels.
  const TabsContent = ({ width }) => {
    return tabs.map((tab, idx) => (
      <div
        className={
          isSelectedTabPanel(idx, width)
            ? selectedPanelClasses.join(' ')
            : styles.TabPanel
        }
        key={`singleTabPanel${idx}`}
      >
        <div className={styles.HeadingDesktop}>
          <TitleMedium.Serif.Book500>
            {tab.heading || tab.faqCategory.trim()}
          </TitleMedium.Serif.Book500>
        </div>
        <div
          className={styles.HeadingMobile}
          onClick={() => toggleAccordion(idx)}
          onKeyPress={(e) => toggleAccordion(idx, e)}
          tabIndex={0}
          role="button"
        >
          <TitleSmall.Sans.Medium500>
            {tab.faqCategoryTitle || tab.faqCategory.trim()}
          </TitleSmall.Sans.Medium500>
          <AccordionToggleIcon className={styles.AccordionIcon} />
        </div>
        <FaqQuestionsFeed
          questions={tab.faqTopOrder}
          classes={faqQuestionsFeedClasses}
          viewAllUrl={tab.url}
        />
      </div>
    ))
  }

  // FaqTabsContent return statement
  return (
    <>
      <TabsNav />
      <div className={[styles.Tabs, styles.container].join(' ')}>
        <TabsContent width={width} />
      </div>
    </>
  )
}

FaqTabsContent.propTypes = {
  tabs: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
}

export default FaqTabs

// export const FaqTabsQuery = graphql`
//   fragment FaqTabsFragment on MarkdownRemarkFrontmatterPageLayout {
//     type
//     tabs {
//       faqCategory
//       faqCategoryTitle
//       faqType
//       heading
//       faqTopOrder {
//         faqRelation
//         faqAnswerPreview
//       }
//     }
//   }
// `
