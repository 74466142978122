import React from 'react'

import { useWillsModalContext } from '@/contexts/WillsModalContext'
import { CloudinaryImage } from 'ethos-design-system'
import { cn } from 'lib/client-utils'

import { useWillsFunnelFlow } from '@/hooks/features/useWillsFunnelFlow'

import Markdown from '../../global/Markdown'
import PriorityImage from '../../global/PriorityImage'
import WillsModal from '../Wills/WillsModal'
import { SingleCTAInterface } from '../interface'

const bullets = [
  'No expensive lawyers. No appointments.',
  'Start and finish your will in minutes.',
  'Confirm who will care for your children.',
]

const trustImages = [
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1705967757/logo-finance_qdjats.svg',
    alt: 'Yahoo Finance',
  },
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1705967757/Vector_txatgi.svg',
    alt: 'Fortune',
  },
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1705967757/logo-forbes_itgt1j.svg',
    alt: 'Forbes',
  },
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1705967757/logo-business-insider_a4uwgj.svg',
    alt: 'Business Insider',
  },
]

const Wills = ({
  moduleData,
  ctaClick,
}: {
  moduleData: SingleCTAInterface
  ctaClick: () => void
  policiesApproved?: number
}) => {
  const { flow } = useWillsFunnelFlow()

  const { setIsModalOpen } = useWillsModalContext()
  const { heading, ctaLabel } = moduleData

  const handleCTAClick = () => {
    if (moduleData.disableNavigateToApp) {
      setIsModalOpen(true)
    }
    ctaClick()
  }

  return (
    <>
      <div className="bg-wills-gradient-mobile text-white md:bg-wills-gradient-desktop [&_p]:m-0">
        <div className="m-auto flex w-full flex-col px-6 md:max-w-[1440px] md:px-0">
          <nav className="py-6 md:px-12 md:py-7 xl:px-20">
            <div className="h-[18px] w-[95px]">
              <CloudinaryImage
                className="h-[18px] w-[95px]"
                publicId="https://res.cloudinary.com/getethos/image/upload/v1717631363/ethos-logo_msu93s.svg"
                alt="ethos logo"
                crop={CloudinaryImage.CROP_METHODS.FIT}
                height={[18, 18, 18, 18]}
                width={[95, 95, 95, 95]}
              />
            </div>
          </nav>
          <div className="relative w-full">
            <div className="relative z-1 mx-auto mt-8 max-w-[400px] md:ml-[60px] md:mt-16 md:max-w-[600px] xl:ml-[120px]">
              <h1 className="font-['Cambon'] text-[32px] font-bold leading-[42px] min-[430px]:text-4xl md:min-w-[600px] md:text-7xl md:leading-[1.15] [&>p]:tracking-[0.64px] md:[&>p]:tracking-[-1.35px]">
                <Markdown input={heading || ''}></Markdown>
              </h1>
              <ul className="bullets mb-12 mt-6 flex list-none flex-col gap-y-2 p-0 md:mb-16 md:mt-12 md:gap-y-6">
                {bullets.map((bullet) => (
                  <li
                    key={bullet}
                    className="flex items-start gap-x-4 text-base tracking-[-0.28px] min-[400px]:text-xl md:text-[28px]"
                  >
                    <div className="h-6 w-6">
                      <CheckmarkIcon />
                    </div>
                    <p className="pt-0.5">{bullet}</p>
                  </li>
                ))}
              </ul>
              <button
                className="flex w-full cursor-pointer items-stretch justify-between gap-x-3 rounded-lg bg-[#F49640] px-5 py-5 text-2xl font-medium uppercase leading-none text-neutral-800 shadow hover:brightness-105 md:mb-28 md:max-w-[500px] md:justify-center md:px-12 md:py-7"
                onClick={handleCTAClick}
              >
                <span className="mt-[3px]">{ctaLabel}</span>
                <ArrowIcon />
              </button>
            </div>
            <div className="static bottom-0 left-1/2 grid h-full place-content-center overflow-hidden md:absolute md:place-content-end">
              <PriorityImage
                className="mb-[-45px] max-h-[350px] md:mb-0 md:max-h-[650px]"
                publicId="https://res.cloudinary.com/getethos/image/upload/v1717711448/AdobeStock_389575492_web_cutout_2_zlkiie.png"
                fetchpriority="high"
                alt="Father mother and child hugging"
                crop={CloudinaryImage.CROP_METHODS.FIT}
                height={[650, 650, 650, 650]}
                width={[655, 655, 655, 655]}
              />
            </div>
          </div>
        </div>
        <div className="press col-span-2 flex items-center justify-center gap-x-6 bg-[#272727] px-6 py-12 md:gap-x-16 md:px-12 md:py-16">
          {trustImages.map((trustImage, index) => (
            <div
              className={cn(
                'flex items-center justify-center gap-x-6 md:gap-x-16',
                index === trustImages.length - 1 && 'hidden md:flex'
              )}
              key={`trustImage-${trustImage.imageUrl}`}
            >
              <div
                className={cn(
                  'flex h-6 items-center justify-center md:h-8',
                  index !== 0 && 'h-[18px]'
                )}
              >
                <CloudinaryImage
                  className="h-full"
                  publicId={trustImage.imageUrl}
                  alt={trustImage.alt}
                  crop={CloudinaryImage.CROP_METHODS.FILL}
                  height={[24, 24, 32, 32]}
                  width={[130, 130, 130, 130]}
                />
              </div>
              {index !== trustImages.length - 1 && (
                <div
                  className={cn(
                    'h-6 w-[2px] min-w-[2px] bg-white md:h-8',
                    index === trustImages.length - 2 && 'hidden md:block'
                  )}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <WillsModal />
    </>
  )
}

const CheckmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8.79995 15.8998L4.59995 11.6998L3.19995 13.0998L8.79995 18.6998L20.8 6.6998L19.4 5.2998L8.79995 15.8998Z"
      fill="white"
    />
  </svg>
)

const ArrowIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
      fill="#272727"
    />
  </svg>
)

export default Wills
