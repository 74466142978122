import { useEffect, useState } from 'react'

import { apiRoutes } from 'lib/@getethos/urls/apiRoutes'

import { UserData } from '../../types/user'
import { EstimateWidgetProgress } from '../../types/widget'
import { getParamsForQuotes } from './helpers/getParamsForQuotes'

interface FeatureFlagConfig {
  finalExpense: boolean
}

interface UseFetchQuotesV2Params {
  userSelectedValues: any | null
  region: string | null
  userData: UserData
  progress: EstimateWidgetProgress
  hasFetchedInitialQuotes: boolean
  setError: (error: string) => void
  setHasFetchedInitialQuotes: (initialFetch: boolean) => void
  featureFlagConfig: FeatureFlagConfig
}

export const useFetchQuotesV2 = ({
  hasFetchedInitialQuotes,
  progress,
  region,
  setError,
  setHasFetchedInitialQuotes,
  userData,
  userSelectedValues,
  featureFlagConfig,
}: UseFetchQuotesV2Params) => {
  const [ranges, setRanges] = useState({ min: 0, max: 0 })
  const [previousRanges, setPreviousRanges] = useState({ min: 0, max: 0 })

  const fetchQuotes = async () => {
    if (progress !== EstimateWidgetProgress.PRICES) return

    const params = getParamsForQuotes({
      coverage: userSelectedValues?.coverage,
      userData,
      region,
      term: userSelectedValues?.term,
    })
    if (!params) return null

    try {
      const response = await apiRoutes.quotesWithoutProductDetermination(
        params,
        'trustage|tawl'
      )
      setPreviousRanges(ranges)
      setRanges({
        min: response?.minPrice || 0,
        max: response?.maxPrice || 0,
      })
    } catch (error: any) {
      setError(error.message)
    }
  }

  useEffect(() => {
    if (
      progress === EstimateWidgetProgress.INFO ||
      !userSelectedValues?.coverage ||
      !region ||
      !featureFlagConfig.finalExpense // we do not want to use this hook if we are not in final expense mode
    )
      return

    if (!hasFetchedInitialQuotes) {
      fetchQuotes()
      setHasFetchedInitialQuotes(true)
      return
    }

    const timeout = setTimeout(fetchQuotes, 500)
    return () => clearTimeout(timeout)
  }, [userSelectedValues?.coverage, progress, hasFetchedInitialQuotes, region])

  return { ranges, previousRanges }
}
