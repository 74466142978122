export { default as CoBranded } from './CoBranded'
export { default as FullbleedImage } from './FullbleedImage'
export { default as MultiImage } from './MultiImage'
export { default as ForegroundImage } from './ForegroundImage'
export { default as StraightToApp } from './StraightToApp'
export { default as External } from './External'

export interface LayoutProps {
  updateScrollNode: any
  moduleData: any
  // eslint-disable-next-line no-undef
  formManager: JSX.Element
  widgetProgress?: string
  isRefactor?: boolean
}
