import React from 'react'

import { EmailInput } from 'ethos-design-system'
import EmailFormatValidator from 'ethos-design-system/src/validators/EmailValidator.js'

export interface EmailModalParams {
  placeholder?: string
  emailValue: string
  dataTid: string
  labelCopy?: string
  allCaps?: boolean
  labelWeight?: string
  labelColor?: string
  classOverrides?: string
}

export interface EmailFormField {
  email: {
    component: (props: any) => JSX.Element
    validators: any[]
    tid: string
  }
}

export const getEmailModalFields = ({
  placeholder,
  emailValue,
  dataTid,
  labelCopy,
  allCaps = true,
  labelWeight,
  labelColor,
  classOverrides,
}: EmailModalParams): EmailFormField => ({
  email: {
    component: (props): JSX.Element => (
      <EmailInput
        name="estimate-collect-email-modal"
        placeholder={placeholder}
        initialValue={emailValue}
        labelCopy={labelCopy}
        allCaps={allCaps}
        labelWeight={labelWeight}
        labelColor={labelColor}
        classOverrides={classOverrides}
        {...props}
      />
    ),
    tid: dataTid,
    validators: [EmailFormatValidator],
  },
})
