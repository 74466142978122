const Consent = ({
  buttonTitle,
  className,
}: {
  buttonTitle: string
  className?: string
}) => (
  <span
    className={`body-m-reg block text-center text-[--theme-fg-subtle-2x] ${className}`}
  >
    By clicking “{buttonTitle}”, I confirm that I have received, read, and agree
    to{' '}
    <a
      href="https://vault.pactsafe.io/s/3badc28a-6081-4c3e-ae78-a79cc9db29cd/legal.html?g=37479#template-a5yxrs3sg"
      target="_blank"
      rel="noopener noreferrer"
      className="!text-[--theme-fg-subtle-2x] !underline"
    >
      Phone/SMS Consent
    </a>
    , and{' '}
    <a
      href="https://vault.pactsafe.io/s/3badc28a-6081-4c3e-ae78-a79cc9db29cd/legal.html?g=37479#template-91o6fzr-8"
      target="_blank"
      rel="noopener noreferrer"
      className="!text-[--theme-fg-subtle-2x] !underline"
    >
      Privacy Policy.{' '}
    </a>
  </span>
)

export default Consent
