//@ts-nocheck

import React, { useEffect, useState } from 'react'

import dynamic from 'next/dynamic'

import { COLORS, Caption2, Spacer } from 'ethos-design-system'

import { TOOLTIP } from '../../../constants'
import styles from './Floating.module.scss'

const EstimateWidgetTooltip = dynamic(
  () =>
    import('../../../shared/Estimate/EstimateWidgetTooltip').then(
      (mod) => mod.EstimateWidgetTooltip
    ),
  { ssr: false }
)

interface TermButtonsProps {
  maxTerm: number
  initialTerm: number
  termToggle: (termLength: number) => void
}

export const TermButtons = ({
  maxTerm,
  initialTerm,
  termToggle,
}: TermButtonsProps) => {
  const [activeTerm, setActiveTerm] = useState(initialTerm)
  const handleClick = ({ termLength }): void => {
    setActiveTerm(termLength)
    termToggle(termLength)
  }
  const TermButton = ({ termLength }) => {
    const buttonClass = activeTerm === termLength ? styles.active : ''
    return (
      <button
        value={termLength}
        name={`termLength_${termLength}`}
        onClick={(): void => {
          handleClick({ termLength })
        }}
        className={buttonClass}
      >
        {termLength}
      </button>
    )
  }

  let validTerms = [10, 15, 20, 30]
  const maxTermFilter = (term): boolean => term > maxTerm
  validTerms = validTerms.filter((term) => !maxTermFilter(term))

  const toolTipDetails = TOOLTIP.TERM_LENGTH.MESSAGE
  const toolTipLabel = TOOLTIP.TERM_LENGTH.LABEL

  const toJSX = (data: string | string[]) => {
    if (Array.isArray(data)) {
      const initialJsxElement = <></>
      return data.reduce(
        (jsxElement, eachEntry) =>
          jsxElement === initialJsxElement ? (
            <>{eachEntry}</>
          ) : (
            <>
              {jsxElement}
              <br />
              <br />
              {eachEntry}
            </>
          ),
        initialJsxElement
      )
    }
    return <>{data}</>
  }

  useEffect(() => {
    if (
      (activeTerm === 0 && initialTerm !== 0) ||
      activeTerm > maxTerm ||
      activeTerm < initialTerm
    ) {
      return setActiveTerm(initialTerm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTerm])

  return (
    <>
      <div className="flex">
        <Caption2.Regular400 color={COLORS.GRAY_SECONDARY}>
          Term length (years)
        </Caption2.Regular400>
        <EstimateWidgetTooltip
          details={toJSX(toolTipDetails)}
          label={toolTipLabel}
          popperBoxStyles={styles.tooltipPopperBoxStyles}
          placement="right"
          softCorners
        />
      </div>
      <Spacer.H8 />
      <div className={styles.termButtons}>
        {validTerms.map((term) => (
          <TermButton termLength={term} key={term} />
        ))}
      </div>
      <Spacer.H16 />
    </>
  )
}
