import React, { useState } from 'react'

import { DirectToAppProvider } from '@/contexts/DirectToAppContext'
import { CloudinaryImage } from 'ethos-design-system'
import { cmsModuleAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { ENABLE_CMS_EW_REFACTOR } from 'lib/FeaturesAndExperiments/featureStore'
import { useFeatureVariable } from 'lib/FeaturesAndExperiments/getFeatureVariable'

import { SingleCTAInterface } from '@/components/SingleCTA/interface'
import Trustworthy from '@/components/SingleCTA/layouts/Trustworthy'

import { useDirectToAppUx } from '@/hooks/features/useDirectToAppUx'
import { useSingleCTAExperiment } from '@/hooks/features/useSingleCTAExperiment'
import { useNavigateToApp } from '@/hooks/useNavigateToApp'

import { useIntersectionObserver } from '../../../../lib/@getethos/hooks/useIntersectionObserver'
import { ESTIMATE_WIDGET_AGES } from '../../../constants'
import {
  EstimateWidgetImageLayout,
  EstimateWidgetModuleData,
} from '../EstimateWidget'
import { EstimateWidgetProvider } from '../contexts/EstimateWidgetContext'
import styles from './Floating.module.scss'
import { FloatingFormManagerRefactor } from './FloatingForm/FloatingFormManagerRefactor'
import { FloatingFormManager } from './FloatingFormManager'
import {
  CoBranded,
  External,
  ForegroundImage,
  FullbleedImage,
  MultiImage,
  StraightToApp,
} from './Layouts'
import { FinalExpense } from './Layouts/FinalExpense/FInalExpense'
import SingleCTADirect from './Layouts/SingleCTADirect/SingleCTADirect'

export const FloatingWidget = ({
  moduleData,
}: {
  moduleData: EstimateWidgetModuleData
}) => {
  const [isIntersecting, scrollNode, updateScrollNode] =
    useIntersectionObserver()

  const [widgetProgress, setWidgetProgress] = useState('info') // info or prices

  const { isEnabled } = useFeatureVariable(ENABLE_CMS_EW_REFACTOR)
  const { handleNavigateToApp } = useNavigateToApp()

  const isRefactor = isEnabled

  const formManager = !isRefactor ? (
    <FloatingFormManager
      scrollNode={scrollNode}
      isIntersecting={isIntersecting}
      moduleData={moduleData}
      minimumEligibleAge={ESTIMATE_WIDGET_AGES.MIN}
      maximumEligibleAge={ESTIMATE_WIDGET_AGES.MAX}
      progressFunction={setWidgetProgress}
    />
  ) : (
    <DirectToAppProvider>
      <FloatingFormManagerRefactor
        moduleData={moduleData}
        scrollNode={scrollNode as unknown as Element}
      />
    </DirectToAppProvider>
  )

  let { isTreatment1, isTreatment2 } = useSingleCTAExperiment()

  if (moduleData.experimentKey === 'single_cta_experiment-treatment_1') {
    isTreatment1 = true
  }

  const handleGoToMainApp = () => {
    cmsModuleAnalytics.ctaClicked({
      properties: {
        module: 'DirectToApp-SingleCTA',
        ctaLabel: 'GET MY RATES',
      },
      callback: null,
    })
    handleNavigateToApp({})
  }

  // showPlaceholder can be used for experiments that modify the EW layout
  // to do this turn showPlaceholder into a useState constant and modify
  // it's value with useLayoutEffect once the Optimizely experiment flag
  // is ready to set the EW layout to it's final variation
  const showPlaceholder = false

  if (showPlaceholder)
    return (
      <div className={styles.placeholder}>
        {moduleData.groupImage && moduleData.imageAlt && (
          <CloudinaryImage
            publicId={moduleData.groupImage}
            alt={moduleData.imageAlt}
            className={styles.image}
            crop={CloudinaryImage.CROP_METHODS.CROP}
          />
        )}
      </div>
    )

  let moduleToRender: React.ReactNode

  if (isTreatment2) {
    moduleToRender = (
      <Trustworthy
        moduleData={
          {
            navbarPadding: '64',
            heading: 'Instant<br/>life insurance',
            ctaLabel: 'Get my rates',
            fiveMinutesHeading: true,
            newTab: false,
            backgroundColor: 'moss',
            fiveMinuteStyles: true,
          } as unknown as SingleCTAInterface
        }
        ctaClick={handleGoToMainApp}
      />
    )
  } else if (isTreatment1) {
    moduleToRender = <SingleCTADirect handleGoToMainApp={handleGoToMainApp} />
  } else if (moduleData.imageLayout === EstimateWidgetImageLayout.MultiImage) {
    // MultiImage variation

    moduleToRender = (
      <MultiImage
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )
  } else if (
    moduleData.imageLayout === EstimateWidgetImageLayout.FinalExpense
  ) {
    moduleToRender = (
      <FinalExpense formManager={formManager} moduleData={moduleData} />
    )
  } else if (moduleData.imageLayout === EstimateWidgetImageLayout.CoBranded) {
    moduleToRender = (
      <CoBranded
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )
  } else if (moduleData.imageLayout === EstimateWidgetImageLayout.FullBleed) {
    moduleToRender = (
      <FullbleedImage
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )
  } else if (moduleData.imageLayout === EstimateWidgetImageLayout.External) {
    moduleToRender = (
      <External
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )
  } else if (
    moduleData.imageLayout === EstimateWidgetImageLayout.ForegroundImage
  ) {
    moduleToRender = (
      <ForegroundImage
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )
  } else if (
    moduleData.imageLayout === EstimateWidgetImageLayout.StraightToApp
  ) {
    moduleToRender = (
      <StraightToApp
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
        widgetProgress={widgetProgress}
        isRefactor={isRefactor}
      />
    )
  } else
    moduleToRender = (
      <FullbleedImage
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )

  return <EstimateWidgetProvider>{moduleToRender}</EstimateWidgetProvider>
}
