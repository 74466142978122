import styles from './TrustSignals.module.scss'

export const TrustSignals = () => {
  return (
    <div className="flex items-end justify-center gap-[45px]">
      <div>
        <img
          src="https://res.cloudinary.com/getethos/image/upload/v1676493336/00_CMS/trustPilotLogoWithStars_tk7u4q.png"
          className="max-w-[110px]"
          alt="Trust Pilot"
        />
        <div className={styles.text}>4.8 rating / 2,004 reviews</div>
      </div>
      <div>
        <img
          src="https://res.cloudinary.com/getethos/image/upload/v1728540939/google_wxg6ko.svg"
          className="max-w-[110px]"
          alt="Google"
        />
        <div className={styles.text}>4.8 stars / 9000 reviews</div>
      </div>
    </div>
  )
}
