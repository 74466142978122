import React from 'react'

import { TitleLarge2, TitleMedium2, TitleXLarge2 } from 'ethos-design-system'

export const Heading = ({
  headingSize,
  enableH1 = false,
  children,
  color,
}: {
  headingSize?: string
  enableH1?: boolean
  children: string
  color?: string
}) => {
  let SizedHeading
  switch (headingSize) {
    default:
    case 'medium':
      SizedHeading = TitleMedium2.Serif.Medium500
      break
    case 'large':
      SizedHeading = TitleLarge2.Serif.Medium500
      break
    case 'xlarge':
      SizedHeading = TitleXLarge2.Serif.Medium500
      break
  }

  return (
    <SizedHeading element={enableH1 ? 'h1' : 'div'} color={color}>
      {children}
    </SizedHeading>
  )
}
