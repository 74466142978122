import z from 'zod'

const ageIntegerValidator =
  (minAge = 1, maxAge: number = 99) =>
  (input: string | number): string => {
    const num = Number(`${input}`.replace(/[^\d]/g, ''))

    if (!(num >= minAge && num <= maxAge)) {
      return `Age must be between ${minAge} and ${maxAge}`
    }

    return ''
  }

const genderSchema = z.object({
  gender: z.string().min(1, 'Please provide your gender'),
})

const birthDateSchema = z.object({
  birthDate: z
    .string({ message: 'Please provide your age ' })
    .min(1, 'Please provide your age')
    .refine(
      (value) => {
        const ageError = ageIntegerValidator(20, 85)(value)
        return !ageError
      },
      { message: 'Age must be between 20 and 85' }
    ),
})

const zipCodeSchema = z.object({
  zipCode: z
    .string()
    .min(1, 'Please provide zip code')
    .length(5, 'Please provide a valid zip code'),
})

const smoker = z.object({
  smoker: z.boolean().optional(),
})

export const validationSchema = z.object({
  step_1: genderSchema,
  step_2: birthDateSchema,
  step_3: zipCodeSchema,
  step_4: smoker,
})

export type Form = z.infer<typeof validationSchema>
