import React from 'react'

import { COLORS, Select } from 'ethos-design-system'
import { DesiredCoverageAmount } from 'lib/@getethos/constants'

export const desiredCoverageOptions = [
  {
    label: 'I don’t know and would like help',
    value: DesiredCoverageAmount.Unknown,
  },
  { label: '$100,000', value: DesiredCoverageAmount.$100_000 },
  { label: '$250,000', value: DesiredCoverageAmount.$250_000 },
  { label: '$500,000', value: DesiredCoverageAmount.$500_000 },
  { label: '$1,000,000', value: DesiredCoverageAmount.$1_000_000 },
  { label: '$1,500,000', value: DesiredCoverageAmount.$1_500_000 },
  { label: '$2,000,000', value: DesiredCoverageAmount.$2_000_000 },
  { label: '$2,500,000', value: DesiredCoverageAmount.$2_500_000 },
  { label: '$3,000,000', value: DesiredCoverageAmount.$3_000_000 },
  { label: '$3,500,000', value: DesiredCoverageAmount.$3_500_000 },
  { label: '$4,000,000', value: DesiredCoverageAmount.$4_000_000 },
  { label: '$4,500,000', value: DesiredCoverageAmount.$4_500_000 },
  { label: '$5,000,000', value: DesiredCoverageAmount.$5_000_000 },
]

export type DesiredCoverageProps = {
  'data-tid': string
  currentError?: string
  currentValue?: DesiredCoverageAmount
  formChangeHandler?: (
    selectStringOrArrayOfSelections: any,
    errorValue: string
  ) => void
  formTouched?: boolean
  labelCopy: string
  name: string
  onChange?: (selectStringOrArrayOfSelections: any, actionMeta: any) => any
  setFieldTouched?: () => void
  validator?: (selectStringOrArrayOfSelections: any) => string
}

export const DesiredCoverage = ({
  currentValue,
  ...props
}: DesiredCoverageProps): JSX.Element => {
  const defaultValue =
    currentValue &&
    desiredCoverageOptions.find((option) => option.value === currentValue)

  return (
    <Select
      allCaps={false}
      capitalize
      defaultValue={defaultValue}
      labelColor={COLORS.GRAY_SECONDARY}
      labelWeight="regular"
      options={desiredCoverageOptions}
      placeholder="Select..."
      styles={{
        control: (style: any): object => ({
          ...style,
          backgroundColor: '#f5f5f5',
        }),
        indicatorSeparator: (): object => ({
          display: 'none',
        }),
      }}
      {...props}
    />
  )
}
