import React from 'react'

/**
 * Splitting on spaces, convert a string to an array and remove the last element.
 * Will strip the last word from a string including punctuation.
 */
export const removeLastWord = (text: string): string => {
  const alteredText = text.trim().split(' ')
  return `${alteredText.slice(0, alteredText.length - 1).join(' ')} `
}

/**
 * Get the last word of a sentence including punctuation, splitting on spaces.
 */
export const getLastWord = (text: string): string | undefined => {
  return text.trim().split(' ').pop()
}

/**
 * getLastTwoWords - from a string, including punctuation, splitting and rejoining on spaces.
 */
export const getLastTwoWords = (text: string): string => {
  return text.trim().split(' ').slice(-2).join(' ')
}

interface IconIntegratedTitleProps {
  title: string
  children: any
}
/**
 * Use this to wrap things like icons in a span with the last word of a text string.
 * Doing so will prevent the icon from being isolated on a newline without any word.
 *
 * @param {string} title - Some text to integrate the icon into.
 * @param {ReactNode} children - Icon to integrate into title.
 *
 * @return {JSX.Element}
 */
export const IconIntegratedTitle = (
  iconIntegratedTitleProps: IconIntegratedTitleProps
): JSX.Element => {
  return (
    <>
      <span>{removeLastWord(iconIntegratedTitleProps.title)}</span>
      <div style={{ display: 'inline-block' }}>
        <span>{getLastWord(iconIntegratedTitleProps.title)}</span>
        {iconIntegratedTitleProps.children}
      </div>
    </>
  )
}

/**
 * wrapLastTwoWords - Similar to IconIntegratedTitle, but will wrap last
 * two words in string.
 *
 * @param {string} [text] - Some text to modify.
 *
 * @return {JSX.Element}
 */
export const wrapLastTwoWords = (text: string): JSX.Element =>
  text ? (
    <>
      {text.trim().replace(getLastTwoWords(text), '')}
      <span style={{ display: 'inline-block' }}>{getLastTwoWords(text)}</span>
    </>
  ) : (
    <></>
  )

/**
 * trimWithEllipsis trims the length of a string and adds an ellipsis if string length of text is greater than length parameter.
 *
 * @param {string} text - The string to trim
 * @param {number} length - Max length of string
 *
 * @return {string} Text string trimmed to length
 */
export const trimWithEllipsis = (text: string, length: number): string => {
  if (text.length > length) {
    const trimmed = `${text.substring(0, length)}...`
    return trimmed
  } else {
    return text
  }
}
