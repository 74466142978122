import { FE_LP_NEW_HEADER } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useFeLPNewHeader = () => {
  const { isLoading, variation, isEnabled } = useDecisionForFlag({
    name: FE_LP_NEW_HEADER.EXPERIMENT_KEY,
    fallbackVariation: FE_LP_NEW_HEADER.VARIATIONS.CONTROL,
  })

  return {
    isLoading,
    isEnabled,
    isTreatment1: variation === FE_LP_NEW_HEADER.VARIATIONS.TREATMENT_1,
    isTreatment2: variation === FE_LP_NEW_HEADER.VARIATIONS.TREATMENT_2,
  }
}
