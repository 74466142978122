//@ts-nocheck

import get from 'lodash.get'
import slugify from 'slugify'

import { SLUGIFY_SETTINGS } from '../constants'
import { trimFaqQuestionPreview } from './trimFaqQuestionPreview'

const createPathKeyPath = (pathKey: string, url: string): string => {
  return `/${pathKey}/${url}/`
}

/**
 * removeMatchingSingleFromData handles removing an object from an array after it's been parsed
 *
 * @param {object} matchingSingle - FAQ single object from tab data
 * @param {array} remainingSingles - Array of all FAQ singles being looped through
 *
 * @return {array} The remainingSingles array with the matching single spliced out.
 */
const removeMatchingSingleFromData = ({
  matchingSingle,
  remainingSingles,
}): void => {
  // remove the matching single from singleData so it's not included in remainder below
  const indexOfMatchingSingle = remainingSingles.indexOf(matchingSingle)
  if (indexOfMatchingSingle > -1) {
    remainingSingles.splice(indexOfMatchingSingle, 1)
  }
}

/**
 * addMatchingSingleContent joins graphQL query data with user set data from `/admin`
 *
 * @param {object} singlesData - All FAQ singles data
 * @param {object} currentSingle - Current FAQ single being looped through
 * @param {array} remainingSingles - Array of all FAQ singles still being looped through
 *
 * @return {void} Updates currentSingle props and splices it from remainingSingles
 */
const addMatchingSingleContent = ({
  singlesData,
  currentSingle,
  remainingSingles,
}): void => {
  const title = currentSingle.faqRelation.trim()
  const matchingSingle = singlesData?.find(function (single) {
    return single.title.trim() === title
  })
  if (matchingSingle) {
    // Set question text
    currentSingle.faqQuestionPreview = get(matchingSingle, 'faqSingle.question')

    // Set the answer text if not overridden
    if (!currentSingle.faqAnswerPreview) {
      currentSingle.faqAnswerPreview =
        get(matchingSingle, 'faqSingle.faqAnswerPreview') ||
        get(matchingSingle, 'faqSingle.answer')
    }

    // Migrate pathkey and url to faqTopOrder
    const faqPathKey = get(matchingSingle, 'pathKey')
    const faqUrl = get(matchingSingle, 'url')
    currentSingle.url = createPathKeyPath(faqPathKey, faqUrl)
    removeMatchingSingleFromData({
      matchingSingle: matchingSingle,
      remainingSingles: remainingSingles,
    })
  }
}

/**
 * prepareFaqTabsData formats raw FaqTabs module data and graphQL single FAQs data for module output
 *
 * @param {array} tabsData - Module data
 * @param {object} singlesData - All FAQ singles
 * @param {number} trimQuestions - Option to trim number of questions returned per tab
 *
 * @return {array} Updated tabsData
 *
 * Example structure of returned tabsData, with one category and one question in the nested arrays.
 * [
 *   {
 *     "faqCategory": {string},
 *     "faqCategoryTitle": {string},
 *     "heading": {string} || null,
 *     "faqTopOrder": [
 *       {
 *         "faqQuestionPreview": {string},
 *         "faqAnswerPreview": {string},
 *         "url": {string}"
 *       },
 *     ],
 *     "url": {String}"
 *   },
 * ]
 *
 */
export const prepareFaqTabsData = ({
  tabsData,
  singlesData,
  trimQuestions,
}): any => {
  const remainingSingles = singlesData

  // for each category tab, add faqSingle data to each faqTopOrder
  for (const tab of tabsData) {
    if (tab.faqCategory) {
      const categoryPath = tab.faqType == 'agents' ? 'agents/faq' : 'faq'
      const categoryUrl = slugify(tab.faqCategory, SLUGIFY_SETTINGS)
      tab.url = createPathKeyPath(categoryPath, categoryUrl)
    }
    // migrate data to faqTopOrder for singles set by editors
    if (!tab.faqTopOrder) {
      tab.faqTopOrder = []
    } else if (tab.faqTopOrder.length > 0) {
      if (trimQuestions && tab.faqTopOrder.length > trimQuestions) {
        tab.faqTopOrder = tab.faqTopOrder.slice(0, trimQuestions)
      }

      for (const topSingle of tab.faqTopOrder) {
        // Find the matching single entry from singlesData for singles defined in faqTopOrder
        if (topSingle.faqRelation) {
          addMatchingSingleContent({
            singlesData: singlesData,
            currentSingle: topSingle,
            remainingSingles: remainingSingles,
          })
        }
      }
    }
  }

  // add remainder of faq singles to the faqTopOrder
  for (const remainingSingle of remainingSingles) {
    const category = get(remainingSingle, 'faqSingle.faqCategory').trim()
    const matchingTab = tabsData.find(function (tab) {
      return tab.faqCategory.trim() === category
    })
    if (
      matchingTab &&
      (!trimQuestions || matchingTab.faqTopOrder.length < trimQuestions)
    ) {
      const question = get(remainingSingle, 'faqSingle.question')
      const answer =
        get(remainingSingle, 'faqSingle.faqAnswerPreview') ||
        get(remainingSingle, 'faqSingle.answer')
      const faqPathKey = get(remainingSingle, 'pathKey')
      const faqUrl = get(remainingSingle, 'url')
      if (question && answer && faqPathKey && faqUrl) {
        const single = {
          faqQuestionPreview: trimFaqQuestionPreview(question, 'question'),
          faqAnswerPreview: trimFaqQuestionPreview(answer, 'answer'),
          url: createPathKeyPath(faqPathKey, faqUrl),
        }
        matchingTab.faqTopOrder.push(single)
      }
    }
  }
  return tabsData
}
