import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'

import { UserData } from '../types/user'
import { EstimateWidgetProgress } from '../types/widget'

type UserDataControls = {
  userData: UserData
  setUserData: (userData: Partial<UserData>) => void
}

type WidgetProgressControls = {
  widgetProgress: EstimateWidgetProgress
  setWidgetProgress: (widgetProgress: EstimateWidgetProgress) => void
  editAnswers: () => void
}

interface IEstimateWidgetContext {
  widgetProgressControls: WidgetProgressControls
  userDataControls: UserDataControls
}

const defaultEstimateWidgetContext: IEstimateWidgetContext = {
  widgetProgressControls: {
    widgetProgress: EstimateWidgetProgress.INFO,
    setWidgetProgress: () => {},
    editAnswers: () => {},
  },
  userDataControls: {
    userData: {
      smoker: false,
      birthDate: '',
      zipCode: '',
      gender: '',
      estimatedCredit: '',
      region: '',
    },
    setUserData: () => {},
  },
}

const EstimateWidgetContext = createContext<IEstimateWidgetContext>(
  defaultEstimateWidgetContext
)

export const useEstimateWidgetContext = () => useContext(EstimateWidgetContext)

export const EstimateWidgetProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [widgetProgress, setWidgetProgress] = useState(
    EstimateWidgetProgress.INFO
  )

  const [userData, setUserData] = useState<UserData>(
    defaultEstimateWidgetContext.userDataControls.userData
  )

  const updateUserData = (newUserData: Partial<UserData>) => {
    setUserData((prevUserData) => ({ ...prevUserData, ...newUserData }))
  }

  const editAnswers = () => {
    setWidgetProgress(EstimateWidgetProgress.INFO)
  }

  const values: IEstimateWidgetContext = useMemo(
    () => ({
      userDataControls: {
        userData,
        setUserData: updateUserData,
      },
      widgetProgressControls: {
        widgetProgress,
        setWidgetProgress,
        editAnswers,
      },
    }),
    [widgetProgress, userData]
  )
  return (
    <EstimateWidgetContext.Provider value={values}>
      {children}
    </EstimateWidgetContext.Provider>
  )
}
