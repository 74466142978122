import { useState } from 'react'

import { useDebounce } from '../../../lib/@getethos/hooks/useDebounce'

const DEBOUNCE_TIME = 500

interface UseTermCoverageParams {
  defaultTerm: number
  defaultCoverage: number
  debounceTime?: number
}

interface ChangeTermCoverageParams {
  newCoverage?: number
  newTerm?: number
}

interface HookReturn {
  term: number
  coverage: number
  debouncedTerm: number
  debouncedCoverage: number
  changeTermCoverage: ({
    newCoverage,
    newTerm,
  }: ChangeTermCoverageParams) => void
}

/**
 * Hooks that allows managing term and coverage
 */
export const useTermCoverage = ({
  defaultTerm,
  defaultCoverage,
  debounceTime = DEBOUNCE_TIME,
}: UseTermCoverageParams): HookReturn => {
  // Term and Coverage
  const [term, setTerm] = useState(defaultTerm)
  const [coverage, setCoverage] = useState(defaultCoverage)

  // debounce coverage and term so we only call the API after DEBOUNCE_TIME
  const debouncedTerm = useDebounce(term, debounceTime)
  const debouncedCoverage = useDebounce(coverage, debounceTime)

  // Handle term / coverage slider toggles
  // ------------------------------------------------------------
  const changeTermCoverage = ({
    newCoverage,
    newTerm,
  }: ChangeTermCoverageParams): void => {
    if (newTerm != null && newTerm !== term) {
      setTerm(newTerm)
    }
    if (newCoverage != null && newCoverage !== coverage) {
      setCoverage(newCoverage)
    }
  }

  return {
    // term and coverage can be used for reactive components like sliders
    term,
    coverage,
    // debounced term and coverage can be used for data fetching
    debouncedTerm,
    debouncedCoverage,
    changeTermCoverage,
  }
}
