import { useState } from 'react'

import { Button, Dropdown, Input } from '@getethos/ethos-design-system-v2'
import { yupResolver } from '@hookform/resolvers/yup'
import classnames from 'classnames'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { apiRoutes } from 'lib/@getethos/urls/apiRoutes'
import {
  Controller,
  FieldErrors,
  Resolver,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import { v4 as uuidv4 } from 'uuid'

import { useEstimateWidgetContext } from '@/components/EstimateWidget/contexts/EstimateWidgetContext'

import { useUpdatedCpFeLp } from '@/hooks/features/useUpdatedCpFeLp'

import AddressAutoComplete from '../AddressAutoComplete/AddressAutoComplete'
import { defaultPrimaryAddress } from '../AddressAutoComplete/utils'
import {
  CITIZENSHIP_OPTIONS,
  GENDER_OPTIONS,
} from '../FinalExpenseInfoForm/utils/constants'
import styles from './LeadForm.module.scss'
import Consent from './components/Consent'
import Overview from './components/Overview'
import TermsModal from './components/TermsModal'
import {
  LeadFormInputFields,
  birthDateMask,
  phoneMask,
  validationSchema,
} from './utils'

interface LeadFormInterface {
  title: string
  subtitle: string
  onSubmit: SubmitHandler<LeadFormInputFields>
  ctaLabel: string
  handleOnBlur?: (field: string) => void
}

const LeadForm = ({
  title,
  subtitle,
  ctaLabel,
  onSubmit,
  handleOnBlur,
}: LeadFormInterface) => {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)
  const [formId, setFormId] = useState(uuidv4())
  const {
    userDataControls: { userData },
  } = useEstimateWidgetContext()

  const { isTreatment2 } = useUpdatedCpFeLp()

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
    clearErrors,
    setError,
  } = useForm<LeadFormInputFields>({
    resolver: yupResolver(validationSchema(isTreatment2)) as Resolver<
      LeadFormInputFields,
      any
    >,
    defaultValues: {
      firstName: undefined,
      lastName: undefined,
      citizen: undefined,
      gender: userData?.gender,
      ...(isTreatment2 ? { phone: undefined } : {}),
      birthDate: undefined,
      isManualForm: false,
      primaryAddress: {
        ...defaultPrimaryAddress,
        region: userData?.region,
      },
    },
    mode: 'onSubmit',
  })

  const onTermsOpen = () => setIsTermsModalOpen(true)
  const onTermsClose = () => setIsTermsModalOpen(false)
  const onFormRerender = () => setFormId(uuidv4())

  const handleInvalidSubmit = (errors: FieldErrors<LeadFormInputFields>) => {
    const currentValues = getValues()
    estimateWidgetAnalytics.continueToSalesError({
      properties: {
        errorMessages: errors,
        currentValues,
        formType: 'Lead',
      },
    })
  }

  const validateAndSubmitForm = async () => {
    try {
      if (isTreatment2) {
        await apiRoutes.validatePhoneTwilio(getValues().phone)
      }
      onSubmit(getValues())
    } catch (error) {
      setError('phone', {
        type: 'manual',
        message:
          'Phone validation failed. Please ensure the phone number is correct and try again.',
      })
    }
  }

  return (
    <>
      <div className="flex min-h-[100vh] w-full flex-col bg-spearmint-cypress">
        <div className={styles.container}>
          <div className="mx-auto flex w-full max-w-[800px] flex-col items-start gap-6 pb-12 custom-md:self-center custom-md:pb-16">
            <h1 className={styles.title}>{title}</h1>
            <h2 className={styles.subtitle}>{subtitle}</h2>
          </div>
          <div className="">
            <div className={styles.flexWrapper}>
              <div className="hidden custom-md:block">
                <Overview onTermsOpen={onTermsOpen} />
              </div>
              <form
                key={formId}
                onSubmit={handleSubmit(
                  validateAndSubmitForm,
                  handleInvalidSubmit
                )}
                className="lg-max-w-full order-1 w-full flex-1 custom-md:order-2 custom-md:min-w-[380px] custom-md:max-w-[600px]"
              >
                <h4 className={styles.formTitle}>Get your rate in minutes</h4>
                <div className="grid grid-cols-1 gap-x-3 gap-y-6 custom-md:grid-cols-2">
                  <div className={styles.inputWrapper}>
                    <Controller
                      control={control}
                      name="firstName"
                      render={({
                        field: { ref, ...fieldRest },
                        fieldState: { error },
                      }) => (
                        <Input
                          {...fieldRest}
                          label="First name"
                          onBlur={() => handleOnBlur?.('firstName')}
                          inputSize="md"
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <Controller
                      control={control}
                      name="lastName"
                      render={({
                        field: { ref, ...fieldRest },
                        fieldState: { error },
                      }) => (
                        <Input
                          {...fieldRest}
                          onBlur={() => handleOnBlur?.('lastName')}
                          label="Last name"
                          inputSize="md"
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={styles.dropdownWrapper}>
                    <Controller
                      control={control}
                      name="gender"
                      render={({
                        field: { ref, onChange, value, ...fieldRest },
                        fieldState: { error },
                      }) => (
                        <Dropdown
                          {...fieldRest}
                          placeholder="Select"
                          label="Sex at birth"
                          menuShouldScrollIntoView
                          onBlur={() => handleOnBlur?.('gender')}
                          options={GENDER_OPTIONS}
                          isSearchable={false}
                          errorMessage={error?.message}
                          onChange={(options) => onChange(options?.value)}
                          value={GENDER_OPTIONS.find(
                            (option) => option.value === value
                          )}
                        />
                      )}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <Controller
                      control={control}
                      name="birthDate"
                      render={({
                        field: { ref, ...fieldRest },
                        fieldState: { error },
                      }) => (
                        <Input
                          {...fieldRest}
                          label="Date of birth"
                          onBlur={() => handleOnBlur?.('birthDate')}
                          inputSize="md"
                          placeholder="MM/DD/YYYY"
                          mask={birthDateMask}
                          // @ts-ignore
                          pipe={createAutoCorrectedDatePipe('mm/dd/yyyy')}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </div>
                  <div
                    className={classnames(
                      { 'md:col-span-2': !isTreatment2 },
                      styles.dropdownWrapper
                    )}
                  >
                    <Controller
                      control={control}
                      name="citizen"
                      render={({
                        field: { ref, onChange, value, ...fieldRest },
                        fieldState: { error },
                      }) => (
                        <Dropdown
                          {...fieldRest}
                          label="Citizenship"
                          placeholder="Select"
                          menuShouldScrollIntoView
                          onBlur={() => handleOnBlur?.('citizen')}
                          isSearchable={false}
                          options={CITIZENSHIP_OPTIONS}
                          errorMessage={error?.message}
                          value={CITIZENSHIP_OPTIONS.find(
                            (option) => option.value === value
                          )}
                          onChange={(options) => onChange(options?.value)}
                        />
                      )}
                    />
                  </div>
                  {isTreatment2 && (
                    <div className={styles.inputWrapper}>
                      <Controller
                        control={control}
                        name="phone"
                        render={({
                          field: { ref, ...fieldRest },
                          fieldState: { error },
                        }) => (
                          <Input
                            {...fieldRest}
                            label="Phone"
                            onBlur={() => handleOnBlur?.('phone')}
                            placeholder="(555) 555 - 5555"
                            mask={phoneMask}
                            inputSize="md"
                            errorMessage={error?.message}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
                <AddressAutoComplete
                  errors={errors}
                  setValue={setValue}
                  getFormValues={getValues}
                  control={control}
                  clearErrors={clearErrors}
                  onFormRerender={onFormRerender}
                />
                <Button
                  buttonTitle={ctaLabel}
                  size="lg"
                  variant="salamander"
                  className={styles.button}
                  leadingIcon="arrowForward"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  alignment="center"
                />
                <Consent buttonTitle={ctaLabel} />
              </form>
            </div>
          </div>
        </div>
        <div className="custom-md:hidden">
          <Overview onTermsOpen={onTermsOpen} />
        </div>
      </div>
      <TermsModal isTermsModalOpen={isTermsModalOpen} onClose={onTermsClose} />
    </>
  )
}

export default LeadForm
