// https://ethoslife.atlassian.net/browse/GC-1905
// https://app.optimizely.com/v2/projects/15340850549/features/23210951426
export const RISK_ID = 'riskIdCMS'

interface Feature {
  name: string
  variables?: Record<string, unknown>
  ruleKeys?: any
  audiences?: Array<string>
  cookieName?: string
  variations?: Record<string, string>
}

export const LIVING_EXPENSES = {
  name: 'monthly_and_children_gc-3569',
  variations: {
    treatment1: 'treatment1',
    treatment2: 'treatment2',
    off: 'off',
  },
}

export const FIVE_VS_TEN_MINUTES_HEADING = {
  '10minutes': 'Life insurance<br/>in 10 minutes',
  '5minutes': 'Life insurance<br/>in 5 minutes',
}

export const FIVE_VS_TEN_MINUTES_HEADER = {
  name: '5vs10header',
  cookieName: '5-VS-10-MINUTES-HEADER-FEATURE',
  variations: {
    '10minutes': '10minutes',
    '5minutes': '5minutes',
    off: 'off',
  },
}

export const BEST_MONEY_COBRANDED_HEADER = {
  name: 'best_money_cobranded_header_gc-2392',
  cookieName: 'BEST-MONEY-HEADER-FEATURE',
  audiences: ['/life/quick-online-quote/'],
  variations: {
    on: 'on',
    off: 'off',
  },
}

export const CREDIT_KARMA_COBRANDED_HEADER = {
  name: 'credit_karma_cobranded_header_gc-3082',
  cookieName: 'CREDIT-KARMA-HEADER-FEATURE',
  audiences: ['/life/credit-karma-members/'],
  variations: {
    on: 'on',
    off: 'off',
  },
  ruleKeys: {
    off: 'credit_karma_cobranded_header_gc-3082-off',
    on: 'credit_karma_cobranded_header_gc-3082-on',
  },
}

export const ENABLE_CMS_EW_REFACTOR = 'enable_cms_ew_refactor'

export const PAID_LANDERS_UPDATE = {
  name: 'gc-3933_paid_landers_update',
  variations: {
    treatment1: 'treatment1',
    treatment2: 'treatment2',
    treatment3: 'treatment3',
    off: 'off',
  },
  ruleKeys: {
    off: 'gc-3933_paid_landers_update-off',
    on: 'gc-3933_paid_landers_update-on',
  },
}

export const REMOVE_GOOGLE_TESTIMONIAL = {
  name: 'remove_google_testimonial_gc-3979',
  variations: {
    off: 'off',
    on: 'on',
  },
  ruleKeys: {
    off: 'remove_google_testimonial_gc-3979-off',
    on: 'remove_google_testimonial_gc-3979-on',
  },
}

export const EW_COPY = {
  name: 'ew_copy_gc-4005',
  variations: {
    off: 'off',
    curiosity: 'curiosity',
    curiosity_fear: 'curiosity_fear',
    doing_work: 'doing_work',
    urgency: 'urgency',
  },
}

export const WILLS_MODAL_DUAL_CTA = {
  name: 'wills_dual_modal_cta-cc-3924',
  variations: {
    off: 'off',
    on: 'on',
  },
}

export const DESIRED_COVERAGE = {
  name: 'desired_coverage',
  variations: {
    off: 'off',
    on: 'on',
  },
}
