type StorageType = 'localStorage' | 'sessionStorage'

export enum StorageKeys {
  LeadFormSubmitted = 'leadFormSubmitted',
}

export class Store {
  private storage: Storage

  constructor(storageType: StorageType) {
    const StorageObject = {
      localStorage: window.localStorage,
      sessionStorage: window.sessionStorage,
    }

    if (typeof window === 'undefined') {
      throw new Error('Storage is not available on the server')
    }
    this.storage =
      storageType in StorageObject ? StorageObject[storageType] : localStorage
  }

  setItem(key: string, value: any) {
    const valueStr = JSON.stringify(value)
    this.storage.setItem(key, valueStr)
  }

  getItem<T>(key: string): T | null {
    const value = this.storage.getItem(key)
    if (!value) return null
    try {
      return JSON.parse(value)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  removeItem(key: string) {
    this.storage.removeItem(key)
  }
  clear() {
    this.storage.clear()
  }
}
