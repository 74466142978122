import { FC } from 'react'

import styles from './ProgressBar.module.scss'

interface IProgressBarProps {
  progress: number
}

export const ProgressBar: FC<IProgressBarProps> = ({ progress }) => {
  return (
    <div
      className={`${styles.baseRoot}`}
      role="progressbar"
      aria-valuenow={progress}
    >
      <div
        className={styles.progressLength}
        style={{ width: `${progress}%` }}
      />
    </div>
  )
}
