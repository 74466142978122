import { FC, useEffect } from 'react'

import { Button, Dropdown, Input } from '@getethos/ethos-design-system-v2'
import { yupResolver } from '@hookform/resolvers/yup'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { Controller, FieldErrors, useForm } from 'react-hook-form'

import { useEstimateQuotesContext } from '@/components/EstimateWidget/contexts/EstimateQuotesContext'

import { GENDER_OPTIONS } from '../utils/constants'
import { validationSchema } from '../utils/validations'
import styles from './form.module.scss'

type FinalExpeseFormInputs = {
  gender: string
  birthDate: string
}

interface FinalExpenseFormProps {
  onSubmit: (formValues: FinalExpeseFormInputs) => void
  handleOnBlur?: (field: string) => void
  defaultValues?: FinalExpeseFormInputs
}

export const FinalExpenseForm: FC<FinalExpenseFormProps> = ({
  onSubmit,
  handleOnBlur,
  defaultValues,
}) => {
  const { control, handleSubmit, getValues, reset } =
    useForm<FinalExpeseFormInputs>({
      resolver: yupResolver(validationSchema),
      defaultValues,
    })

  const { userSelectedValues } = useEstimateQuotesContext()

  const onInvalidSubmit = (errors: FieldErrors<FinalExpeseFormInputs>) => {
    const currentValues = getValues()
    estimateWidgetAnalytics.continueToPricesError({
      properties: {
        triedToEditAnswers: !!userSelectedValues,
        errorMessages: errors,
        currentValues,
      },
    })
  }

  // we need this for async default values
  useEffect(() => {
    reset({ ...defaultValues })
  }, [defaultValues]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
      <div className={styles.dropdownWrapper}>
        <Controller
          control={control}
          name="gender"
          render={({ field, fieldState: { error } }) => (
            <Dropdown
              {...field}
              label="Sex at birth"
              options={GENDER_OPTIONS}
              errorMessage={error?.message}
              isSearchable={false}
              placeholder="Select"
              onBlur={() => handleOnBlur?.('gender')}
              onChange={(options) => field.onChange(options?.value)}
              value={GENDER_OPTIONS.find(
                (option) => option.value === field?.value
              )}
              defaultValue={GENDER_OPTIONS.find(
                (option) => option.value === defaultValues?.gender
              )}
            />
          )}
        />
      </div>
      <div className={styles.inputWrapper}>
        <Controller
          control={control}
          name="birthDate"
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              label="Age"
              placeholder="i.e. 67"
              inputSize="md"
              onBlur={() => handleOnBlur?.('birthDate')}
              errorMessage={error?.message}
            />
          )}
        />
      </div>
      <Button
        className={styles.button}
        alignment="center"
        buttonTitle="GET MY QUOTE"
        variant="salamander"
        type="submit"
        size="lg"
      />
    </form>
  )
}
