//@ts-nocheck
import { useEffect, useState } from 'react'

import { apiRoutes } from 'lib/@getethos/urls/apiRoutes'

import useSiteApiUrl from '@/hooks/useSiteApiUrl'

import { convertAgeToBirthDateISO } from '../../../lib/@getethos/api/api'
import {
  CreditScore,
  Product,
  QuoteFunnelCreditScore,
} from '../../../lib/@getethos/constants'
import { FeatureFlagConfig, UserData } from '../../../lib/@getethos/interfaces'
import { INVALID_STATES, STATE_ERROR } from '../../constants'

interface UsePriceRangeParams {
  userData: Partial<UserData> | null
  isUserDataComplete: boolean
  term: number
  coverage: number
  product: Product
  region: null | string
  analytics: any
  featureFlagConfig?: FeatureFlagConfig
  creditScore: CreditScore
}

interface Range {
  min: number
  max: number
}

interface HookReturn {
  ranges: Range
  prevRanges: Range
  error: string | null
  clearError: () => void
}

export const usePriceRange = ({
  userData,
  isUserDataComplete,
  term,
  coverage,
  product,
  region,
  analytics,
  featureFlagConfig,
  creditScore,
}: UsePriceRangeParams): HookReturn => {
  const siteApiUrl = useSiteApiUrl()
  if (!siteApiUrl) {
    throw new TypeError('siteApiUrl is undefined')
  }

  const [ranges, setRanges] = useState({ min: 0, max: 0 })
  const [prevRanges, setPrevRanges] = useState({ min: 0, max: 0 })
  const [error, setError] = useState<string | null>(null)

  const usePriceElasticityFlag =
    featureFlagConfig && featureFlagConfig.usePriceElasticity

  function successRangeResponse({ minPrice, maxPrice }, finalParams): void {
    if (product === Product.Term && usePriceElasticityFlag) {
      minPrice = minPrice * 1.2
      maxPrice = maxPrice * 1.2
    }

    setPrevRanges({
      min: ranges.min,
      max: ranges.max,
    })
    setRanges({
      min: minPrice,
      max: maxPrice,
    })
    analytics.adjustedEstimatePrice({
      properties: {
        formParams: finalParams,
        minPrice,
        maxPrice,
        product,
        priceElasticityEnabled: usePriceElasticityFlag,
      },
    })
  }

  async function requestQuoteApiPriceRange(formData): Promise<void> {
    if (region && INVALID_STATES.includes(region)) {
      setError(STATE_ERROR)
      return
    }

    const { gender, birthDate, smoker, product } = formData
    const birthDateISO = !Number.isNaN(Number(birthDate))
      ? convertAgeToBirthDateISO(birthDate).substring(0, 10)
      : new Date(birthDate).toISOString().substring(0, 10)
    let quoteFunnelCreditScore
    switch (creditScore) {
      case CreditScore.Average:
        quoteFunnelCreditScore = QuoteFunnelCreditScore.Average
        break
      case CreditScore.Low:
        quoteFunnelCreditScore = QuoteFunnelCreditScore.Low
        break
      case CreditScore.High:
      case CreditScore.NoAnswer:
      default:
        quoteFunnelCreditScore = QuoteFunnelCreditScore.High
        break
    }

    const mainParams = {
      gender: gender.toLowerCase(),
      birthDate: birthDateISO,
      region: region == null ? 'AL' : region, //This guard should not be relevant in the current workflow
      targetAmount: coverage,
      creditScore: quoteFunnelCreditScore,
      term,
      tobacco: smoker === 'true',
      product,
    }

    const rangeResponse = await apiRoutes.quotes(mainParams)

    const finalParams = {
      gender,
      birthDate: mainParams.birthDate,
      smoker,
      state: mainParams.region,
      coverage: coverage.toString(),
      term: term.toString(),
    }

    if (!rangeResponse.error) {
      successRangeResponse(rangeResponse, finalParams)
    } else {
      setError(
        'Something went wrong on our end! Please try again or contact us for assistance.'
      )
    }
  }

  const clearError = (): void => {
    setError(null)
  }

  // useFetchPackages
  useEffect(() => {
    if (
      !userData ||
      !coverage ||
      !term ||
      product === null ||
      !isUserDataComplete ||
      !coverage ||
      !term ||
      region === null
    ) {
      return
    }

    if (region) {
      if (INVALID_STATES.includes(region)) {
        setError(STATE_ERROR)
        return
      } else {
        clearError()
      }
    }
    requestQuoteApiPriceRange({
      ...userData,
      coverage,
      term,
      product,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, term, coverage, product, region])

  return {
    ranges,
    prevRanges,
    error,
    clearError,
  }
}
