import { useRouter } from 'next/router'

import Cookies from 'js-cookie'
import { useGetQueryParam } from 'src/hooks/useGetQueryParam'

const audiencePaths = ['/life/quick-online-quote/']

export const useForbesHeaderNavbar = () => {
  const { asPath } = useRouter()
  const queryParams = useGetQueryParam('utm_term')

  const pathnameWithoutQuery = asPath.split('?')[0]
  const isValidAudiencePath = audiencePaths.includes(pathnameWithoutQuery)

  const isForbesUtmTerm = queryParams === '1955282'

  const isEnabled = isValidAudiencePath && isForbesUtmTerm

  if (isEnabled) {
    // cookie used by PreInterview
    Cookies.set('FORBES-HEADER-EXPERIMENT', 'on', {
      expires: 365,
    })
  }

  return isEnabled
}
