import { FC, useState } from 'react'

import { useDirectToAppContext } from '@/contexts/DirectToAppContext'
import { zodResolver } from '@hookform/resolvers/zod'
import classNames from 'classnames'
import { FormProvider, useForm } from 'react-hook-form'

import { UserData } from '@/components/EstimateWidget/types/user'

import styles from './DirectToAppPreinterviewForm.module.scss'
import { QuestionCard } from './components/QuestionCard/QuestionCard'
import { questionsConfig } from './config/config'
import { Form, validationSchema } from './config/schemas'

interface IDirectToAppPreinterviewForm {
  onSubmit: (data: UserData) => void
}

const defaultValues = {
  step_1: {
    gender: '',
  },
  step_2: {
    birthDate: '',
  },
  step_3: {
    zipCode: '',
  },
  step_4: {
    smoker: undefined,
  },
}

export const DirectToAppPreinterviewForm: FC<IDirectToAppPreinterviewForm> = ({
  onSubmit,
}) => {
  const [currentStep, setCurrentStep] = useState(0)

  const { handleProgress } = useDirectToAppContext()
  const methods = useForm<Form>({
    shouldUnregister: false,
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  })

  const { handleSubmit } = methods

  const submitForm = (data: Form) => {
    const mappedData = Object.keys(data).reduce((acc, key) => {
      const stepData = data[key as keyof Form]
      return { ...acc, ...stepData }
    }, {} as Form)
    onSubmit(mappedData as unknown as UserData)
  }

  const handleNext = () => {
    if (currentStep < questionsConfig.length - 1) {
      setCurrentStep((prev) => prev + 1)
    } else {
      submitForm(methods.getValues())
    }
    handleProgress(currentStep + 2)
  }

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1)
      handleProgress(currentStep - 1)
    }
  }

  return (
    <div
      className={classNames(
        'mx-auto max-w-[500px] px-6 py-4 lg:py-10',
        styles.wrapper
      )}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => submitForm(data))}>
          <QuestionCard
            step={currentStep}
            questionsLength={questionsConfig.length}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        </form>
      </FormProvider>
    </div>
  )
}
