import React, { useEffect } from 'react'

import Image from 'next/image'

import { useModuleContext } from '@/contexts/ModuleContext'
import consumerLogo from '@/img/ethos-logo-forest.svg'
import { Button } from '@getethos/ethos-design-system-v2'
import { CloudinaryImage } from 'ethos-design-system'

import useDetectScreenSize from '@/hooks/useDetectScreenSize'

import styles from './SingleCTADirect.module.scss'

export const TRUST_IMAGE_GROUPS = [
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1729174231/forbes_logo_hyku4n.svg',
    imageAlt: 'forbes instant',
  },
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1729150002/business_insider_wfqbrp.svg',
    imageAlt: 'business insider',
  },
]

const SingleCTADirect = ({
  handleGoToMainApp,
}: {
  handleGoToMainApp: () => void
}) => {
  const { isLaptopAndUp } = useDetectScreenSize()

  const {
    navBarControls: { hideNavBar },
  } = useModuleContext()

  useEffect(() => {
    hideNavBar(!isLaptopAndUp)
  }, [isLaptopAndUp])

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <div className={styles.logo}>
            <img src={consumerLogo.src} alt="Ethos Logo" />
          </div>

          <h1 className={styles.header}>
            Instant
            <br /> life insurance
          </h1>
          <div className="mb-[42px] flex items-center gap-2">
            <p className={styles.subheader}>no medical exams</p>
            <div className="mb-1 h-[6px] w-[6px] rounded-full bg-[#82B0AB]"></div>
            <p className={styles.subheader}>no blood tests</p>
          </div>

          <Button
            className={styles.button}
            buttonTitle="GET MY RATES"
            alignment="center"
            variant="salamander"
            type="submit"
            size="lg"
            onClick={handleGoToMainApp}
          />
          <div className={styles.trustImages}>
            {TRUST_IMAGE_GROUPS?.map((trustImageGroup, index) => (
              <CloudinaryImage
                publicId={trustImageGroup.groupImage}
                alt={trustImageGroup.imageAlt}
                className={styles.trustImage}
                crop={CloudinaryImage.CROP_METHODS.FIT}
                height={[142, 142, 142, 142]}
                width={[85, 85, 85, 85]}
                key={`trustImage-${index}`}
              />
            ))}
          </div>
        </div>

        <div className={styles.bgContainer}>
          <div className={styles.bgImage}>
            <Image
              priority
              src="https://res.cloudinary.com/getethos/image/upload/v1665678125/family_stock_photo_2_plqkwh.png"
              alt="Family"
              width={500}
              height={560}
              quality={100}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SingleCTADirect
