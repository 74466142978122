/*global google*/

import React, { useState } from 'react'

import {
  Dropdown,
  Input,
  SearchSelectInput,
} from '@getethos/ethos-design-system-v2'
import classnames from 'classnames'
import { ReactSelectComponents } from 'ethos-design-system'
import { isEmpty } from 'lodash'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form'

import { STATE_OPTIONS } from '../FinalExpenseInfoForm/utils/constants'
import styles from '../LeadForm/LeadForm.module.scss'
import { LeadFormInputFields } from '../LeadForm/utils'
import {
  STREET_FIELD_ERROR_MESSAGE,
  STREET_FIELD_MAX_CHARS,
  defaultPrimaryAddress,
  parseUSResult,
  stringifyAddress,
} from './utils'

/**
 * SingleValue and Placeholder are removed because, for some reason,
 * if you add an inputValue prop and click on the select on mobile,
 * there is no menu or input focus because a focus+blur event happens sequentially.
 */
const components = {
  Placeholder: () => null,
  SingleValue: () => null,
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null,
  Input: ({ ...props }) => (
    <ReactSelectComponents.Input
      {...props}
      data-tid={'street-auto-complete-tid'}
      id={'street-auto-complete'}
      name="primaryAddress"
      placeholder="123 Main St"
    />
  ),
}

interface AddressAutoCompletePropType {
  control: Control<LeadFormInputFields, any>
  errors: FieldErrors<LeadFormInputFields>
  clearErrors: UseFormClearErrors<LeadFormInputFields>
  getFormValues: UseFormGetValues<LeadFormInputFields>
  setValue: UseFormSetValue<LeadFormInputFields>
  onFormRerender: () => void
}

type AddressOption = {
  label: string
  value: string
}

type SelectInputAction =
  | 'set-value'
  | 'input-change'
  | 'input-blur'
  | 'menu-close'

/**
 * Autocomplete the primary address based on Google Place Autocomplete API results
 */
const AddressAutoComplete = ({
  control,
  clearErrors,
  errors,
  setValue,
  getFormValues,
  onFormRerender,
}: AddressAutoCompletePropType) => {
  const { primaryAddress, isManualForm } = getFormValues()
  const [addressInput, setAddressInput] = useState(
    stringifyAddress(primaryAddress) || ''
  )

  const [streetExceedsLimit, setStreetExceedsLimit] = useState(false)
  const [addressSelected, setAddressSelected] = useState(
    stringifyAddress(primaryAddress) !== ''
  )

  const setIsManualForm = (isManual: boolean) =>
    setValue('isManualForm', isManual)

  const handleManualForm = (clickedManually = false) => {
    setIsManualForm(true)
    clearErrors('primaryAddress')
    if (clickedManually) {
      setValue('primaryAddress', defaultPrimaryAddress)
      onFormRerender()
    }
  }

  // Each time an address item is selected, we immediately make another API call to fetch
  // more detail about the location that can be parsed into individual fields.
  const onAddressSelected = async (newOption: AddressOption | null) => {
    const { value } = newOption || { value: null }
    if (addressSelected) {
      setAddressSelected(false)
    }

    if (!value || value === 'manualAddress') {
      handleManualForm(true)
      return
    }

    const placeService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    )

    await placeService.getDetails(
      {
        placeId: value,
        fields: ['address_components'],
      },
      (
        result: google.maps.places.PlaceResult,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK || !result) {
          handleManualForm(true)
          return
        }
        const { locality, region, street1, postalCode } = parseUSResult(result)

        /**
         * If the address has no postalCode, we cannot parse it.
         * Allow user to manually input address
         */
        if (!postalCode) {
          handleManualForm()
          return
        }
        const parsedPrimaryAddress = {
          ...primaryAddress,
          region,
          street1,
          postalCode,
          locality,
        }
        setValue('primaryAddress', parsedPrimaryAddress)
        setAddressSelected(true)
        setStreetExceedsLimit(false)
        // Triggers the parent form to rerender and update the field with the autocompleted values
        onFormRerender()
      }
    )
  }

  const loadOptions = (inputValue: string): Promise<AddressOption[]> => {
    return new Promise((resolve) => {
      const displaySuggestions = (
        predictions: google.maps.places.QueryAutocompletePrediction[] | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (
          status !== google.maps.places.PlacesServiceStatus.OK ||
          !predictions
        ) {
          return resolve([
            { label: 'Enter Address Manually', value: 'manualAddress' },
          ])
        }

        const options = predictions
          ? predictions.map((prediction) => ({
              label: prediction.description,
              value: prediction.place_id,
            }))
          : []

        return resolve(options)
      }

      const autoCompleteService =
        new window.google.maps.places.AutocompleteService()

      return autoCompleteService.getPlacePredictions(
        {
          input: inputValue,
          componentRestrictions: {
            country: 'us',
          },
          types: ['address'],
        },
        displaySuggestions
      )
    })
  }

  const onInputChange = (
    value: string,
    { action }: { action: SelectInputAction }
  ) => {
    if (action === 'input-change') {
      // exceed the maximum limit
      setStreetExceedsLimit(String(value).length > STREET_FIELD_MAX_CHARS)
      setAddressInput(value)
    }
  }

  const errorMessage = streetExceedsLimit
    ? STREET_FIELD_ERROR_MESSAGE
    : !isEmpty(errors?.primaryAddress)
    ? 'Please provide an address'
    : ''

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // when user press delete/backspace button, we want to clear out the entire field
    if (e.keyCode === 8 && addressSelected) {
      setValue('primaryAddress', defaultPrimaryAddress)
      onFormRerender()
    }
  }

  if (isManualForm) {
    return (
      <div className="grid w-full grid-cols-8 gap-x-3 gap-y-6 pt-6">
        <div className={classnames('col-span-6', styles.inputWrapper)}>
          <Controller
            control={control}
            name="primaryAddress.street1"
            render={({
              field: { ref, ...fieldRest },
              fieldState: { error },
            }) => (
              <Input
                label="Street"
                inputSize="md"
                {...fieldRest}
                errorMessage={error?.message}
              />
            )}
          />
        </div>
        <div className={classnames('col-span-2', styles.inputWrapper)}>
          <Controller
            control={control}
            name="primaryAddress.street2"
            render={({
              field: { ref, ...fieldRest },
              fieldState: { error },
            }) => (
              <Input
                label="Apt"
                inputSize="md"
                {...fieldRest}
                errorMessage={error?.message}
              />
            )}
          />
        </div>
        <div className={classnames('col-span-4', styles.inputWrapper)}>
          <Controller
            control={control}
            name="primaryAddress.postalCode"
            render={({
              field: { ref, ...fieldRest },
              fieldState: { error },
            }) => (
              <Input
                label="Zip Code"
                inputSize="md"
                {...fieldRest}
                errorMessage={error?.message}
              />
            )}
          />
        </div>
        <div className={classnames('col-span-4', styles.inputWrapper)}>
          <Controller
            control={control}
            name="primaryAddress.locality"
            render={({
              field: { ref, ...fieldRest },
              fieldState: { error },
            }) => (
              <Input
                label="City"
                inputSize="md"
                {...fieldRest}
                errorMessage={error?.message}
              />
            )}
          />
        </div>
        <div className={classnames('col-span-8', styles.dropdownWrapper)}>
          <Controller
            control={control}
            name="primaryAddress.region"
            render={({
              field: { ref, ...fieldRest },
              fieldState: { error },
            }) => (
              <Dropdown
                label="State"
                {...fieldRest}
                menuShouldScrollIntoView
                onChange={({ value }: any) => fieldRest.onChange(value)}
                options={STATE_OPTIONS}
                value={STATE_OPTIONS.find(
                  (option) => option.value === primaryAddress?.region
                )}
                errorMessage={error?.message}
              />
            )}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="grid w-full grid-cols-10 gap-x-3 gap-y-6 pt-6">
      <div className={classnames('col-span-8', styles.dropdownWrapper)}>
        <SearchSelectInput
          tabIndex={0}
          data-tid="address-auto-complete"
          onChange={onAddressSelected}
          label="Address"
          instanceId="address"
          openMenuOnClick={false}
          onKeyDown={onKeyDown}
          errorMessage={errorMessage}
          menuPlacement="top"
          loadOptions={loadOptions}
          inputValue={addressInput || stringifyAddress(primaryAddress)}
          onInputChange={onInputChange}
          components={components}
        />
      </div>
      <div className={classnames('col-span-2', styles.inputWrapper)}>
        <Controller
          control={control}
          name="primaryAddress.street2"
          render={({ field: { ref, ...fieldRest }, fieldState: { error } }) => (
            <Input
              label="Apt"
              inputSize="md"
              {...fieldRest}
              errorMessage={error?.message}
            />
          )}
        />
      </div>
    </div>
  )
}

export default AddressAutoComplete
