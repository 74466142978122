import { useEffect } from 'react'

import { Button, Modal } from '@getethos/ethos-design-system-v2'

interface TermsModalProps {
  isTermsModalOpen: boolean
  onClose: () => void
}

const TermsModal = ({ isTermsModalOpen, onClose }: TermsModalProps) => {
  // TODO: move to EDS v2
  useEffect(() => {
    document.body.style.overflow = isTermsModalOpen ? 'hidden' : 'auto'
  }, [isTermsModalOpen])

  return (
    <Modal open={isTermsModalOpen} onClose={onClose} variant="success">
      <div className="flex flex-col gap-3">
        <h1 className="body-m-med text-[--theme-fg-default]">Product terms</h1>
        <span className="label-reg pb-4 text-[--neutrals-night-60]">
          TruStage™ Simplified Issue Term Life Insurance is issued by CMFG Life
          Insurance Company. This is a term policy to age 80 that has premiums
          that increase when entering each five-year band: 25, 30, 35, 40, 45,
          50, 55, 60, 65, 70, and 75. Base Form Numbers for coverage under 100k:
          ICC16-A10a-039, A10a-039-2016 & over 100k ICC17-SIT-2, 2017-SIT-2.
          TruStage™ Guaranteed Acceptance Whole Life Insurance is issued by CMFG
          Life Insurance Company. Death benefit is limited the first 2 years of
          the policy and is limited to a return of 100% of premiums, plus 10%.
          Base Form Numbers: ICC16-GAWL, 2016-GAWL. TruStage™ Advantage Whole
          Life Insurance is issued by MEMBERS Life Insurance Company. Base Form
          Numbers: ICC20-WLMLIC, 2020-WLMLIC. The insurance offered is not a
          deposit, and is not federally insured, sold, or guaranteed by any
          depository institution. Product and features may vary and not be
          available in all states. Corporate Headquarters: 5910 Mineral Point
          Road, Madison, WI 53705. © TruStage
          <br />
          LEGAL: Privacy | Terms and Conditions | Confidentiality
          <br />
          GAWL, SIT1, SIT2, TAWL-6056453.1-1023-1125
        </span>
        <Button
          buttonTitle="Done"
          size="md"
          variant="grayOutline"
          onClick={onClose}
        />
      </div>
    </Modal>
  )
}

export default TermsModal
