//@ts-nocheck

import React from 'react'

import {
  Body,
  Button,
  COLORS,
  Form,
  Modal,
  Spacer,
  TitleLarge2,
} from 'ethos-design-system'

import styles from '../../../components/EstimateWidget/EstimateWidget.module.scss'
import { EstimateWidgetVariations } from '../../../components/EstimateWidget/common/variations'
import { CloseButtonCircle } from '../../../icons'
import { emailSuccessIcon } from './emailSuccessIcon'
import {
  EmailFormField,
  EmailModalParams,
  getEmailModalFields,
} from './getEmailModalFields'

const emailSuccessHeading = 'Sent!'
const emailSuccessSubHeading =
  'We emailed your personalized life insurance estimates right to your inbox.'

interface EmailModalProps {
  email: string
  emailModal: boolean
  emailModalClose: () => void
  emailSuccess: boolean
  emailFieldDataTid: string
  buttonTid: string
  saveEmailFormCallback: (formData: any) => void
  emailHeading: string
  emailSubHeading: string
  emailSendLabel: string
  variation: string
}

const EmailModal: React.FC<EmailModalProps> = ({
  emailModal,
  emailModalClose,
  email,
  emailSuccess,
  emailFieldDataTid,
  buttonTid,
  saveEmailFormCallback,
  emailHeading,
  emailSubHeading,
  emailSendLabel,
  variation,
}) => {
  const emailModalParams: EmailModalParams = {
    placeholder: 'Your email address',
    emailValue: email,
    dataTid: emailFieldDataTid,
    labelCopy: 'Email address',
  }
  const shouldUseFloatEmailForm =
    variation === EstimateWidgetVariations.FLOATING_LEAN

  if (shouldUseFloatEmailForm) {
    emailModalParams.placeholder = ''
    emailModalParams.allCaps = false
    emailModalParams.labelWeight = 'regular'
    emailModalParams.labelColor = COLORS.GRAY_SECONDARY
    emailModalParams.classOverrides = 'bg-gray-1 rounded-sm border-none'
  }

  const fields: EmailFormField = getEmailModalFields(emailModalParams)

  return (
    <Modal
      isOpen={emailModal}
      onDismiss={emailModalClose}
      ariaLabelledBy="Save estimates"
      ariaDescribedBy="Save estimates"
    >
      <Form
        config={{
          formName: 'Estimate Widget Form Part 3',
          formId: 'EstimateWidgetEmail',
          autocompleteOff: false,
          fields,
          onSubmit: saveEmailFormCallback,
        }}
      >
        {({ field, getFormIsValid }: any) => {
          const floatingEmailForm = (
            <div
              className={[
                styles.email,
                styles[shouldUseFloatEmailForm ? 'floating' : ''],
              ].join(' ')}
            >
              <button
                type="button"
                onClick={emailModalClose}
                className={styles.close}
              >
                <CloseButtonCircle />
              </button>
              <div className={styles.emailHeading}>
                <TitleLarge2.Sans.Medium500>
                  {emailSuccess ? emailSuccessHeading : emailHeading}
                </TitleLarge2.Sans.Medium500>
              </div>
              <Body.Regular400>
                {emailSuccess ? emailSuccessSubHeading : emailSubHeading}
              </Body.Regular400>
              {emailSuccess && <Spacer.H24 />}
              <Spacer.H24 />
              {!emailSuccess && field('email')}
              {emailSuccess && <Spacer.H24 />}
              {emailSuccess ? (
                emailSuccessIcon
              ) : (
                <Button.Medium.Black
                  type="submit"
                  disabled={!getFormIsValid()}
                  data-tid={buttonTid}
                >
                  {emailSendLabel}
                </Button.Medium.Black>
              )}
            </div>
          )

          const defaultEmailForm = (
            <div className={[styles.email, styles[variation]].join(' ')}>
              <button
                type="button"
                onClick={emailModalClose}
                className={styles.close}
              >
                <CloseButtonCircle />
              </button>
              <div className={styles.emailHeading}>
                <TitleLarge2.Sans.Regular400>
                  {emailSuccess ? emailSuccessHeading : emailHeading}
                </TitleLarge2.Sans.Regular400>
              </div>
              <Body.Regular400>
                {emailSuccess ? emailSuccessSubHeading : emailSubHeading}
              </Body.Regular400>
              {emailSuccess && <Spacer.H24 />}
              <Spacer.H24 />
              {!emailSuccess && field('email')}
              {emailSuccess && <Spacer.H24 />}
              {emailSuccess ? (
                <Button.Medium.BlackOutline onClick={emailModalClose}>
                  Back to estimates
                </Button.Medium.BlackOutline>
              ) : (
                <Button.Medium.Black
                  type="submit"
                  disabled={!getFormIsValid()}
                  data-tid={buttonTid}
                >
                  {emailSendLabel}
                </Button.Medium.Black>
              )}
            </div>
          )
          return shouldUseFloatEmailForm ? floatingEmailForm : defaultEmailForm
        }}
      </Form>
    </Modal>
  )
}

export default EmailModal
