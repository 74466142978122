import { FC, useEffect } from 'react'

import { useLegalBannerContext } from '@/contexts/LegalBannerContext'
import { Button } from 'ethos-design-system'
import { genericEvents } from 'lib/@getethos/analytics/analyticsEvents'
import { get } from 'lodash'
import { Controller, useFormContext } from 'react-hook-form'

import { questionsConfig } from '../../config/config'
import { Form } from '../../config/schemas'
import PreviousButton from '../PreviousButton/PreviousButton'
import styles from './QuestionCard.module.scss'

interface IQuestionCardProps {
  step: number
  questionsLength: number
  onNext: () => void
  onPrevious: () => void
}

type Steps = 'step_1' | 'step_2' | 'step_3' | 'step_4'

export const QuestionCard: FC<IQuestionCardProps> = ({
  step,
  onNext,
  onPrevious,
  questionsLength,
}) => {
  const currentStep = questionsConfig[step]
  const {
    control,
    trigger,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext<Form>()

  useEffect(() => {
    genericEvents.questionShown({
      questionId: currentStep.field.name,
    })
  }, [step])

  const { isDisclaimerOpen, disclaimerHeight } = useLegalBannerContext()
  const legalBannerHeight = (isDisclaimerOpen && disclaimerHeight) || 0

  const configStep = `step_${step + 1}` as Steps

  const currentName = currentStep?.field?.name

  const fieldName = `${configStep}.${currentName}` as keyof Form

  const handleNext = async () => {
    const isValid = await trigger(configStep)
    if (!isValid) return
    genericEvents.questionAnswered({
      questionId: currentStep.field.name,
      answer: watch(fieldName),
    })
    onNext()
  }
  const handlePrevious = () => {
    clearErrors()
    onPrevious()
  }

  const currentStepError = get(errors, fieldName)?.message
  const currentValue = watch(`${configStep}.${currentName}` as keyof Form)
  const shouldAutoSubmit = currentStep.field.options.autoSubmit && !currentValue
  const shouldShowBackButton = step > 0
  const isLastQuestion = step === questionsLength - 1

  const disableButton = !!currentStepError
  return (
    <div key={fieldName}>
      <div className={styles.prompt}>{currentStep.prompt}</div>
      <Controller
        control={control}
        name={fieldName}
        render={({ field }) => {
          return (
            <>
              {currentStep.field.component(
                {
                  currentValue: field.value as unknown as string,
                  onChange: (value: any) => {
                    field.onChange(value)
                    if (currentStepError) {
                      trigger(configStep)
                    }
                    if (shouldAutoSubmit) handleNext()
                  },
                  name: fieldName,
                },
                currentStep.field.options
              )}
              {currentStepError && (
                <div className="body-s-reg eds2-mt-[12px] eds2-text-[--theme-error-emphasis]">
                  {currentStepError}
                </div>
              )}
            </>
          )
        }}
      />
      <div
        className={styles.buttons}
        style={{
          bottom: `calc(${legalBannerHeight}px + 24px)`,
        }}
      >
        {!shouldAutoSubmit && (
          <Button.Medium.Black
            arrowIcon
            type={isLastQuestion ? 'submit' : 'button'}
            onClick={isLastQuestion ? undefined : handleNext}
            fullWidth
            disabled={disableButton}
          >
            Next
          </Button.Medium.Black>
        )}
        {shouldShowBackButton && <PreviousButton onClick={handlePrevious} />}
      </div>
    </div>
  )
}
