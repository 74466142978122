import React from 'react'

import { Button, CloudinaryImage, Spacer } from 'ethos-design-system'

import PriorityImage from '../../global/PriorityImage'
import styles from '../SingleCTA.module.scss'
import { SingleCTAInterface } from '../interface'
import { InsuranceInfo } from './FinalExpense'
import { SpeakWithExpert } from './SpeakWithExpert/SpeakWithExpert'

const FinalExpenseWithCallSection = ({
  moduleData,
  ctaClick,
  phoneNumber,
}: {
  moduleData: SingleCTAInterface
  ctaClick: () => void
  phoneNumber: {
    key: string
    value: string
  } | null
}) => {
  const { groupImage, imageAlt } = moduleData

  const navbarPadding =
    moduleData?.navbarPadding === '64' ? (
      <div className="hidden w-full md:block">
        <Spacer.H64 />
      </div>
    ) : (
      <div className="w-full">
        <Spacer.H80 />
      </div>
    )

  return (
    <>
      <div className={styles.finalExpense}>
        <div className={styles.container}>
          {moduleData.navbarPadding !== '0' && <>{navbarPadding}</>}
          <div className={styles.mainContent}>
            <h1 className={styles.header}>Final expense insurance</h1>
            <InsuranceInfo />
            <div>
              <div className={styles.ctaPhoneContainer}>
                <Button.Medium.DarkSalamander
                  fullWidth
                  onClick={() => ctaClick()}
                >
                  APPLY ONLINE
                </Button.Medium.DarkSalamander>
                <div className={styles.phoneContainer}>
                  <p className="m-0 text-xl">Or call for dedicated support</p>
                  <a
                    className={styles.phoneNumber}
                    href={phoneNumber?.value}
                    target="_blank"
                  >
                    {phoneNumber?.key}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.laptopMidAndUpOnly}>
            <Spacer.H80 />
          </div>
        </div>
        <div className={styles.bgContainer}>
          <div className={styles.bgImage}>
            {groupImage && imageAlt && (
              <PriorityImage
                publicId={groupImage}
                fetchpriority="high"
                alt={imageAlt}
                crop={CloudinaryImage.CROP_METHODS.CROP}
                height={[0, 384, 729, 972]}
                width={[0, 768, 1440, 1920]}
              />
            )}
          </div>
        </div>
      </div>
      <SpeakWithExpert phoneNumber={phoneNumber} />
    </>
  )
}

export default FinalExpenseWithCallSection
