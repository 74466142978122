//@ts-nocheck

import React from 'react'

import {
  ButtonSelectGroup,
  COLORS,
  CheckboxInput,
  NumberInput,
  OPTION_BUTTON_STYLES,
  ZipInput,
} from 'ethos-design-system'
import validateNumberRange from 'ethos-design-system/src/validators/NumberInputValidator'
import validateExists from 'ethos-design-system/src/validators/validateExists'

import { ageFromBirthDate } from '../../../../lib/@getethos/utils/ageFromBirthDate'
import {
  EstimatedCredit,
  estimatedCreditOptionsWithoutNumericValue,
} from '../../../shared/Estimate/EstimatedCredit'

/**
 * returns a function to be called as child by Form.js field
 * that function returns a ButtonSelectGroup injected with required props
 * @param initialValue
 * @param labelCopy
 * @returns {function(*, *)}
 */
function floatingButtonFactory(
  initialValue: any,
  labelCopy: string
): (
  props: object,
  options: [{ value: string; copy: string }]
) => React.JSX.Element {
  return function (props, options): React.JSX.Element {
    const [hasMounted, setHasMounted] = React.useState(false)
    // prefill button on url params requires delayed second render
    // because of gatsby production hydration
    // https://www.joshwcomeau.com/react/the-perils-of-rehydration/
    React.useEffect(() => {
      setHasMounted(true)
    }, [])

    if (hasMounted) {
      props['currentValue'] = initialValue
    }

    return (
      <div className={'w-full'}>
        <ButtonSelectGroup
          labelCopy={labelCopy}
          {...props}
          buttonStyle={OPTION_BUTTON_STYLES.FLOATING}
          allCaps={false}
          capitalize={false}
          labelColor={COLORS.GRAY_SECONDARY}
          labelWeight={'regular'}
        >
          {options.map((option) => (
            <ButtonSelectGroup.Option
              value={option.value}
              key={`buttonSelectGroup${option.value}`}
            >
              {option.copy}
            </ButtonSelectGroup.Option>
          ))}
        </ButtonSelectGroup>
      </div>
    )
  }
}

function noop(): void {
  // do nothing
}

export const getFloatingFields = ({
  minimumEligibleAge,
  maximumEligibleAge,
  genderQuestion = '',
  birthdateQuestion = ' ', // must be a space to prevent label from showing
  nicotineQuestion = '',
  zipcodeQuestion = '',
  displayEstimatedCredit,
  estimatedCreditQuestion = '',
  estimatedCreditFootnote = '',
  sendAnalyticsEvent,
  fetchStateFromZipCallback = noop,
  dataTid: DATA_TID,
  userData,
  variation,
  hideNicotineQuestion,
}: {
  minimumEligibleAge: number
  maximumEligibleAge: number
  genderQuestion?: string
  birthdateQuestion?: string
  nicotineQuestion?: string
  healthQuestion?: string
  zipcodeQuestion?: string
  displayEstimatedCredit?: boolean
  estimatedCreditQuestion?: string
  estimatedCreditFootnote?: string
  dateFormat: any
  sendAnalyticsEvent: any
  fetchStateFromZipCallback?
  dataTid: {
    GENDER_INPUT: string
    NICOTINE_INPUT: string
    HEALTH_INPUT: string
    BIRTHDATE_INPUT: string
    ZIPCODE_INPUT: string
    ESTIMATED_CREDIT_INPUT: string
  }
  hideNicotineQuestion?: boolean
  userData?: {
    gender?: string
    smoker?: string
    health?: string
    birthDate?: string
    zipCode?: string
  }
  variation?: string
}): {
  gender: object
  smoker: object
  health?: object
  birthDate: object
  zipCode: object
  estimatedCredit?: object
} => {
  const initialValues = userData ? userData : {}
  const age: number | undefined = ageFromBirthDate({
    birthDate: initialValues.birthDate,
  })

  const gender = {
    component: floatingButtonFactory(initialValues.gender, genderQuestion),
    validators: [validateExists],
    validationSuccess: [sendAnalyticsEvent],
    options: [
      { value: 'Female', copy: 'Female' },
      { value: 'Male', copy: 'Male' },
    ],
    tid: DATA_TID.GENDER_INPUT,
  }

  const smoker = {
    component: (formProps: any): React.JSX.Element => {
      return (
        <div className={'w-full'}>
          <CheckboxInput
            initialValue={initialValues.smoker === 'true'}
            {...formProps}
          >
            {nicotineQuestion}
          </CheckboxInput>
        </div>
      )
    },
    optional: true,
    validators: [],
    tid: DATA_TID.NICOTINE_INPUT,
  }

  const birthDate = {
    component: (formProps: any, options: any): React.JSX.Element => {
      return (
        <NumberInput
          {...formProps}
          initialValue={age ? age.toString() : ''}
          name={options.name}
          placeholder={options.placeholder}
          labelCopy={birthdateQuestion}
          allCaps={false}
          capitalize={true}
          maxLength={3}
          classOverrides={'bg-GrayLightHover--opaque rounded-sm border-none'}
          labelColor={COLORS.GRAY_SECONDARY}
          labelWeight={'regular'}
        />
      )
    },
    tid: DATA_TID.BIRTHDATE_INPUT,
    validators: [
      validateExists,
      validateNumberRange(
        minimumEligibleAge,
        maximumEligibleAge,
        `Sorry, you must be between ${minimumEligibleAge}-${maximumEligibleAge}`
      ),
    ],
    validationSuccess: [sendAnalyticsEvent],

    options: {
      placeholder: '',
      name: 'birthdate-age',
    },
  }

  const zipCode = {
    component: (props): React.JSX.Element => {
      return (
        <ZipInput
          initialValue={initialValues.zipCode}
          {...props}
          allCaps={false}
          capitalize={true}
          labelWeight="regular"
          labelColor={COLORS.GRAY_SECONDARY}
          classOverrides={'bg-GrayLightHover--opaque rounded-sm border-none'}
        />
      )
    },
    validationSuccess: [sendAnalyticsEvent, fetchStateFromZipCallback],
    labelCopy: zipcodeQuestion,
    tid: DATA_TID.ZIPCODE_INPUT,
  }

  const fields = {
    gender,
    smoker,
    birthDate,
    zipCode,
  }

  if (displayEstimatedCredit) {
    const estimatedCredit = {
      component: (props): React.JSX.Element => (
        <EstimatedCredit
          estimatedCreditFootnote={estimatedCreditFootnote}
          variation={variation}
          {...props}
        />
      ),
      validators: [validateExists],
      validationSuccess: [sendAnalyticsEvent],
      labelCopy: estimatedCreditQuestion,
      options: estimatedCreditOptionsWithoutNumericValue,
      tid: DATA_TID.ESTIMATED_CREDIT_INPUT,
      name: 'estimate-credit-input',
    }

    fields['estimatedCredit'] = estimatedCredit
  }

  if (hideNicotineQuestion) {
    delete fields.smoker
  }

  return fields
}
