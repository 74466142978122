import { Product } from '../../../lib/@getethos/constants'
import { maxCoverageDetermination } from '../../../lib/@getethos/utils/maxCoverageDetermination'
import { COVERAGE_SLIDER, FINAL_EXPENSE_COVERAGE } from '../../constants'

export const isValidCoverage = (
  coverage: number,
  birthDate: string | undefined,
  product: Product
): boolean => {
  switch (product) {
    case Product.Term:
    case Product.GI:
    case Product.SI:
      return (
        coverage >= COVERAGE_SLIDER.MIN &&
        coverage <= maxCoverageDetermination(birthDate, product)
      )
    case Product.FE:
      return (
        coverage >= FINAL_EXPENSE_COVERAGE.MIN &&
        coverage <= maxCoverageDetermination(birthDate, product)
      )
  }
}

export const getMinValidCoverage = (product: Product): number => {
  switch (product) {
    case Product.Term:
    case Product.GI:
    case Product.SI:
      return COVERAGE_SLIDER.MIN
    case Product.FE:
      return FINAL_EXPENSE_COVERAGE.MIN
  }
}

export const coalesce = (...values: any[]): any => {
  for (const eachValue of values) {
    if (eachValue != null) {
      return eachValue
    }
  }

  return undefined
}

export const getMedianTerm = (terms: number[]) => {
  const maxTerm = terms[terms.length - 1]
  const minTerm = terms[0]

  switch (true) {
    case minTerm === 10 && maxTerm === 15:
      return 10
    case minTerm === 10 && maxTerm === 20:
      return 10
    case minTerm === 10 && maxTerm === 30:
      return 20
    default:
      return 10
  }
}
