import React from 'react'

import Link from 'next/link'

import { Body } from 'ethos-design-system'
import uniqBy from 'lodash.uniqby'

import { IconIntegratedTitle } from '../../helpers/words'
import { QuestionCtaArrow } from '../../icons'
import styles from './FaqQuestionsFeed.module.scss'
import SingleQuestion from './FaqSingleQuestionPreview'

interface FaqQuestionsFeedParam {
  questions: Array<any>
  fullWidth?: boolean
  classes?: {
    questionsClass?: string
    singleClass?: string
    questionClass?: string
    viewAllClass?: string
  }
  viewAllUrl?: string
}

/**
 * FaqQuestionsFeed is a list of links to individual questions, with preview snippets of the answers.
 *
 * @param {array} questions - the questions to display
 * @param {object} classes - optional CSS classes to pass from parent component
 * @param {string} viewAllUrl - Link URL for the FAQ category
 * @param {boolean} fullWidth - whether to make grid full width of container
 *
 * @return {JSX.Element}
 */
const FaqQuestionsFeed = ({
  questions,
  classes,
  viewAllUrl,
  fullWidth = false,
}: FaqQuestionsFeedParam) => {
  // Remove duplicates from questions array
  const questionsArray = uniqBy(questions, 'url')
  // Prepare classes
  const questionsClasses = [styles.Questions]
  if (classes && classes.questionsClass) {
    questionsClasses.push(classes.questionsClass)
  }
  return (
    <div className={questionsClasses.join(' ')}>
      <div className={styles.PhoneAndTablet}>
        {questionsArray.map((single, sidx) => (
          <SingleQuestion
            question={single}
            wrapperClass={
              classes && classes.singleClass ? classes.singleClass : undefined
            }
            questionClass={
              classes && classes.questionClass
                ? classes.questionClass
                : undefined
            }
            index={sidx}
            key={`singleQuestion${sidx}`}
          />
        ))}
        {classes && classes.viewAllClass && viewAllUrl && (
          <div className={classes.viewAllClass}>
            <Link href={viewAllUrl}>
              <Body.Medium500>
                <IconIntegratedTitle title="See all questions">
                  {QuestionCtaArrow}
                </IconIntegratedTitle>
              </Body.Medium500>
            </Link>
          </div>
        )}
      </div>
      <div
        className={`${styles.LaptopAndUp} ${fullWidth ? styles.fullWidth : ''}`}
      >
        {questionsArray.map((single: any, sidx: number) => (
          <SingleQuestion
            question={single}
            wrapperClass={
              classes && classes.singleClass ? classes.singleClass : undefined
            }
            questionClass={
              classes && classes.questionClass
                ? classes.questionClass
                : undefined
            }
            index={sidx}
            key={`singleQuestion${sidx}`}
          />
        ))}
      </div>
    </div>
  )
}

export default FaqQuestionsFeed
