/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import classnames from 'classnames'
import { CloudinaryImage } from 'ethos-design-system'

import styles from './CPSales.module.scss'

const trustItemsCopy = [
  {
    url: 'https://res.cloudinary.com/getethos/image/upload/v1720523825/Affordable_ydc5yh.svg',
    header: 'Get optimal coverage',
    subheader:
      'Our specialist will find you the best protection at an affordable rate.',
  },
  {
    url: 'https://res.cloudinary.com/getethos/image/upload/v1720701828/Time_stop_watch_lqluwl.svg',
    header: 'Get your final rate right away',
    subheader: 'No misleading quotes.',
  },
  {
    url: 'https://res.cloudinary.com/getethos/image/upload/v1720701811/Document_check_xempnh.svg',
    header: 'Free Basic Will & Trust',
    subheader:
      'Get estate planning valued at $898 for you and your spouse—absolutely free.',
  },
]

const CPSales = () => (
  <div className={classnames('w-screen [height:100dvh]', styles.background)}>
    <div className={styles.container}>
      <div className="w-full max-w-[550px] md:mx-auto md:w-[500px] md:max-w-[auto] md:text-[#272727]">
        <h2 className="pb-4 font-['Cambon'] text-[24px] font-medium leading-[32px] tracking-[0.24px] md:pb-6 md:text-[32px] md:leading-[42px] md:tracking-[-0.32px]">
          Thank you! A specialist will call you within 10 minutes.
        </h2>
        <p className="body-l-reg m-0 pb-9 md:pb-12">Why a phone call?</p>
        <ul className="m-0 flex list-none flex-col gap-y-4 p-0 md:gap-y-6">
          {trustItemsCopy.map((item, index) => (
            <TrustItem
              key={`dk-${index}`}
              iconUrl={item.url}
              header={item.header}
              subheader={item.subheader}
            />
          ))}
        </ul>
      </div>
    </div>
  </div>
)

const TrustItem = ({
  iconUrl,
  header,
  subheader,
}: {
  iconUrl: string
  header: string
  subheader: string
}) => (
  <li className="flex items-center gap-x-4 md:gap-x-8 ">
    <div className="min-w-[48px] max-w-[48px] md:max-h-[60px] md:min-h-[60px] md:min-w-[60px] md:max-w-[60px]">
      <CloudinaryImage
        publicId={iconUrl}
        alt="ethos logo"
        crop={CloudinaryImage.CROP_METHODS.FIT}
        height={[60, 60, 60, 60]}
        width={[60, 60, 60, 60]}
      />
    </div>
    <div className="flex flex-col gap-y-2">
      <p className="m-0 text-[16px] font-medium md:text-[18px]">{header}</p>
      <p className="m-0 text-[14px] tracking-[0.063px]">{subheader}</p>
    </div>
  </li>
)

export default CPSales
