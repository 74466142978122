import { CreditScore, FE_AGE_LIMIT, GI_AGE_LIMIT, Product } from '../constants'
import { ageNearestBirthDate } from './ageNearestBirthDate'
import { isWithinRange } from './utils'

interface PredictProductParams {
  birthDate: string | undefined
  creditScore: CreditScore | null
  region: string | null
}

const FINAL_EXPENSE_EXCLUSION_STATES = [
  'CA',
  'CT',
  'FL',
  'IA',
  'ME',
  'MT',
  'ND',
  'NH',
  'NY',
  'SD',
  'VT',
  'WA',
  'WI',
  'WY',
]

const AGE_RANGES = {
  1: { min: 18, max: 50 },
  2: { min: 51, max: 54 },
  3: { min: 55, max: 60 },
  4: { min: 61, max: 65 },
  5: { min: 66, max: 80 },
}

/**
 * Predicts what product a user is in based on their age and credit score
 * Note: N/A is when the user does not want to answer the question
 *  Age 	  High 	Average	Low 	NoAnswer  None
    18-50	  LGA	  LGA	    LGA	  LGA       LGA
    51-54	  LGA	  LGA	    SI	  LGA       LGA
    55-60	  LGA	  SI	    SI	  LGA       LGA
    61-65	  SI	  SI	    SI	  SI        SI
    66-80	  GI/FE	GI/FE	  GI/FE	GI/FE     FE/GI/Term
 */
export const predictProduct = ({
  birthDate,
  creditScore,
  region,
}: PredictProductParams): Product => {
  const age = !Number.isNaN(Number(birthDate))
    ? Number(birthDate)
    : ageNearestBirthDate({ birthDate })

  switch (creditScore) {
    case CreditScore.High:
      if (age && isWithinRange(age, AGE_RANGES[1].min, AGE_RANGES[3].max)) {
        return Product.Term
      }

      if (age && isWithinRange(age, AGE_RANGES[4].min, AGE_RANGES[4].max)) {
        return Product.SI
      }

      if (region && !FINAL_EXPENSE_EXCLUSION_STATES.includes(region)) {
        return Product.FE
      }
      return Product.GI
    case CreditScore.Average:
      if (age && isWithinRange(age, AGE_RANGES[1].min, AGE_RANGES[2].max)) {
        return Product.Term
      }

      if (age && isWithinRange(age, AGE_RANGES[3].min, AGE_RANGES[4].max)) {
        return Product.SI
      }

      if (region && !FINAL_EXPENSE_EXCLUSION_STATES.includes(region)) {
        return Product.FE
      }
      return Product.GI
    case CreditScore.Low:
      if (age && isWithinRange(age, AGE_RANGES[1].min, AGE_RANGES[1].max)) {
        return Product.Term
      }

      if (age && isWithinRange(age, AGE_RANGES[2].min, AGE_RANGES[4].max)) {
        return Product.SI
      }

      if (region && !FINAL_EXPENSE_EXCLUSION_STATES.includes(region)) {
        return Product.FE
      }
      return Product.GI
    case CreditScore.NoAnswer:
      // users up to 60
      if (age && isWithinRange(age, AGE_RANGES[1].min, AGE_RANGES[3].max)) {
        return Product.Term
      }

      if (age && isWithinRange(age, AGE_RANGES[4].min, AGE_RANGES[4].max)) {
        return Product.SI
      }

      if (region && !FINAL_EXPENSE_EXCLUSION_STATES.includes(region)) {
        return Product.FE
      }
      return Product.GI
    default:
      if (
        age &&
        region &&
        !FINAL_EXPENSE_EXCLUSION_STATES.includes(region) &&
        isWithinRange(age, FE_AGE_LIMIT.MIN, FE_AGE_LIMIT.MAX)
      ) {
        return Product.FE
      }

      if (age && isWithinRange(age, GI_AGE_LIMIT.MIN, GI_AGE_LIMIT.MAX)) {
        return Product.GI
      }

      if (age && isWithinRange(age, AGE_RANGES[4].min, AGE_RANGES[4].max)) {
        return Product.SI
      }

      return Product.Term
  }
}
