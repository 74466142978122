import {
  UPDATED_CP_FE_LP,
  UPDATED_CP_FE_LP_NON_WORKING_HOURS,
} from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

import useIsCentralTimeWeekdayBetween9And5 from '../useIsCentralTimeWeekdayBetween9And5'

export const useUpdatedCpFeLp = () => {
  const isCentralTimeWeekdayBetween9And5 = useIsCentralTimeWeekdayBetween9And5()

  const { isLoading, variation } = useDecisionForFlag({
    name: isCentralTimeWeekdayBetween9And5
      ? UPDATED_CP_FE_LP.EXPERIMENT_KEY
      : UPDATED_CP_FE_LP_NON_WORKING_HOURS.EXPERIMENT_KEY,
    fallbackVariation: UPDATED_CP_FE_LP.VARIATIONS.CONTROL,
  })

  return {
    isLoading,
    isTreatment1: variation === UPDATED_CP_FE_LP.VARIATIONS.TREATMENT_1,
    isTreatment2: variation === UPDATED_CP_FE_LP.VARIATIONS.TREATMENT_2,
    pageKeys: UPDATED_CP_FE_LP.PAGE_VALUES,
  }
}
