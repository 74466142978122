import removeMd from 'remove-markdown'

import { MAX_ANSWER_LENGTH } from '../constants'

const CONTENT_TYPES = {
  QUESTION: 'question',
  ANSWER: 'answer',
}

/**
 * trimFaqQuestionPreview cleans up question and answer lengths for FAQ single previews
 *
 * @param {content} string - The content to clean/trim.
 * @param {type} string - The type of content.
 *
 * @return {string}
 */
export const trimFaqQuestionPreview = (
  content: string,
  type: string
): string => {
  if (type === CONTENT_TYPES.QUESTION) {
    return content.trim()
  } else if (type === CONTENT_TYPES.ANSWER) {
    content = removeMd(content)
    if (content.length > MAX_ANSWER_LENGTH) {
      const trimmedAnswer = content.substring(0, MAX_ANSWER_LENGTH).trim()
      return `${trimmedAnswer}...`
    }
  }
  return content
}
