import dayjs from 'dayjs'
import utcPlugin from 'dayjs/plugin/utc'

dayjs.extend(utcPlugin)
/**
 * Given a birthdate, returns the rounded number of a person's age in years (e.g.
 * if they are 19 years and 7 months, the function will round to 20 years;
 * if they are 19 years and 5 months, will round to 19 years).
 * @see http://lifeinsuranceguide.net/insurance-glossary/age-nearest-birthday/
 *
 * Note, we use `utc` because referenceDate and birthDate can come through as
 * a date without time (e.g. 1990-07-18) and so we prefer to parse it as
 * a UTC date explicitly than let it be parsed in the machine's timezone which
 * could be arbitrary
 */
export const ageNearestBirthDate = ({
  birthDate,
  useFloatingPoint = true,
  referenceDate = dayjs(),
}) => {
  return birthDate
    ? Math.round(
        dayjs
          .utc(referenceDate)
          .diff(dayjs.utc(birthDate), 'years', useFloatingPoint)
      )
    : undefined
}
