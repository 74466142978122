import { TRUSTAGE_LANDING_PAGE } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useTrustageLandingPage = () => {
  const { isLoading, variation } = useDecisionForFlag({
    name: TRUSTAGE_LANDING_PAGE.EXPERIMENT_KEY,
    fallbackVariation: TRUSTAGE_LANDING_PAGE.VARIATIONS.CONTROL,
  })

  return {
    isLoading,
    isTreatment: variation === TRUSTAGE_LANDING_PAGE.VARIATIONS.TREATMENT,
  }
}
