//@ts-nocheck
import { useEffect } from 'react'

import { cmsModuleAnalytics } from './analyticsEvents'

/**
 * A custom hook that adds a scroll listener with the largest scroll
 * percent a user has taken
 */
export default function useTrackMaxScroll(): void {
  useEffect(() => {
    let ticking = false
    let maxScrollPercent = 0
    let currentScrollPixels = 0
    let prevScrollPercent = 0
    let currentScrollPercent = 0
    let pageScrollLimit
    let timeout
    const showed = {}
    const SCROLL_DEPTH_TRACKING_DELAY = 1000

    const scrollListener = (): void => {
      // window.requestAnimationFrame and ticking throttles
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
      if (!ticking) {
        window.requestAnimationFrame(() => {
          // Crossbrowser method of getting max possible scroll position.
          pageScrollLimit =
            Math.max(
              document.body.scrollHeight,
              document.body.offsetHeight,
              document.documentElement.clientHeight,
              document.documentElement.scrollHeight,
              document.documentElement.offsetHeight
            ) - window.innerHeight

          currentScrollPixels = window.scrollY
          currentScrollPercent = Math.floor(
            (currentScrollPixels / pageScrollLimit) * 100
          )

          maxScrollPercent = Math.max(maxScrollPercent, currentScrollPercent)
          ticking = false

          if (prevScrollPercent !== currentScrollPercent) {
            clearTimeout(timeout)
            timeout =
              currentScrollPercent !== 0 && !showed[currentScrollPercent]
                ? setTimeout(() => {
                    const wlp = window.location.pathname
                    const properties = {
                      pathName: wlp.endsWith('/') ? wlp : wlp.concat('/'),
                      maxScroll: maxScrollPercent,
                      currentScroll: currentScrollPercent,
                      currentScrollPixels,
                      pageScrollLimit,
                    }
                    cmsModuleAnalytics.trackScrollDepth({ properties })
                    showed[currentScrollPercent] = true
                  }, SCROLL_DEPTH_TRACKING_DELAY)
                : null
            prevScrollPercent = currentScrollPercent
          }
        })

        ticking = true
      }
    }
    window.addEventListener('scroll', scrollListener)
    return (): void => {
      window.removeEventListener('scroll', scrollListener)
      clearTimeout(timeout)
    }
  })
}
