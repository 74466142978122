import { ageNearestBirthDate } from './ageNearestBirthDate'
import { isWithinRange } from './utils'

/**
 * Returns a maximum term length for a given age
 *
 * [AGE] --> [TERM]
 * <=50  --> 30
 * 51-60 --> 20
 * 61-65 --> 15
 * 66-85 --> N/A
 *
 */

export const maxTermDetermination = (birthDate: string): number => {
  const age = !Number.isNaN(Number(birthDate))
    ? Number(birthDate)
    : ageNearestBirthDate({ birthDate })

  if (age && isWithinRange(age, 20, 50)) {
    return 30
  } else if (age && isWithinRange(age, 51, 60)) {
    return 20
  }

  return 15
}
