import { useRouter } from 'next/router'

const audiencesToIgnore = [
  '/life/quick-online-quote/',
  '/life/online-life-quote/',
]
/**
 * This hook is used to determine if the EW updates should be used.
 * @link https://ethoslife.atlassian.net/browse/GC-3908
 * @returns boolean
 * @example
 * const shouldUseEwUpdates = useEwUpdates()
 * if (shouldUseEwUpdates) {
 *  // do something
 * }
 */
export const useEwUpdates = () => {
  const { asPath } = useRouter()

  const pathWithoutQuery = asPath.split('?')[0]

  const shouldIgnore = !audiencesToIgnore.includes(pathWithoutQuery)
  return shouldIgnore
}
