import { maxTermDetermination } from '../../../lib/@getethos/utils/maxTermDetermination'
import { termRecommendation } from '../../../lib/@getethos/utils/termRecommendation'

interface HookReturn {
  terms: number[]
  recommendedTerm: number
}
const defaultTerm = 10
const defaultTerms = [10, 15, 20, 30]

export const useEligibility = (birthDate?: string): HookReturn => {
  if (!birthDate) {
    return {
      terms: defaultTerms,
      recommendedTerm: defaultTerm,
    }
  }
  const maxTerm = maxTermDetermination(birthDate)
  const newTerms = defaultTerms.filter((term) => term <= maxTerm)
  return {
    terms: newTerms,
    recommendedTerm: termRecommendation(birthDate),
  }
}
