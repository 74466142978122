import classNames from 'classnames'

interface CheckboxIconProps {
  isChecked: boolean
  size: 'sm' | 'md'
  isDisabled?: boolean
}

const CheckboxIcon = ({
  isChecked,
  size,
  isDisabled = false,
}: CheckboxIconProps) => {
  const isMedium = size === 'md'
  const boxSize = isMedium ? 20 : 16
  const squareSize = isMedium ? 18 : 14
  const checkedSquareSize = isMedium ? 12 : 8
  const viewBow = isMedium ? '0 0 20 20' : '0 0 16 16'
  const checkboxStyle = isDisabled
    ? 'eds2-stroke-[--theme-fg-subtle-4x]'
    : isChecked
    ? 'eds2-stroke-[--theme-accent-default]'
    : 'eds2-stroke-[--theme-input-border-default]'

  const checkmarkStyle = isDisabled
    ? 'eds2-fill-[--theme-fg-subtle-4x]'
    : 'fill-[--theme-accent-default]'

  const backGroundStyle = classNames('focus:bg-[#DAE7E6]')

  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox={viewBow}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width={squareSize}
        height={squareSize}
        rx="3"
        className={backGroundStyle}
      />
      {isChecked && (
        <rect
          x="4"
          y="4"
          width={checkedSquareSize}
          height={checkedSquareSize}
          rx={isMedium ? 2 : 1}
          className={checkmarkStyle}
        />
      )}
      <rect
        x="1"
        y="1"
        width={squareSize}
        height={squareSize}
        rx="3"
        strokeWidth="2"
        className={checkboxStyle}
      />
    </svg>
  )
}

export default CheckboxIcon
