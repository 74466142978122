import { object, string } from 'yup'

const ageIntegerValidator =
  (minAge = 1, maxAge: number = 99) =>
  (input: string | number): string => {
    const num = Number(`${input}`.replace(/[^\d]/g, ''))

    if (!(num >= minAge && num <= maxAge)) {
      return `Age must be between ${minAge} and ${maxAge}`
    }

    return ''
  }

export const validationSchema = object({
  gender: string().required('Please select your gender'),
  birthDate: string()
    .required('Please provide an age')
    .test('custom-min-max', (value, { createError }) => {
      const ageError = ageIntegerValidator(20, 85)(value)
      return !ageError || createError({ message: ageError })
    }),
})

export const stateValidationSchema = object({
  region: string().required('Please select a state'),
})

export const mooValidationSchema = object({
  gender: string().required('Please select your gender'),
  birthDate: string()
    .required('Please provide an age')
    .test('custom-min-max', (value, { createError }) => {
      const ageError = ageIntegerValidator(20, 85)(value)
      return !ageError || createError({ message: ageError })
    }),
  region: string().required('Please select a state'),
})

export const mooValidationSchemaWPhone = object({
  firstName: string().required('Please provide first name'),
  birthDate: string()
    .required('Please provide an age')
    .test('custom-min-max', (value, { createError }) => {
      const ageError = ageIntegerValidator(20, 85)(value)
      return !ageError || createError({ message: ageError })
    }),
  region: string().required('Please select a state'),
  phone: string().required('Please provide a phone number'),
})
