import { WILLS_RISK_FREE_VS_V3_1_T1 } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

const features = {
  control: 'https://app.tomorrow.me/documents/will/intro',
  curatedFluffFlow: 'wills',
  minimalFlow: 'wills-minimal',
}

export const THIRTY_DAY_TRIAL_FLOW = 'wills-30-day-trial'
export const WILLS_ONBOARDING_FLOW = 'wills-onboarding'
export const WILLS_ONBOARDING_TREATMENT_2_FLOW = 'wills-onboarding-t2'
export const WILLS_RISK_FREE_TRIAL = 'wills-risk-free-trial'
export const WILLS_RISK_FREE_TRIAL_T2 = 'wills-risk-free-trial-t2'

// TODO: these can be removed for baselining
export const WILLS_PAINTED_DOOR_V2_SEPARATE_FLOW = 'wills-pdv2-separate'
export const TRUSTS_PAINTED_DOOR_V2_SEPARATE_FLOW = 'trusts-pdv2-separate'
export const WILLS_PAINTED_DOOR_V2_2_1_TREATMENT_1 = 'wills-pdv2-2-1-t1'

export const useWillsFunnelFlow = () => {
  const { variation, isLoading } = useDecisionForFlag({
    name: WILLS_RISK_FREE_VS_V3_1_T1.EXPERIMENT_KEY,
    fallbackVariation: WILLS_RISK_FREE_VS_V3_1_T1.VARIATIONS.CONTROL,
  })

  let flow
  let isTreatment = false

  switch (variation) {
    case WILLS_RISK_FREE_VS_V3_1_T1.VARIATIONS.CONTROL:
      flow = WILLS_ONBOARDING_FLOW
      isTreatment = true
      break
    case WILLS_RISK_FREE_VS_V3_1_T1.VARIATIONS.TREATMENT_1:
      flow = WILLS_RISK_FREE_TRIAL
      isTreatment = true
      break
    case WILLS_RISK_FREE_VS_V3_1_T1.VARIATIONS.TREATMENT_2:
      flow = WILLS_RISK_FREE_TRIAL_T2
      isTreatment = true
      break
    default:
      flow = undefined
  }

  return {
    isLoading,
    isTreatment,
    isLeadForm: false,
    flow,
  }
}
