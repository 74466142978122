import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react'

interface IDirectToAppContext {
  progress: number
  handleProgress: (progress: number) => void
}

const DirectToAppContext = createContext<IDirectToAppContext>({
  progress: 0,
  handleProgress: () => {},
})

export const useDirectToAppContext = () => useContext(DirectToAppContext)

export const DirectToAppProvider: FC<PropsWithChildren> = ({ children }) => {
  const [progress, setProgress] = useState(1)

  const handleProgress = (progress: number) => {
    setProgress(progress)
  }

  return (
    <DirectToAppContext.Provider value={{ progress, handleProgress }}>
      {children}
    </DirectToAppContext.Provider>
  )
}
