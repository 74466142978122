import { FC, PropsWithChildren } from 'react'

import { useLegalBannerContext } from '@/contexts/LegalBannerContext'
import { useModuleContext } from '@/contexts/ModuleContext'

export const MainWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { isDisclaimerOpen, disclaimerHeight } = useLegalBannerContext()
  const {
    footerControls: { isFooterHidden },
  } = useModuleContext()

  const getPaddingBottom = () => {
    if (!isDisclaimerOpen || !isFooterHidden) return ''
    return disclaimerHeight
  }
  const paddingBottom = getPaddingBottom()

  return (
    <main style={{ paddingBottom: `calc(${paddingBottom}px + 24px)` }}>
      {children}
    </main>
  )
}
