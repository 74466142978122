import React from 'react'

import {
  ButtonSelectGroup,
  CheckboxInput,
  NumberInput,
  OPTION_BUTTON_STYLES,
  ZipInput,
} from 'ethos-design-system'
import validateNumberRange from 'ethos-design-system/src/validators/NumberInputValidator'
import validateExists from 'ethos-design-system/src/validators/validateExists.js'

import { DATA_TID } from '@/components/EstimateWidget/common/data-tid'

import { EstimateWidgetVariations } from '../../components/EstimateWidget/common/variations'
import {
  EstimatedCredit,
  EstimatedCreditProps,
  estimatedCreditOptions,
} from './EstimatedCredit'

interface Props {
  minimumEligibleAge: number
  maximumEligibleAge: number
  genderQuestion?: string
  nicotineQuestion?: string
  healthQuestion?: string
  zipcodeQuestion?: string
  displayEstimatedCredit: boolean
  estimatedCreditQuestion?: string
  estimatedCreditFootnote?: string
  ageLabel?: string
  sendAnalyticsEvent: () => void
  fetchStateFromZipCallback?: () => void
  dataTid: typeof DATA_TID
  userData: {
    gender?: string
    smoker?: string
    health?: string
    zipCode?: string
    birthDate?: string
  }
  useLean?: boolean
}

/**
 * Function that returns an EDS component for a button select group. The
 * EDS form expects a functional component.
 */
const getButtonSelectGroupFactory =
  (initialValue: string) =>
  (
    // ButtonSelectGroup using flexible props type that is hard to type without breaking anything
    props: Record<string, any>,
    options: Array<{ value: string; copy: string }>
  ) => {
    const [hasMounted, setHasMounted] = React.useState(false)
    // prefill button on url params requires delayed second render
    // because of gatsby production hydration
    // https://www.joshwcomeau.com/react/the-perils-of-rehydration/
    React.useEffect(() => {
      setHasMounted(true)
      DATA_TID
    }, [])

    if (hasMounted) {
      props['currentValue'] = initialValue
    }

    return (
      <ButtonSelectGroup
        buttonStyle={OPTION_BUTTON_STYLES.WHITE}
        {...{ ...props, labelCopy: props.labelCopy }}
      >
        {options.map((x: { value: string; copy: string }) => (
          <ButtonSelectGroup.Option
            value={x.value}
            key={`buttonSelectGroup${x.value}`}
          >
            {x.copy}
          </ButtonSelectGroup.Option>
        ))}
      </ButtonSelectGroup>
    )
  }

export const getFieldsQuote = ({
  minimumEligibleAge,
  maximumEligibleAge,
  genderQuestion = '',
  nicotineQuestion = '',
  healthQuestion = '',
  zipcodeQuestion = '',
  displayEstimatedCredit,
  estimatedCreditQuestion = '',
  estimatedCreditFootnote = '',
  ageLabel = 'AGE',
  sendAnalyticsEvent,
  fetchStateFromZipCallback = () => {},
  dataTid: DATA_TID,
  userData,
  useLean,
}: Props) => {
  const gender = {
    component: getButtonSelectGroupFactory(userData?.gender || ''),
    validators: [validateExists],
    validationSuccess: [sendAnalyticsEvent],
    labelCopy: genderQuestion,
    options: [
      { value: 'Female', copy: 'Female' },
      { value: 'Male', copy: 'Male' },
    ],
    tid: DATA_TID.GENDER_INPUT,
  }

  const smokerLeanQuestion = {
    // CheckboxInput using flexible props type that is hard to type without breaking anything
    component: (formProps: Record<string, any>) => {
      return (
        <div className={'w-full'}>
          <CheckboxInput
            initialValue={userData?.smoker === 'true'}
            useWhiteBackground
            {...{
              ...formProps,
              name: formProps.name,
              'data-tid': formProps['data-tid'],
            }}
          >
            {nicotineQuestion}
          </CheckboxInput>
        </div>
      )
    },
    optional: true,
    validators: [],
    tid: DATA_TID.NICOTINE_INPUT,
  }

  const smokerQuestion = {
    component: getButtonSelectGroupFactory(userData?.smoker || ''),
    validators: [validateExists],
    validationSuccess: [sendAnalyticsEvent],
    labelCopy: nicotineQuestion,
    options: [
      { value: 'true', copy: 'Yes' },
      { value: 'false', copy: 'No' },
    ],
    tid: DATA_TID.NICOTINE_INPUT,
  }

  const smoker = useLean ? smokerLeanQuestion : smokerQuestion

  const health = {
    component: getButtonSelectGroupFactory(userData?.health || ''),
    validators: [validateExists],
    validationSuccess: [sendAnalyticsEvent],
    labelCopy: healthQuestion,
    options: [
      { copy: 'Average', value: '1' },
      { copy: 'Great', value: '2' },
      { copy: 'Excellent', value: '3' },
    ],
    tid: DATA_TID.HEALTH_INPUT,
  }

  const ageQuestion = {
    component: (
      // NumberInput using flexible props type that is hard to type without breaking anything
      formProps: Record<string, any>,
      options: { placeholder: string; name: string }
    ) => {
      return (
        <NumberInput
          {...formProps}
          initialValue={userData?.birthDate?.toString() || ''}
          name={options.name}
          placeholder={options.placeholder}
          labelCopy={ageLabel}
          allCaps={false}
          capitalize={true}
          maxLength={3}
        />
      )
    },
    tid: DATA_TID.BIRTHDATE_INPUT,
    validators: [
      validateExists,
      validateNumberRange(
        minimumEligibleAge,
        maximumEligibleAge,
        `Sorry, you must be between ${minimumEligibleAge}-${maximumEligibleAge}`
      ),
    ],
    validationSuccess: [sendAnalyticsEvent],
    options: {
      placeholder: '',
      name: 'birthdate-age',
    },
  }

  const birthDate = ageQuestion

  const zipCode = {
    // ZipInput using flexible props type that is hard to type without breaking anything
    component: (props: Record<string, any>) => {
      return <ZipInput initialValue={userData?.zipCode || ''} {...props} />
    },
    validationSuccess: [sendAnalyticsEvent, fetchStateFromZipCallback],
    labelCopy: zipcodeQuestion,
    tid: DATA_TID.ZIPCODE_INPUT,
  }

  // fields is a Record<string, any> because it is conditionally changes
  const fields: Record<string, any> = {
    gender,
    smoker,
    birthDate,
    zipCode,
  }

  if (displayEstimatedCredit) {
    const estimatedCredit = {
      component: (props: Partial<EstimatedCreditProps>) => (
        <EstimatedCredit
          estimatedCreditFootnote={estimatedCreditFootnote}
          heroBoxed={true}
          variation={
            useLean ? EstimateWidgetVariations.FLOATING_LEAN : undefined
          }
          {...props}
        />
      ),
      validators: [validateExists],
      validationSuccess: [sendAnalyticsEvent],
      labelCopy: estimatedCreditQuestion,
      options: estimatedCreditOptions,
      tid: DATA_TID.ESTIMATED_CREDIT_INPUT,
      name: 'estimate-credit-input',
    }

    fields.estimatedCredit = estimatedCredit
  }

  if (!useLean) {
    fields.health = health
  }

  return fields
}
