/* tslint:disable */
/* eslint-disable */
/**
 * Ethos Partners API Reference
 * Documentation of Ethos Partners API.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from './PartnerData'

/**
 *
 * @export
 * @interface LeadInfo
 */
export interface LeadInfo {
  /**
   *
   * @type {string}
   * @memberof LeadInfo
   */
  utmContent?: string
  /**
   *
   * @type {string}
   * @memberof LeadInfo
   */
  utmTerm?: string
  /**
   *
   * @type {string}
   * @memberof LeadInfo
   */
  utmSource?: string
  /**
   *
   * @type {string}
   * @memberof LeadInfo
   */
  utmName?: string
  /**
   *
   * @type {string}
   * @memberof LeadInfo
   */
  utmMedium?: string
  /**
   *
   * @type {string}
   * @memberof LeadInfo
   */
  leadUrl?: string
}

/**
 * Check if a given object implements the LeadInfo interface.
 */
export function instanceOfLeadInfo(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function LeadInfoFromJSON(json: any): LeadInfo {
  return LeadInfoFromJSONTyped(json, false)
}

export function LeadInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LeadInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    utmContent: !exists(json, 'utm_content') ? undefined : json['utm_content'],
    utmTerm: !exists(json, 'utm_term') ? undefined : json['utm_term'],
    utmSource: !exists(json, 'utm_source') ? undefined : json['utm_source'],
    utmName: !exists(json, 'utm_name') ? undefined : json['utm_name'],
    utmMedium: !exists(json, 'utm_medium') ? undefined : json['utm_medium'],
    leadUrl: !exists(json, 'lead_url') ? undefined : json['lead_url'],
  }
}

export function LeadInfoToJSON(value?: LeadInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    utm_content: value.utmContent,
    utm_term: value.utmTerm,
    utm_source: value.utmSource,
    utm_name: value.utmName,
    utm_medium: value.utmMedium,
    lead_url: value.leadUrl,
  }
}
