import { useEffect } from 'react'

/**
 * A function that makes all external links
 * open in a new tab
 */
export function AgentsIFrameScript() {
  useEffect(() => {
    const anchors = document.querySelectorAll('a')

    // make external links open in new window
    for (const anchor of anchors) {
      if (!anchor.href.includes('/agents/')) {
        anchor.target = '_blank'
      }
    }
    return
  })
}
