/**
 * From monorepo. https://github.com/getethos/ethos/blob/trunk/frontend/shared/lib/ErrorMonitor/ErrorMonitor.js#L44-L60
 */

type NotFoundError = {
  url: string

  [key: string]: string
}

class ErrorMonitor {
  reportError({
    errorObject,
    errorInfoObject,
  }: {
    errorObject: Error
    errorInfoObject: NotFoundError
  }): void {
    console.warn(errorObject, errorInfoObject)
    // TODO: Add datadog
    // Sentry.withScope((scope) => {
    //   Object.keys(errorInfoObject).forEach((key) => {
    //     scope.setExtra(key, errorInfoObject[key])
    //   })

    //   Sentry.captureException(errorObject)
    // })
  }
}

export const errorMonitor = new ErrorMonitor()
