import React from 'react'

import Head from 'next/head'

import { CloudinaryImage, preloadImageData } from 'ethos-design-system'

/**
 * A component that should be considered for use exclusively by LCP images
 * This image will not load lazily, will let the browser know it should be
 * preloaded, as well as be fetched with the "highest" priority
 */

export default function PriorityImage({
  width,
  height,
  crop,
  publicId,
  alt,
  className,
  fetchpriority = 'high',
}: {
  width: number[]
  height: number[]
  crop: string
  publicId: string
  alt: string
  className?: string
  fetchpriority?: 'high' | 'low' | 'auto'
}): JSX.Element {
  return (
    <>
      <Head>
        {preloadImageData({
          width,
          height,
          publicId,
          crop,
        }).map(({ key, ...rest }) => {
          return <link {...rest} key={key} />
        })}
      </Head>
      <CloudinaryImage
        className={className}
        alt={alt}
        width={width}
        height={height}
        lazyLoad={false}
        crop={crop}
        publicId={publicId}
        fetchpriority={fetchpriority}
      />
    </>
  )
}
