import { useEffect, useRef, useState } from 'react'

import { NAVBAR_HEIGHT } from '../../../src/constants'

/**
 * Helper function to add polyfill values to the Intersection Observer
 * @see {https://github.com/w3c/IntersectionObserver/tree/master/polyfill}
 * @see {https://github.com/getethos/cms/blob/master/polyfill.js}
 * @param {{ disconnect: function }} observer
 */
function addObserverPolyfill(observer) {
  observer.POLL_INTERVAL = 100
  observer.disconnect()
}

/**
 * Hook which returns whether the user is intersecting with the refNode
 * @see {https://medium.com/the-non-traditional-developer/how-to-use-an-intersectionobserver-in-a-react-hook-9fb061ac6cb5}
 * @see {https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API}
 *
 * @param {number} navbarHeight
 */
export function useIntersectionObserver(navbarHeight = NAVBAR_HEIGHT) {
  const [refNode, updateRefNode] = useState(null)
  const [isIntersecting, setIsIntersecting] = useState(false)

  const observer = useRef(
    (() => {
      if (typeof window !== 'undefined') {
        return new window.IntersectionObserver(
          ([entry]) => {
            setIsIntersecting(entry.isIntersecting)
          },
          {
            rootMargin: `-${navbarHeight}px 0px 0px 0px`,
          }
        )
      }
      return null
    })()
  )

  useEffect(() => {
    const { current: currentObserver } = observer

    addObserverPolyfill(currentObserver)

    if (refNode) currentObserver.observe(refNode)

    return () => {
      currentObserver.disconnect()
    }
  }, [refNode])

  return [isIntersecting, refNode, updateRefNode]
}
