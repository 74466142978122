import React from 'react'

import { Caption2 } from 'ethos-design-system'

import styles from './Floating.module.scss'

interface DisclaimerProps {
  color?: string
  children: React.ReactNode
}

export const Disclaimer = (props: DisclaimerProps) => {
  const disclaimerStyles = [styles.disclaimer, 'max-w-sm']
  if (props.color === 'darkGrey') {
    disclaimerStyles.push(styles.darkGrey)
  }
  return (
    <div className={disclaimerStyles.join(' ')}>
      <Caption2.Regular400>{props.children}</Caption2.Regular400>
    </div>
  )
}
