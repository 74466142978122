//@ts-nocheck
// Based on https://github.com/kenny-hibino/react-places-autocomplete#utility-functions

import { INVALID_STATES, VALID_STATES } from '@/constants'

import useScript from '@/hooks/useScript'

/**
 * getStateFromGoogleApi - get a state based on zipcode, using Google Maps API
 *
 * @param {string} zipCode
 * @param {Object} [analytics] - contains analytics events
 *
 * @return {string} state abbreviation, 'AL' if Google Maps API lookup fails
 */
export const getStateFromGoogleApi = async (zipCode, analytics) => {
  let stateFromZip

  try {
    stateFromZip = (await geocodeByAddressAndParse(zipCode)).region

    // Fallback to the first 'eligible' state from LGA if the API
    // returns an invalid abbreviation that is not NY (the only invalid state)
    if (
      !VALID_STATES.includes(stateFromZip) &&
      !INVALID_STATES.includes(stateFromZip)
    ) {
      stateFromZip = 'AL'
    }

    if (analytics) {
      analytics.zipCodeCheck({
        properties: {
          zipCodeValid: true,
          zipCode,
          state: stateFromZip,
        },
      })
    }
  } catch (e) {
    console.warn(e)

    // Fallback to the first 'eligible' state from LGA
    stateFromZip = 'AL'

    if (analytics) {
      analytics.zipCodeCheck({
        properties: {
          zipCodeValid: false,
          zipCode,
          error: e,
        },
      })
    }
  }

  if (analytics) {
    analytics.regionSelected({
      properties: { state: stateFromZip },
    })
  }

  return stateFromZip
}

export const geocodeByAddress = (address) => {
  if (typeof window === 'undefined') return
  // Pass Zipcode into Google Maps API and return an object
  const geocoder = new window.google.maps.Geocoder()
  const OK = window.google.maps.GeocoderStatus.OK

  return new Promise((resolve, reject) => {
    // Add 5 second Google Maps API timeout
    setTimeout(() => {
      reject('Google Maps Timeout')
    }, 5000)

    geocoder.geocode({ address }, (results, status) => {
      if (status !== OK) {
        reject(status)
      }
      resolve(results)
    })
  })
}

export const parseState = (result) => {
  const { address_components } = result
  const desired = {
    // administrative_area_level_1 is state from the Google Maps API call
    administrative_area_level_1: 'short_name',
  }
  const parsed = {}
  for (let i = 0, ii = address_components.length; i < ii; i++) {
    const addressType = address_components[i].types[0]
    if (desired[addressType]) {
      parsed[addressType] = address_components[i][desired[addressType]]
    }
  }

  const formatted = {}

  if (parsed.administrative_area_level_1) {
    formatted.region = parsed.administrative_area_level_1
  }

  return formatted
}

export const geocodeByAddressAndParse = (zipCode) =>
  geocodeByAddress(zipCode).then((results) => parseState(results[0]))

// This code must be run in order for the Google Maps API script to be present on the webpage
export const useGoogleMaps = () => {
  const googleMapsApiKey = 'AIzaSyCgCzoqKDD74yhKGEDBYXkCbXgSYSmW-CI'
  return useScript(
    `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`
  )
}
