import React from 'react'

import { Caption2, CloudinaryImage, Layout } from 'ethos-design-system'
import PropTypes from 'prop-types'

import { ESTIMATE_WIDGET_AGES } from '../../../constants'
import PriorityImage from '../../global/PriorityImage'
import styles from '../EstimateWidget.module.scss'
import { EstimateWidgetVariations } from '../common/variations'
import { HeroBoxedFormManager } from './HeroBoxedFormManager'

export const HeroBoxedWidget = ({ moduleData }: any) => {
  const {
    variation,
    groupImage,
    hideMediaMobile,
    imageAlt,
    disclaimerText,
    backgroundColor,
  } = moduleData

  const isHero = variation === EstimateWidgetVariations.HERO && groupImage
  const imageStyles = [styles.image]
  if (hideMediaMobile) {
    imageStyles.push(styles.hideMediaMobile)
  }

  return (
    <div className={[styles.container, styles[variation]].join(' ')}>
      {isHero && (
        <div className={imageStyles.join(' ')}>
          <figure>
            <PriorityImage
              publicId={groupImage}
              alt={imageAlt}
              crop={CloudinaryImage.CROP_METHODS.FILL}
              width={[375, 800, 372, 560]}
              height={[216, 216, 700, 800]}
              className={''}
            />
          </figure>
        </div>
      )}

      <Layout.HorizontallyPaddedContainer
        className={[styles.inner, styles[backgroundColor]].join(' ')}
      >
        <div
          id="ethos-estimate-widget"
          className={[styles.widget, styles[variation]].join(' ')}
        >
          <HeroBoxedFormManager
            moduleData={{
              variation,
              heading: moduleData.heading,
              heading2: moduleData.heading2,
              enableH1: moduleData.enableH1,
              headingSize: moduleData.headingSize,
              subHeading: moduleData.subHeading,
              subHeading2: moduleData.subHeading2,
              displaySubHeading: moduleData.displaySubHeading,
              displaySubHeading2: moduleData.displaySubHeading2,
              continueLabel: moduleData.continueLabel,
              firstButton: moduleData?.firstButton
                ? moduleData?.firstButton
                : 'Continue',
              saveLabel: moduleData.saveLabel,
              disclaimerText: moduleData.disclaimerText,
              genderLabel: moduleData.genderLabel,
              birthLabel: moduleData.birthLabel,
              nicotineLabel: moduleData.nicotineLabel,
              healthLabel: moduleData.healthLabel,
              zipLabel: moduleData.zipLabel,
              displayEstimatedCredit: moduleData.displayEstimatedCredit,
              estimatedCreditLabel: moduleData.estimatedCreditLabel,
              estimatedCreditFootnote: moduleData.estimatedCreditFootnote,
              coverageLabel: moduleData.coverageLabel,
              termLabel: moduleData.termLabel,
              emailHeading: moduleData.emailHeading,
              emailSubHeading: moduleData.emailSubHeading,
              emailSendLabel: moduleData.emailSendLabel,
              backgroundColor: isHero ? 'white' : backgroundColor,
              useFinalExpensePriceRange: moduleData.useFinalExpensePriceRange,
            }}
            minimumEligibleAge={ESTIMATE_WIDGET_AGES.MIN}
            maximumEligibleAge={ESTIMATE_WIDGET_AGES.MAX}
          />
        </div>
      </Layout.HorizontallyPaddedContainer>

      {disclaimerText.length > 0 && (
        <div className={[styles[backgroundColor]].join(' ')}>
          <Layout.HorizontallyPaddedContainer>
            <div className={styles.disclaimer}>
              <Caption2.Regular400>{disclaimerText}</Caption2.Regular400>
            </div>
          </Layout.HorizontallyPaddedContainer>
        </div>
      )}
    </div>
  )
}

HeroBoxedWidget.propTypes = {
  /** moduleData defined in parent: EstimateWidget */
  moduleData: PropTypes.object,
}
