import { FC } from 'react'

import { Button, Dropdown } from '@getethos/ethos-design-system-v2'
import { yupResolver } from '@hookform/resolvers/yup'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { Controller, FieldErrors, useForm } from 'react-hook-form'

import { useEstimateQuotesContext } from '@/components/EstimateWidget/contexts/EstimateQuotesContext'

import { useUpdatedCpFeLp } from '@/hooks/features/useUpdatedCpFeLp'

import { STATE_OPTIONS } from '../utils/constants'
import { stateValidationSchema } from '../utils/validations'
import styles from './form.module.scss'

type FormProps = {
  region: string
}

interface FinalExpenseStateFormProps {
  onSubmit: (formValues: FormProps) => void
  handleOnBlur?: (field: string) => void
  defaultValues?: Partial<FormProps>
}

export const FinalExpenseStateForm: FC<FinalExpenseStateFormProps> = ({
  onSubmit,
  handleOnBlur,
  defaultValues,
}) => {
  const { isTreatment1, isTreatment2 } = useUpdatedCpFeLp()

  const { control, handleSubmit, getValues } = useForm<FormProps>({
    defaultValues,
    resolver: yupResolver(stateValidationSchema),
    mode: 'onSubmit',
  })

  const { userSelectedValues } = useEstimateQuotesContext()

  const onInvalidSubmit = (errors: FieldErrors<FormProps>) => {
    const currentValues = getValues()
    estimateWidgetAnalytics.continueToPricesError({
      properties: {
        triedToEditAnswers: !!userSelectedValues,
        errorMessages: errors,
        currentValues,
        formType: 'State',
      },
    })
  }

  const ctaLabel =
    isTreatment1 || isTreatment2 ? 'GET STARTED' : 'GET AN ESTIMATE'

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <div className={styles.dropdownWrapper}>
          <Controller
            control={control}
            name="region"
            render={({ field, fieldState: { error } }) => (
              <Dropdown
                {...field}
                label="State"
                placeholder="Select"
                options={STATE_OPTIONS}
                errorMessage={error?.message}
                onBlur={() => handleOnBlur?.('state')}
                onChange={(option) => field.onChange(option?.value)}
                value={STATE_OPTIONS.find(
                  (option) => option.value === field?.value
                )}
                defaultValue={STATE_OPTIONS.find(
                  (option) => option.value === defaultValues?.region
                )}
              />
            )}
          />
          <span className={styles.inputHelperText}>
            Laws and regulation vary by state
          </span>
        </div>
        <Button
          className={styles.button}
          buttonTitle={ctaLabel}
          variant="salamander"
          alignment="center"
          type="submit"
          size="lg"
        />
      </form>
    </div>
  )
}
