import styles from './Overview.module.scss'

interface OverivewProp {
  onTermsOpen: () => void
}

const OVERVIEW = [
  '#1 online life insurance provider',
  'No medical exams. No fees.',
  '*99% approval',
  'Ages 20 to 85 in most states',
  'Lifetime coverage with locked in price',
  '30 day money-back guarantee',
  'Insure your family’s financial future',
]

const Overview = ({ onTermsOpen }: OverivewProp) => (
  <div className={styles.overviewWrapper}>
    <span className="body-l-bold text-[--primary-cypress-100]">
      Plan overview
    </span>
    <div className="flex flex-col justify-center gap-4 custom-md:gap-6 ">
      {OVERVIEW.map((text, index) => (
        <OverviewItem key={index} text={text} />
      ))}
    </div>
    <span className={styles.terms} onClick={onTermsOpen}>
      Product terms
    </span>
  </div>
)

const OverviewItem = ({ text }: { text: string }) => (
  <div className="flex items-center gap-2">
    <Checkmark className="min-w-[24px] self-start" />
    <span className={styles.overviewText}>{text}</span>
  </div>
)

const Checkmark = ({ className = '' }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    className={className}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8.79995 15.9L4.59995 11.7L3.19995 13.1L8.79995 18.7L20.8 6.70005L19.4 5.30005L8.79995 15.9Z"
      fill="#525252"
    />
  </svg>
)

export default Overview
