import React from 'react'

import { COLORS, CloudinaryImage } from 'ethos-design-system'

import { Subheading } from '../../common/Subheading'
import styles from './Fullbleed.module.scss'
import { H1Responsive } from './H1Responsive'
import { colorCombos } from './colorCombos'
import { LayoutProps } from './index'

const FullbleedImage = ({
  updateScrollNode,
  moduleData,
  formManager,
}: LayoutProps): JSX.Element => {
  const { bgColor, h1Color } = colorCombos.forest
  const h2Color = COLORS.WHITE

  const section = `floatingContainer relative w-full ${bgColor} ${styles.fullBleed}`
  const backgroundImageWrapper =
    'z-0 hidden md:block absolute top-0 bottom-0 left-0 right-0 overflow-hidden'
  const backgroundImage = styles.backgroundImage

  const heightArray = [0, 384, 729, 972]
  const widthArray = [0, 768, 1440, 1920]

  const container =
    'z-1 w-full max-w-screen-lg mx-auto px-3 pt-8 md:py-8 md:px-12 lg:p-0 flex flex-wrap md:flex-nowrap justify-center md:justify-between'

  const headings = 'z-1 md:mt-16 lg:mr-16 lg:pb-6 text-center md:text-left'
  const h1 = 'max-w-md md:max-w-lg-plus lg:pb-6'
  const h2 = 'max-w-xs md:w-72 lg:max-w-sm lg:w-96 mx-auto md:mx-0'

  const form = 'z-1 w-full max-w-sm mx-auto md:mx-0 md:ml-4 lg:ml-0 md:mt-14'

  return (
    <section className={section} ref={updateScrollNode}>
      <div className={container}>
        <div className={headings}>
          <H1Responsive
            className={h1}
            color={h1Color}
            copy={moduleData.heading}
          />

          <Subheading
            className={h2}
            color={h2Color}
            displaySubHeading={moduleData.displaySubHeading}
          >
            {moduleData.subHeading}
          </Subheading>
        </div>

        {formManager && <div className={form}>{formManager}</div>}
      </div>

      {moduleData.groupImage && moduleData.imageAlt && (
        <div className={backgroundImageWrapper}>
          <CloudinaryImage
            publicId={moduleData.groupImage}
            alt={moduleData.imageAlt}
            className={backgroundImage}
            crop={CloudinaryImage.CROP_METHODS.FILL}
            height={heightArray}
            width={widthArray}
          />
        </div>
      )}
    </section>
  )
}

export default FullbleedImage
