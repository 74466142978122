//@ts-nocheck

import React, { ReactNode } from 'react'

import { Form } from 'ethos-design-system'

import { estimateWidgetAnalytics } from '../../../../lib/@getethos/analytics/analyticsEvents'
import { Subheading } from '../common/Subheading'
import { EstimateWidgetVariations } from '../common/variations'
import { Heading } from './Heading'
import styles from './HeroBoxed.module.scss'
import { UserInfoFields } from './UserInfoFields'

export const UserInfoForm = ({
  heading,
  enableH1,
  headingSize,
  displaySubHeading,
  handleSubmit,
  subHeading,
  disclaimerText,
  scrollToTop,
  displayEstimatedCredit,
  fields,
  variation,
  ctaText,
  priceElasticityEnabled = false,
}: {
  heading: string
  enableH1: boolean
  headingSize: string
  displaySubHeading: boolean
  handleSubmit: () => void
  subHeading: any
  disclaimerText?: string
  scrollToTop: () => void
  displayEstimatedCredit: boolean
  fields: any
  variation: string
  ctaText: string
  priceElasticityEnabled?: boolean
}) => {
  const onSubmit = (data) => {
    handleSubmit(data)

    estimateWidgetAnalytics.continuedToSecondView({
      properties: {
        priceElasticityEnabled,
      },
    })
  }

  return (
    <div className={styles.infoForm}>
      <Form
        config={{
          formName: 'User Info Form',
          formId: 'UserInfoForm',
          autocompleteOff: true,
          onSubmit,
          fields,
        }}
      >
        {({
          field,
          getFormIsValid,
        }: {
          field: (fieldName: string) => Element
          getFormIsValid: () => boolean
        }) => (
          <div className={styles.infoFormInner}>
            <div className={styles.copy}>
              <Heading headingSize={headingSize} enableH1={enableH1}>
                {heading}
              </Heading>

              <Subheading displaySubHeading={!heading || displaySubHeading}>
                {subHeading}
              </Subheading>
            </div>

            <UserInfoFields
              displayEstimatedCredit={displayEstimatedCredit}
              field={field}
              getFormIsValid={getFormIsValid}
              scrollToTop={scrollToTop}
              disclaimerText={disclaimerText}
              showDisclaimer={variation === EstimateWidgetVariations.HERO}
              ctaText={ctaText}
            />
          </div>
        )}
      </Form>
    </div>
  )
}
