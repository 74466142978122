import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { LEGAL_BANNER_COOKIE, LEGAL_BANNER_COOKIE_VALUE } from '@/constants'
import Cookies from 'js-cookie'

interface ILegalBannerContext {
  isDisclaimerOpen: boolean
  setIsDisclaimerOpen: (isDisclaimerOpen: boolean) => void
  ref: React.RefObject<HTMLDivElement> | null
  disclaimerHeight?: number
  setDisclaimerHeight?: (height: number) => void
}

const LegalBannerContext = createContext<ILegalBannerContext>({
  isDisclaimerOpen: false,
  setIsDisclaimerOpen: () => {},
  ref: null,
  disclaimerHeight: 0,
  setDisclaimerHeight: () => {},
})

export const useLegalBannerContext = () => useContext(LegalBannerContext)

export const LegalBannerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false)
  const ref = useRef(null)
  const [disclaimerHeight, setDisclaimerHeight] = useState(0)
  useEffect(() => {
    if (Cookies.get(LEGAL_BANNER_COOKIE) !== LEGAL_BANNER_COOKIE_VALUE) {
      setIsDisclaimerOpen(true)
    }
  }, [])

  return (
    <LegalBannerContext.Provider
      value={{
        isDisclaimerOpen,
        setIsDisclaimerOpen,
        ref,
        disclaimerHeight,
        setDisclaimerHeight,
      }}
    >
      {children}
    </LegalBannerContext.Provider>
  )
}
