import React from 'react'

import { LayoutProps } from './index'

const External = ({
  updateScrollNode,
  moduleData,
  formManager,
}: LayoutProps) => {
  return (
    <div className="max-w-full sm:max-w-sm md:z-1 md:max-w-md md:pr-9 md:pt-14">
      {formManager}
    </div>
  )
}

export default External
