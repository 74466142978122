import React from 'react'

import { CloudinaryImage } from 'ethos-design-system'
import { cn } from 'lib/client-utils'

type PressWreathsProps = {
  title: string
  subtitle: string
  logoUrl: string
  logoAlt: string
  variant: 'dark' | 'light'
}

const darkWreathUrl =
  'https://res.cloudinary.com/getethos/image/upload/v1715196407/dark-wreath_vzvzrm.svg'

const lightWreathUrl =
  'https://res.cloudinary.com/getethos/image/upload/v1715303162/wreath-light_m9gb4c.svg'

const PressWreaths = ({
  title,
  subtitle,
  logoUrl,
  logoAlt,
  variant,
}: PressWreathsProps) => {
  return (
    <div className="flex items-center">
      <div className="max-w-[32px]">
        <CloudinaryImage
          publicId={variant === 'dark' ? darkWreathUrl : lightWreathUrl}
          alt={'Green wreath'}
          crop={CloudinaryImage.CROP_METHODS.FIT}
          height={[56, 56, 56, 56]}
          width={[32, 32, 32, 32]}
        />
      </div>
      <div
        className={cn(
          'flex flex-col gap-y-2.5 text-center font-medium',
          variant === 'dark' && 'text-[#525252]',
          variant === 'light' && 'text-[#F3F7F7]'
        )}
      >
        <div className="font-['Cambon'] text-2xl font-bold leading-none tracking-[2.4px]  md:text-[30px] md:tracking-[3px]">
          {title}
        </div>
        <div
          className={cn(
            'whitespace-pre-line text-[9px] uppercase leading-[12px] tracking-[0.9px] md:text-[10px] md:tracking-[1.1px]',
            variant === 'light' && 'text-[#DAE7E6]'
          )}
        >
          {subtitle}
        </div>
        <div className="m-auto max-h-[23px] max-w-[71px]">
          <CloudinaryImage
            publicId={logoUrl}
            alt={logoAlt}
            crop={CloudinaryImage.CROP_METHODS.FIT}
            height={[23, 23, 23, 23]}
            width={[70, 70, 70, 70]}
          />
        </div>
      </div>
      <div className="max-w-[32px]">
        <CloudinaryImage
          className="scale-x-[-1]"
          publicId={variant === 'dark' ? darkWreathUrl : lightWreathUrl}
          alt={'Green wreath'}
          crop={CloudinaryImage.CROP_METHODS.FIT}
          height={[56, 56, 56, 56]}
          width={[32, 32, 32, 32]}
        />
      </div>
    </div>
  )
}

export default PressWreaths
