import React from 'react'

export const EthosLogo = () => {
  return (
    <div className="pl-1 pt-2">
      <svg
        width="119"
        height="24"
        viewBox="0 0 119 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5959 1.27304V4.95731H29.7473V23.3809H33.3223V4.95731H40.4729V1.27304H22.5959ZM0 1.27304V23.3809H15.8314V19.6959H3.57498V14.1691H14.2242V10.4849H3.57498V4.95731H15.8314V1.27304H0ZM61.9252 1.27304V10.4849H51.1996V1.27304H47.6239V23.3809H51.1996V14.1691H61.9252V23.3809H65.5002V1.27304H61.9252ZM92.8599 3.35767C92.1346 2.6102 91.2736 2.01727 90.3259 1.61274C89.3783 1.20821 88.3626 1 87.3369 1C86.3112 1 85.2955 1.20821 84.3479 1.61274C83.4003 2.01727 82.5392 2.6102 81.814 3.35767C81.3733 3.81262 80.4551 4.87619 80.1414 5.40003C81.6329 4.4513 83.3909 4.04781 85.1321 4.2546C86.8733 4.46138 88.4961 5.26638 89.7391 6.53985C90.9821 7.81331 91.7728 9.48104 91.9837 11.2742C92.1946 13.0673 91.8134 14.8814 90.9016 16.4241L91.0221 16.2999C91.5451 15.9729 92.4081 15.2068 92.8599 14.7413C93.5852 13.9938 94.1605 13.1065 94.553 12.1299C94.9456 11.1533 95.1476 10.1065 95.1476 9.04947C95.1476 7.9924 94.9456 6.94569 94.553 5.96908C94.1605 4.99248 93.5852 4.10512 92.8599 3.35767Z"
          fill="white"
        />
        <path
          d="M79.624 16.064C76.5551 12.9013 74.6754 7.08379 78.1388 0.933999L76.5198 2.60241C74.3761 4.81174 73.1719 7.80819 73.1719 10.9326C73.172 14.057 74.3764 17.0534 76.5202 19.2626C78.664 21.4718 81.5716 22.7129 84.6033 22.7129C87.635 22.7128 90.5425 21.4716 92.6862 19.2622L94.3051 17.5938C88.622 20.9988 82.9668 19.509 79.624 16.064ZM110.451 22.7728C115.645 22.7728 119 20.121 119 16.0166C119 12.1686 116.376 10.7768 112.14 9.78056L109.546 9.1939C107.21 8.66173 105.944 8.04327 105.944 6.29917C105.944 4.521 107.552 3.37214 110.04 3.37214C112.362 3.37214 114.405 4.26084 115.664 5.81494L118.359 3.58309C116.876 1.93032 114.259 0 110.135 0C105.475 0 102.092 2.73122 102.092 6.49497C102.092 10.7901 105.504 12.1012 108.477 12.7636L111.356 13.4161C113.633 13.9256 115.149 14.6243 115.149 16.3752C115.149 18.2571 113.506 19.335 110.642 19.335C107.518 19.335 105.156 17.7537 104.167 16.1595L101.21 18.3532C102.733 20.5038 105.941 22.7725 110.451 22.7725"
          fill="white"
        />
      </svg>
    </div>
  )
}
