interface Props {
  age: number
}

const MIN_TAWL_COVERAGE = 5000
const START_FROM = MIN_TAWL_COVERAGE
const STEP = 5000

const getEndAt = (age: number) => {
  if (age < 71) return 100000
  if (age >= 71 && age < 76) return 50000
  return 25000
}

export const getAvailableOptions = ({ age }: Props) => {
  const options = []
  const endAt = getEndAt(age)

  for (let i = START_FROM; i <= endAt; i += STEP) {
    options.push({
      value: i,
      label: `$${i.toLocaleString('en-US')}`,
    })
  }
  return options
}
