import { FINAL_EXPENSE_WIDGET } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useFeWidget = () => {
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: FINAL_EXPENSE_WIDGET.EXPERIMENT_KEY,
    fallbackVariation: FINAL_EXPENSE_WIDGET.VARIATIONS.CONTROL,
  })

  return {
    isLoading,
    isTreatment:
      isEnabled && variation === FINAL_EXPENSE_WIDGET.VARIATIONS.TREATMENT,
    pageKeys: FINAL_EXPENSE_WIDGET.PAGE_VALUES,
  }
}
