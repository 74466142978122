import { ESTIMATE_EXPERIENCE_AGES } from '@/constants'
import dayjs, { Dayjs } from 'dayjs'
import utcPlugin from 'dayjs/plugin/utc'
import moment from 'moment'

interface BirthDateProps {
  birthDate?: string | number
  useFloatingPoint?: boolean
  referenceDate?: Dayjs
}

dayjs.extend(utcPlugin)

const formats = ['YYYY-MM-DD', 'MM-DD-YYYY', 'YYYY']

/**
 * Given a birthdate, returns the person's age in years as a number.
 * @param {string} birthDate - A date in the form of an ISO string.
 * @param {boolean} useFloatingPoint - Use floating point for dayjs.diff calculation
 * @param referenceDate
 * @return {number} - The person's age in integer years.
 *
 * Note, we use `utc` because referenceDate and birthDate can come through as
 * a date without time (e.g. 1990-07-18) and so we prefer to parse it as
 * a UTC date explicitly than let it be parsed in the machine's timezone which
 * could be arbitrary
 */
export const ageFromBirthDate = ({
  birthDate,
  useFloatingPoint = true,
  referenceDate = dayjs(),
}: BirthDateProps) => {
  if (!birthDate) {
    return undefined
  }

  if (
    typeof birthDate === 'string' &&
    Number(birthDate) <= ESTIMATE_EXPERIENCE_AGES.MAX &&
    Number(birthDate) >= ESTIMATE_EXPERIENCE_AGES.MIN
  ) {
    return birthDate
  }

  const age = Math.floor(
    dayjs
      .utc(referenceDate)
      .diff(
        dayjs.utc(moment(birthDate, formats, true).toISOString()),
        'years',
        useFloatingPoint
      )
  )

  return getExperienceAge(age)
}

export const getExperienceAge = (age: number | string) => {
  if (Number(age) < ESTIMATE_EXPERIENCE_AGES.MIN) {
    return String(ESTIMATE_EXPERIENCE_AGES.MIN)
  }

  if (Number(age) > ESTIMATE_EXPERIENCE_AGES.MAX) {
    return String(ESTIMATE_EXPERIENCE_AGES.MAX)
  }

  return String(age)
}
