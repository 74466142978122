import { useState } from 'react'

import { estimateWidgetAnalytics } from '../../../../lib/@getethos/analytics/analyticsEvents'

const EVENT_NAME = 'CMS Estimate Widget - Estimates Saved'
const MODULE_NAME = 'CMS Estimate Widget'

interface UseEmailProps {
  userData: any
  minPrice: number
  maxPrice: number
  term?: string
  coverage?: string
}

interface UseEmailReturn {
  saveEmailFormCallback: (formData: any) => void
  openEmailModal: () => void
  emailModalClose: () => void
  email: string
  emailModal: boolean
  emailSuccess: boolean
}

/**
 * Hook to expose the Email Modal
 */
export const useEmailModal = ({
  userData,
  minPrice,
  maxPrice,
  term,
  coverage,
}: UseEmailProps): UseEmailReturn => {
  const [email, setEmail] = useState('')
  const [emailSuccess, setEmailSuccess] = useState(false)
  const [emailModal, setEmailModal] = useState(false)

  function saveEmailForm(email: string): void {
    if (typeof window === 'undefined') {
      return
    }

    estimateWidgetAnalytics.emailSender({
      properties: {
        event: EVENT_NAME,
        module: MODULE_NAME,
        link: window.location.href,
        recipient: email,
        userData: { ...userData, term, coverage },
        minPrice,
        maxPrice,
      },
    })

    estimateWidgetAnalytics.savedEstimates({
      properties: {
        email,
        minPrice,
        maxPrice,
      },
    })
    setEmailSuccess(true)
  }

  // Email modal to save estimates
  // ------------------------------------------------------------
  function openEmailModal(): void {
    setEmailModal(true)
  }

  function emailModalClose(): void {
    setEmailModal(false)
    setEmailSuccess(false)
  }

  async function saveEmailFormCallback(formData: any): Promise<void> {
    saveEmailForm(formData.email) // todo handle error from customer IO on fail
    setEmail(formData.email)
    setEmailSuccess(true)
  }

  return {
    saveEmailFormCallback,
    emailModal,
    openEmailModal,
    emailModalClose,
    email,
    emailSuccess,
  }
}
