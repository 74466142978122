import { FC } from 'react'

import { ButtonSelectGroup } from 'ethos-design-system'

import styles from './ButtonGroup.module.scss'

type Option = {
  value: string
  copy: string
}

interface ButtonGroupProps {
  initialValue: any
  labelCopy: string
  options: Option[]
  onChange: (value: string) => void
}

export const ButtonGroup: FC<ButtonGroupProps> = ({
  initialValue,
  labelCopy,
  options,
  onChange,
}) => {
  return (
    <div className={styles.buttonGroup}>
      <ButtonSelectGroup
        labelCopy={labelCopy}
        allCaps={false}
        capitalize={false}
        labelWeight={'regular'}
        initialValue={initialValue}
      >
        {options.map((option) => (
          <ButtonSelectGroup.Option
            value={option.value}
            key={`buttonSelectGroup${option.value}`}
            onClick={() => onChange(option.value)}
          >
            {option.copy}
          </ButtonSelectGroup.Option>
        ))}
      </ButtonSelectGroup>
    </div>
  )
}
