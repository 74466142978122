import { FC } from 'react'

import { Button, Dropdown, Input } from '@getethos/ethos-design-system-v2'
import { yupResolver } from '@hookform/resolvers/yup'
import classnames from 'classnames'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { apiRoutes } from 'lib/@getethos/urls/apiRoutes'
import { Controller, FieldErrors, useForm } from 'react-hook-form'

import { useEstimateQuotesContext } from '@/components/EstimateWidget/contexts/EstimateQuotesContext'

import Consent from '../../LeadForm/components/Consent'
import { phoneMask } from '../../LeadForm/utils'
import { STATE_OPTIONS } from '../utils/constants'
import { mooValidationSchemaWPhone } from '../utils/validations'
import styles from './form.module.scss'

type FormProps = {
  firstName: string
  birthDate: string
  region: string
  phone: string
}

interface FinalExpenseStateFormProps {
  onSubmit: (formValues: FormProps) => void
  handleOnBlur?: (field: string) => void
  defaultValues?: Partial<FormProps>
}

const FinalExpenseFormWithPhone: FC<FinalExpenseStateFormProps> = ({
  onSubmit,
  handleOnBlur,
  defaultValues,
}) => {
  const { control, handleSubmit, getValues, setError } = useForm<FormProps>({
    defaultValues,
    resolver: yupResolver(mooValidationSchemaWPhone),
    mode: 'onSubmit',
  })

  const { userSelectedValues } = useEstimateQuotesContext()

  const onInvalidSubmit = (errors: FieldErrors<FormProps>) => {
    const currentValues = getValues()
    estimateWidgetAnalytics.continueToPricesError({
      properties: {
        triedToEditAnswers: !!userSelectedValues,
        errorMessages: errors,
        currentValues,
      },
    })
  }

  const validateAndSubmitForm = async () => {
    try {
      await apiRoutes.validatePhoneTwilio(getValues().phone)
      onSubmit(getValues())
    } catch (error) {
      setError('phone', {
        type: 'manual',
        message:
          'Phone validation failed. Please ensure the phone number is correct and try again.',
      })
    }
  }

  const submitButtonTitle = 'GET MY ESTIMATE'

  return (
    <form onSubmit={handleSubmit(validateAndSubmitForm, onInvalidSubmit)}>
      <div className="grid grid-cols-2 gap-x-4 gap-y-6">
        <div className={classnames('col-span-2', styles.inputWrapper)}>
          <Controller
            control={control}
            name="firstName"
            render={({
              field: { ref, ...fieldRest },
              fieldState: { error },
            }) => (
              <Input
                {...fieldRest}
                label="First name"
                onBlur={() => handleOnBlur?.('firstName')}
                inputSize="md"
                errorMessage={error?.message}
              />
            )}
          />
        </div>
        <div className={classnames('col-span-1', styles.inputWrapper)}>
          <Controller
            control={control}
            name="birthDate"
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                label="Age"
                inputSize="md"
                onBlur={() => handleOnBlur?.('birthDate')}
                errorMessage={error?.message}
              />
            )}
          />
        </div>
        <div className={classnames('col-span-1 !mb-0', styles.dropdownWrapper)}>
          <Controller
            control={control}
            name="region"
            render={({ field, fieldState: { error } }) => (
              <Dropdown
                {...field}
                label="State"
                options={STATE_OPTIONS}
                errorMessage={error?.message}
                onBlur={() => handleOnBlur?.('state')}
                onChange={(option) => field.onChange(option?.value)}
                value={STATE_OPTIONS.find(
                  (option) => option.value === field?.value
                )}
                defaultValue={STATE_OPTIONS.find(
                  (option) => option.value === defaultValues?.region
                )}
                placeholder="Select"
              />
            )}
          />
        </div>
        <div className={classnames('col-span-2', styles.inputWrapper)}>
          <Controller
            control={control}
            name="phone"
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                label="Phone"
                errorMessage={error?.message}
                onBlur={() => handleOnBlur?.('phone')}
                inputSize="md"
                placeholder="(555) 555 - 5555"
                mask={phoneMask}
              />
            )}
          />
        </div>
      </div>
      <Button
        className={styles.button}
        buttonTitle={submitButtonTitle}
        alignment="center"
        variant="salamander"
        type="submit"
        size="lg"
      />
      <Consent className={'mt-4 !text-left'} buttonTitle={submitButtonTitle} />
    </form>
  )
}

export default FinalExpenseFormWithPhone
