import { FC } from 'react'

import { Banner } from './components/Banner/Banner'
import { ProgressBar } from './components/ProgressBar/ProgressBar'

interface INavbarProps {
  showNavbar?: boolean
  progress?: number
}

export const Navbar: FC<INavbarProps> = ({
  showNavbar = true,
  progress = 20,
}) => {
  if (!showNavbar) return null

  return (
    <>
      <Banner />
      <ProgressBar progress={progress} />
    </>
  )
}
