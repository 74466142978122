import '@reach/slider/styles.css'

import React, { useMemo } from 'react'

import {
  AFFILIATES_NON_PREFILL_LP_URL,
  DAILY_PRICING_CPL_EXCLUSIONS,
  ESTIMATE_PRICE_RANGE_COPY,
} from '@/constants'
import { useDirectToAppContext } from '@/contexts/DirectToAppContext'
import { useSendAnalytics } from '@/shared/Estimate/useSendAnalytics'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { getAttributionCookie } from 'lib/@getethos/analytics/getAttributionCookie'
import { DESIRED_COVERAGE } from 'lib/@getethos/experiments/feature_constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

import { useDirectToAppUx } from '@/hooks/features/useDirectToAppUx'

import {
  EstimateWidgetImageLayout,
  EstimateWidgetModuleData,
} from '../../EstimateWidget'
import { EstimateQuotesProvider } from '../../contexts/EstimateQuotesContext'
import { useEstimateWidgetContext } from '../../contexts/EstimateWidgetContext'
import { EstimateWidgetProgress } from '../../types/widget'
import { getFloatingFieldsRefactor } from '../config/getFloatingFieldsRefactor'
import { Navbar } from '../experiments/SocialLandingPageExperiment/Navbar/Navbar'
import { calculateProgress } from '../experiments/SocialLandingPageExperiment/utils/calculateProgress'
import { FloatingInfoFormRefactored } from './FloatingInforFormRefactor'
import { FloatingRangeFormRefactor } from './FloatingRangeForm/FloatingRangeFormRefactor'
import { EstimateWidgetLoader } from './components/EstimateWidgetLoader'
import { LeadFormWrapper } from './experiments/LeadForm/LeadFormWrapper'
import CPSales from './experiments/Sales/CPSales'

interface IFloatingFormManagerRefactorProps {
  moduleData: EstimateWidgetModuleData
  scrollNode: Element
}

export const FloatingFormManagerRefactor = ({
  moduleData,
  scrollNode,
}: IFloatingFormManagerRefactorProps) => {
  const {
    userDataControls: { userData },
    widgetProgressControls: { widgetProgress, setWidgetProgress },
  } = useEstimateWidgetContext()

  const { progress: currentProgress } = useDirectToAppContext()

  const { isTreatment, isVariant1, isVariant2, isVariant3 } = useDirectToAppUx()

  let container = ''

  const salamanderFormEnabled = moduleData.salamanderForm
  const cypressFormEnabled = moduleData.cypressForm

  const genderQuestion = moduleData.genderLabel || ''
  const desiredCoverageQuestion =
    moduleData.desiredCoverageLabel || 'Desired coverage'
  const birthdateQuestion = moduleData.birthLabel || ''
  const zipcodeQuestion = moduleData.zipLabel || ''
  const nicotineQuestion = moduleData.nicotineLabel || ''

  const fieldLabels = {
    birthdateQuestion,
    desiredCoverageQuestion,
    genderQuestion,
    nicotineQuestion,
    zipcodeQuestion,
  }

  const { sendAnalyticsEvent } = useSendAnalytics(estimateWidgetAnalytics)

  const estimatedCredit = {
    estimatedCreditFootnote: moduleData.estimatedCreditFootnote || '',
    estimatedCreditQuestion: moduleData.estimatedCreditLabel || '',
    displayEstimatedCredit: moduleData.displayEstimatedCredit || false,
    variation: moduleData.variation || '',
  }

  const { isEnabled: desiredCoverageEnabled } = useDecisionForFlag({
    name: DESIRED_COVERAGE.name,
    fallbackVariation: DESIRED_COVERAGE.variations.off,
  })

  const floatingFields = useMemo(
    () =>
      getFloatingFieldsRefactor({
        userData,
        estimatedCredit,
        labels: fieldLabels,
        showDesiredCoverage:
          Boolean(moduleData.showDesiredCoverage) && desiredCoverageEnabled,
        analytics: sendAnalyticsEvent,
        featureConfig: {
          hideNicotineQuestion: false,
        },
      }),
    [userData, isTreatment]
  )

  const boxShadow =
    moduleData?.imageLayout === EstimateWidgetImageLayout.MultiImage

  // Exclude CPL affiliates from daily premium pricing

  const currentPath =
    typeof window !== 'undefined' ? window.location.pathname : null
  const attributionCookie = getAttributionCookie() as any
  const pathToMatch = `/${AFFILIATES_NON_PREFILL_LP_URL}/`

  const cplAffiliate =
    currentPath === pathToMatch &&
    DAILY_PRICING_CPL_EXCLUSIONS.includes(attributionCookie['utmTerm'])

  const showDailyPremium = moduleData.dailyPremium && !cplAffiliate

  const estimateCopy = !showDailyPremium
    ? ESTIMATE_PRICE_RANGE_COPY.MONTHLY
    : moduleData.estimateRangeCopy || ESTIMATE_PRICE_RANGE_COPY.MONTHLY

  // End exclusion for CPL affiliates

  const isStraightToApp =
    moduleData.imageLayout === EstimateWidgetImageLayout.StraightToApp
  const ctaText = isStraightToApp
    ? 'See my estimate'
    : moduleData?.firstButton
    ? moduleData?.firstButton
    : 'Continue'

  const infoFormVariations = {
    asta: isStraightToApp,
    boxShadow,
    cypressForm: cypressFormEnabled,
    displayEstimatedCredit: moduleData?.displayEstimatedCredit,
    estimateCopy: moduleData.estimateInfoCopy || '',
    priceElasticityEnabled: moduleData?.priceElasticity,
    salamanderForm: salamanderFormEnabled,
    finalExpense:
      moduleData.imageLayout === EstimateWidgetImageLayout.FinalExpense,
  }

  const getTotalSteps = () => {
    if (isVariant1 || isVariant3) return 2 // variant 1 and 2 have only INFO/RESULTS STEPS.
    if (isVariant2) return 7 // variant 2 has saparated zipcode/gender into different questions.
    return 0
  }

  const getCurrentStep = () => {
    if (isVariant1 || isVariant3) {
      return widgetProgress === EstimateWidgetProgress.PRICES ? 2 : 1
    } else if (isVariant2) {
      return currentProgress
    }
    return 0
  }
  const totalSteps = getTotalSteps()
  const currentStep = getCurrentStep()
  const progress = calculateProgress(currentStep, totalSteps) // Hardcoded to 1 for now as we do not have logic to track steps yet.

  return (
    <>
      {isTreatment && <Navbar progress={progress} />}
      <EstimateQuotesProvider moduleData={moduleData}>
        <div className={container}>
          <EstimateWidgetLoader
            isLoading={widgetProgress === EstimateWidgetProgress.LOADING}
            directToApp={isTreatment}
          />
          {widgetProgress === EstimateWidgetProgress.INFO && (
            <FloatingInfoFormRefactored
              ctaText={ctaText}
              scrollNode={scrollNode}
              fields={floatingFields}
              variations={infoFormVariations}
              moduleData={moduleData}
            />
          )}
          {widgetProgress === EstimateWidgetProgress.PRICES && (
            <FloatingRangeFormRefactor
              asta={isStraightToApp}
              estimateCopy={estimateCopy}
              moduleData={moduleData}
            />
          )}
          {widgetProgress === EstimateWidgetProgress.LEAD_FORM && (
            <LeadFormWrapper />
          )}
          {widgetProgress === EstimateWidgetProgress.SALES && <CPSales />}
        </div>
      </EstimateQuotesProvider>
    </>
  )
}
