import { getModuleIdClosure } from '@/helpers/keys'
import { CMSLayout } from '@/layouts/CmsLayout'

import { ErrorHandler } from '@/components/ErrorHandler'
import Client from '@/components/global/DynamicComponent/Client'

const NotFoundPage = ({ pageContent, settings }: any) => {
  const getModuleId = getModuleIdClosure()
  const cmsUrl = `${pageContent.pathKey}/${pageContent.url}`
  return (
    <>
      <ErrorHandler />
      <CMSLayout
        pageContent={pageContent}
        settings={settings}
        url={cmsUrl}
        agentLayout={false}
        footnoteFooterInfo={pageContent.footnoteFooter}
      >
        {pageContent.pageLayout.map(
          (module: { type: string; [key: string]: any }) => {
            const moduleId = getModuleId(module.type)
            return (
              <div id={moduleId} key={moduleId}>
                <Client
                  tag={module.type}
                  key={moduleId}
                  moduleData={module}
                  settings={settings}
                />
              </div>
            )
          }
        )}
      </CMSLayout>
    </>
  )
}

export default NotFoundPage
