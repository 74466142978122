import { PrimaryAddress } from '../LeadForm/utils'

export const defaultPrimaryAddress = {
  street1: undefined,
  street2: undefined,
  postalCode: undefined,
  locality: undefined,
  region: undefined,
}

const desired = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  neighborhood: 'short_name',
  sublocality_level_1: 'short_name',
  administrative_area_level_3: 'short_name',
  postal_code: 'short_name',
  subpremise: 'long_name',
  country: 'short_name',
}

type DesiredType = keyof typeof desired

export type FormattedType = {
  street1?: string
  street2?: string
  locality?: string
  region?: string
  postalCode?: string
  country?: string
}

export const parseUSResult = (result: any) => {
  const { address_components: addressComponents } = result

  const parsed = {} as Record<DesiredType, unknown>
  for (let i = 0, ii = addressComponents.length; i < ii; i++) {
    const addressType = addressComponents[i].types[0] as string
    if (desired[addressType as DesiredType]) {
      parsed[addressType as DesiredType] =
        addressComponents[i][desired[addressType as DesiredType]]
    }
  }

  const formatted = {} as FormattedType
  if (parsed.street_number || parsed.route) {
    formatted.street1 = [parsed.street_number, parsed.route]
      .filter((v) => !!v)
      .join(' ')
  }
  if (parsed.subpremise) {
    formatted.street2 = parsed.subpremise as string
  }
  if (
    parsed.locality ||
    parsed.sublocality_level_1 ||
    parsed.neighborhood ||
    parsed.administrative_area_level_3
  ) {
    formatted.locality = (parsed.locality ||
      parsed.sublocality_level_1 ||
      parsed.neighborhood ||
      parsed.administrative_area_level_3) as string
  }
  if (parsed.administrative_area_level_1) {
    formatted.region = parsed.administrative_area_level_1 as string
  }
  if (parsed.postal_code) {
    formatted.postalCode = parsed.postal_code as string
  }

  // Do not handle country except for making US -> USA. Otherwise this may not line up with Assurity's constants
  if (parsed.country) {
    formatted.country = parsed.country === 'US' ? 'USA' : undefined
  }

  return formatted
}

// For full address with length longer than 35, we only show the first 35 chars
// and hide the rest with an ellipsis.
export const addEllipsisIfNeeded = (fullAddress: string): string =>
  fullAddress.length > 35 ? fullAddress.substring(0, 35) + '...' : fullAddress

// Full address returned by Google Place API is broken down individual field for storage
// and validation purpose. We need to manually concat them back to a single string for
// prefill purpose. This is also used for indicator on whether we prefill the address.
export const stringifyAddress = (address: Partial<PrimaryAddress>): string => {
  const {
    street1 = '',
    locality = '',
    region = '',
    postalCode = '',
  } = address || defaultPrimaryAddress
  return street1 && locality && region
    ? street1 + ', ' + locality + ', ' + region + ' ' + postalCode
    : ''
}

export const STREET_FIELD_MAX_CHARS = 50
export const STREET_FIELD_ERROR_MESSAGE = `Street address cannot exceed ${STREET_FIELD_MAX_CHARS} chars`
export const GOOGLE_MAPS_PARSE_ISSUE = `Oops! We had an issue converting your address. Please enter it manually.`

export enum ERROR_MESSAGES {
  INVALID_STATE = 'Unfortunately Ethos does not sell policies in this state yet.',
  AUTOCOMPLETE_NO_RESULTS = 'We cannot find your address at this time; please try again later.',
}
