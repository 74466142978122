import { useEffect } from 'react'

import { errorMonitor } from 'lib/ErrorMonitor/ErrorMonitor'

export const ErrorHandler = () => {
  const errorObject = new Error('Not Found Route')
  useEffect(() => {
    const errorInfoObject = { url: window.location.href }
    errorMonitor.reportError({ errorObject, errorInfoObject })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
