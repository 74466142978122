import { FC } from 'react'

import classNames from 'classnames'

import styles from './Loader.module.scss'

interface LoaderProps {
  isLoading: boolean
  className?: string
}

export const Loader: FC<LoaderProps> = ({ isLoading, className }) => {
  return (
    <div
      className={classNames(styles.loader, className, {
        [styles.fadeOut]: !isLoading,
      })}
    />
  )
}
