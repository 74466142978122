import React from 'react'

import classNames from 'classnames'
import { CloudinaryImage, Spacer } from 'ethos-design-system'
import { cn } from 'lib/client-utils'

import PriorityImage from '../../global/PriorityImage'
import styles from '../SingleCTA.module.scss'
import { SingleCTAInterface } from '../interface'
import { InsuranceInfo, trustImageGroupsV2 } from './FinalExpense'
import { FloatingCallInfoWidget } from './FloatingCallInfoWidget/FloatingCallInfoWidget'

const FinalExpenseWithFloatingInfo = ({
  moduleData,
  ctaClick,
  phoneNumber,
}: {
  moduleData: SingleCTAInterface
  ctaClick: () => void
  phoneNumber: {
    key: string
    value: string
  } | null
}) => {
  const { groupImage, imageAlt } = moduleData

  const navbarPadding =
    moduleData?.navbarPadding === '64' ? (
      <div className="hidden w-full md:block">
        <Spacer.H64 />
      </div>
    ) : (
      <div className="w-full">
        <Spacer.H80 />
      </div>
    )

  const TrustImageGroups = () => {
    return (
      <div className={cn(styles.trustImages, 'custom-md:!mb-18 pb-8 lg:pb-36')}>
        {trustImageGroupsV2?.map((trustImageGroup, index) => (
          <div
            key={`trustImage-${index}`}
            className={styles.trustImageContainer}
          >
            <CloudinaryImage
              publicId={trustImageGroup.groupImage}
              alt={trustImageGroup.imageAlt}
              className={styles.trustImageV2}
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[142, 142, 142, 142]}
              width={[85, 85, 85, 85]}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.finalExpense, 'pb-0 custom-lg:pb-36')}>
        <div className={styles.container}>
          {moduleData.navbarPadding !== '0' && <>{navbarPadding}</>}
          <div className={styles.mainContent}>
            <h1 className={styles.header}>Final expense insurance</h1>
            <InsuranceInfo />
            <TrustImageGroups />
          </div>
        </div>
        <div className={styles.bgContainer}>
          <div className={classNames(styles.bgImage, 'lg:!-bottom-20')}>
            {groupImage && imageAlt && (
              <PriorityImage
                publicId={groupImage}
                fetchpriority="high"
                alt={imageAlt}
                crop={CloudinaryImage.CROP_METHODS.CROP}
                height={[0, 384, 729, 972]}
                width={[0, 768, 1440, 1920]}
              />
            )}
          </div>
        </div>
      </div>
      <FloatingCallInfoWidget
        phoneNumber={phoneNumber}
        onClick={ctaClick}
        ctaLabel={'APPLY ONLINE'}
      />
    </div>
  )
}

export default FinalExpenseWithFloatingInfo
