import validateNumberRange from 'ethos-design-system/src/validators/NumberInputValidator'
import { ageNearestBirthDate } from 'lib/@getethos/utils/ageNearestBirthDate'
import { setAndGetUserUuid } from 'lib/FeaturesAndExperiments/setAndGetUserUUID'
import { bool, object, string } from 'yup'

export type PrimaryAddress = {
  street1: string
  street2?: string
  postalCode: string
  locality: string
  region: string
}

export enum LeadSource {
  CP_WITHOUT_QUOTE = 'CP Without Quote',
  CP_WITH_SALES = 'CP With Sales',
  FE_WIDGET_WITH_PHONE = 'FE Widget With Phone',
}

export interface LeadFormInputFields {
  firstName: string
  lastName: string
  gender: string
  birthDate: string
  citizen: string
  phone: string
  primaryAddress: Partial<PrimaryAddress>
  isManualForm?: boolean
}

interface DataForSalesForce {
  First_Name: string
  Last_Name: string
  External_Identifier: string
  Lead_Source: string
  Address?: string
  City?: string
  Zip?: string
  State?: string
  Phone?: string
  Applicant_Age?: number
}

export const birthDateMask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const phoneMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const citizenValidator = (citizen: string): string => {
  return citizen === 'Other'
    ? 'Unfortunately Ethos can only sell policies to US citizens or permanent residents at this time.'
    : ''
}

const minimumEligibleAge = 20
const maximumEligibleAge = 85

export const validationSchema = (isTreatment2: boolean) =>
  object().shape({
    firstName: string().required('Please provide first name'),
    lastName: string().required('Please provide last name'),
    gender: string().required('Please provide gender'),
    birthDate: string()
      .required('Please provide birth date')
      .test('customBirthDateValidation', (value, { createError }) => {
        const birthDateError = validateNumberRange(
          minimumEligibleAge,
          maximumEligibleAge,
          `Sorry, you must be between ${minimumEligibleAge}-${maximumEligibleAge}`
        )(ageNearestBirthDate({ birthDate: value }))
        return !birthDateError || createError({ message: birthDateError })
      }),
    citizen: string()
      .required('Please provide citizenship')
      .test('customCitizensValidation', (value, { createError }) => {
        const citizenError = citizenValidator(value)
        return !citizenError || createError({ message: citizenError })
      }),
    ...(isTreatment2
      ? { phone: string().required('Please provide phone') }
      : {}),
    isManualForm: bool(),
    primaryAddress: object({
      street1: string().required('Please provide state'),
      street2: string(),
      postalCode: string().required('Please provide zip code'),
      locality: string().required('Please provide city'),
      region: string().required('Please provide state'),
    }),
  })

interface SalesForceLeadData {
  firstName?: string
  lastName?: string
  gender?: string
  birthDate?: string
  citizen?: string
  phone?: string
  primaryAddress?: Partial<PrimaryAddress>
  isManualForm?: boolean
  region?: string
}

export const prepareDataForSalesForce = (
  values: SalesForceLeadData,
  leadSource: LeadSource
) => {
  const userId = setAndGetUserUuid()

  // Initialize data object with required properties
  const data: DataForSalesForce = {
    First_Name: '⠀',
    Last_Name: '⠀',
    External_Identifier: userId,
    Lead_Source: leadSource,
  }

  if (values.primaryAddress) {
    const { locality, postalCode, region, street1, street2 } =
      values.primaryAddress

    const address = `${street1}${
      street2 ? `, ${street2}` : ''
    }, ${locality}, ${region} ${postalCode}`

    data.Address = address
    data.City = locality
    data.Zip = postalCode
    data.State = region
  }
  if (values.region) {
    data.State = values.region
  }
  if (values.firstName) {
    data.First_Name = values.firstName
  }
  if (values.lastName) {
    data.Last_Name = values.lastName
  }
  if (values.phone) {
    data.Phone = values.phone
  }
  if (values.birthDate) {
    data.Applicant_Age =
      typeof values.birthDate === 'string'
        ? ageNearestBirthDate({ birthDate: values.birthDate })
        : values.birthDate
  }

  return data
}
