import '@reach/slider/styles.css'

import React, { useEffect, useState } from 'react'

import dynamic from 'next/dynamic'

import { TitleSmall2 } from 'ethos-design-system'

import { estimateWidgetAnalytics } from '../../../../lib/@getethos/analytics/analyticsEvents'
import { getAttributionCookie } from '../../../../lib/@getethos/analytics/getAttributionCookie'
import {
  AFFILIATES_NON_PREFILL_LP_URL,
  DAILY_PRICING_CPL_EXCLUSIONS,
  ESTIMATE_PRICE_RANGE_COPY,
} from '../../../constants'
import { wrapLastTwoWords } from '../../../helpers/words'
import { useEmailModal } from '../../../shared/Estimate/EmailModal/useEmailModal'
import { useFormState } from '../../../shared/Estimate/useFormState'
import { EstimateWidgetImageLayout } from '../EstimateWidget'
import { DATA_TID } from '../common/data-tid'
import { scrollToTop } from '../common/scrollToTop'
import styles from './Floating.module.scss'
import { FloatingInfoForm } from './FloatingInfoForm'
import { FloatingRangeForm } from './FloatingRangeForm'
import { getFloatingFields } from './getFloatingFields'

const EmailModal = dynamic(
  () => import('../../../shared/Estimate/EmailModal/EmailModal'),
  { ssr: false }
)

export const FloatingFormManager = ({
  moduleData,
  scrollNode,
  minimumEligibleAge,
  maximumEligibleAge,
  progressFunction,
}: any) => {
  const [initialFetch, setInitialFetch] = useState(false)
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  //feature flag configs that we use to configure experiments
  const featureFlagConfig = {
    useFinalExpensePriceRange: moduleData.useFinalExpensePriceRange,
    usePriceElasticity: moduleData.priceElasticity,
    useRatesCopy: moduleData.ratesCopy,
    useDailyPremium: moduleData.dailyPremium,
    useMedianValues: moduleData?.showMedianCoverage || false,
  }

  const formState = useFormState(estimateWidgetAnalytics, featureFlagConfig)

  const emailMinPrice = formState.ranges.min
  const emailMaxPrice = formState.ranges.max
  const emailTerm = formState.term
  const emailCoverage = formState.coverage

  let augmentedUserData = formState.userData
  if (
    formState.userData &&
    formState.userData.birthDate &&
    typeof formState.userData.birthDate === 'string' &&
    formState.userData.birthDate.length == 2 &&
    typeof formState.userData.age == 'undefined'
  ) {
    augmentedUserData = {
      ...formState.userData,
      age: formState.userData.birthDate,
    }
  }
  const modalState = useEmailModal({
    userData: augmentedUserData,
    minPrice: emailMinPrice,
    maxPrice: emailMaxPrice,
    term: emailTerm,
    coverage: emailCoverage,
  })

  let container = styles.hideForms

  // Determine which form to show
  if (hasMounted) {
    if (formState.userData.readyToRender && !formState.isUserDataComplete) {
      container = styles.showInfoForm
    } else if (
      formState.userData.readyToRender &&
      formState.isUserDataComplete
    ) {
      container = styles.showRangeForm
    }
  }

  const salamanderFormEnabled = moduleData.salamanderForm
  const cypressFormEnabled = moduleData.cypressForm

  const genderQuestion = moduleData.genderLabel
  const birthdateQuestion = moduleData.birthLabel
  const zipcodeQuestion = moduleData.zipLabel
  const estimatedCreditQuestion = moduleData.estimatedCreditLabel

  const floatingFields = getFloatingFields({
    minimumEligibleAge,
    maximumEligibleAge,
    genderQuestion,
    birthdateQuestion,
    nicotineQuestion: moduleData.nicotineLabel,
    healthQuestion: moduleData.healthLabel,
    zipcodeQuestion,
    displayEstimatedCredit: moduleData.displayEstimatedCredit,
    estimatedCreditQuestion,
    estimatedCreditFootnote: moduleData.estimatedCreditFootnote,
    dateFormat: DATA_TID.DATE_FORMAT,
    sendAnalyticsEvent: formState.sendAnalyticsEvent,
    dataTid: DATA_TID,
    userData: formState.userData,
    variation: moduleData.variation,
    hideNicotineQuestion: false,
  })

  let boxShadow = false
  if (
    moduleData.imageLayout &&
    moduleData.imageLayout === EstimateWidgetImageLayout.MultiImage
  ) {
    boxShadow = true
  }

  const [estimateCopy, setEstimateCopy] = useState(
    moduleData.estimateRangeCopy || ESTIMATE_PRICE_RANGE_COPY.MONTHLY
  )

  // Exclude CPL affiliates from daily premium pricing
  const [showDailyPremium, setShowDailyPremium] = useState(
    moduleData.dailyPremium
  )
  const currentPath =
    typeof window !== 'undefined' ? window.location.pathname : null
  const attributionCookie = getAttributionCookie() as any
  const pathToMatch = `/${AFFILIATES_NON_PREFILL_LP_URL}/`

  useEffect(() => {
    if (
      currentPath === pathToMatch &&
      DAILY_PRICING_CPL_EXCLUSIONS.includes(attributionCookie['utmTerm'])
    ) {
      setShowDailyPremium(false)
      setEstimateCopy(ESTIMATE_PRICE_RANGE_COPY.MONTHLY)
    }
  }, [currentPath, pathToMatch, attributionCookie])
  // End exclusion for CPL affiliates

  const isStraightToApp =
    moduleData.imageLayout === EstimateWidgetImageLayout.StraightToApp
  const ctaText = isStraightToApp ? 'See my estimate' : 'Continue'

  const readyToRender = formState.userData.readyToRender && hasMounted

  return (
    <div className={container}>
      <div
        className={
          styles.loadingForm +
          ' w-96 max-w-full rounded-lg bg-white pb-6 pl-6 pr-6 pt-8'
        }
      >
        <TitleSmall2.Serif.Book500>Loading...</TitleSmall2.Serif.Book500>
      </div>
      {readyToRender ? (
        <FloatingInfoForm
          handleSubmit={formState.setUserData}
          progressFunction={() => progressFunction('prices')}
          scrollToTop={scrollToTop(scrollNode)}
          fields={floatingFields}
          displayEstimatedCredit={moduleData.displayEstimatedCredit}
          boxShadow={boxShadow}
          ctaText={moduleData.firstButton ? moduleData.firstButton : ctaText}
          estimateCopy={moduleData.estimateInfoCopy}
          salamanderForm={salamanderFormEnabled}
          cypressForm={cypressFormEnabled}
          asta={isStraightToApp}
          priceElasticityEnabled={featureFlagConfig.usePriceElasticity}
        />
      ) : null}

      <FloatingRangeForm
        styles={styles}
        estimateCopy={wrapLastTwoWords(estimateCopy)}
        coverageLabel={moduleData.coverageLabel}
        termLabel={moduleData.termLabel}
        backgroundColor={moduleData.backgroundColor}
        variation={moduleData.variation}
        disclaimerText={moduleData.disclaimerText}
        disclaimerColor={moduleData.disclaimerColor}
        saveLabel={moduleData.saveLabel}
        continueLabel={moduleData.continueLabel}
        showError={formState.error}
        editAnswers={formState.editAnswers}
        progressFunction={() => progressFunction('info')}
        asta={isStraightToApp}
        toggleSlider={formState.toggleSlider}
        initialFetch={initialFetch}
        setInitialFetch={() => setInitialFetch(true)}
        coverage={formState.coverage}
        term={formState.term}
        terms={formState.terms}
        ranges={formState.ranges}
        prevRanges={formState.prevRanges}
        maxCoverage={formState.maxCoverage}
        minCoverage={formState.minCoverage}
        product={formState.product}
        handleNavigateToApp={formState.handleNavigateToApp}
        openEmailModal={modalState.openEmailModal}
        boxShadow={boxShadow}
        dailyDollars={moduleData.dailyDollars}
        dailyPremium={showDailyPremium}
        salamanderForm={salamanderFormEnabled}
        cypressForm={cypressFormEnabled}
        showSinglePrice={moduleData.showSinglePrice}
        disablePresets={moduleData.disablePresets}
        disableSave={moduleData.disableSave}
        showMedianCoverage={moduleData?.showMedianCoverage || false}
        isUserDataComplete={formState.isUserDataComplete}
      />
      <EmailModal
        emailModal={modalState.emailModal}
        emailModalClose={modalState.emailModalClose}
        email={modalState.email}
        emailSuccess={modalState.emailSuccess}
        saveEmailFormCallback={modalState.saveEmailFormCallback}
        emailHeading={moduleData.emailHeading}
        emailSubHeading={moduleData.emailSubHeading}
        emailSendLabel={moduleData.emailSendLabel}
        variation={moduleData.variation}
        emailFieldDataTid={DATA_TID.EMAIL_INPUT}
        buttonTid={DATA_TID.EMAIL_BUTTON}
      />
    </div>
  )
}
