import React from 'react'

import {
  Body2,
  COLORS,
  CloudinaryImage,
  TitleSmall2,
  TitleXLarge2,
} from 'ethos-design-system'

import { CoBrandedX } from '../CoBrandedX'
import { EthosLogo } from '../EthosLogo'
import { MedalIcon } from '../MedalIcon'
import styles from './CoBranded.module.scss'
import { LayoutProps } from './index'

const CoBranded = ({
  updateScrollNode,
  moduleData,
  formManager,
}: LayoutProps): JSX.Element => (
  <>
    <div
      className={
        'flex items-center justify-center bg-gray-1 pb-1.5 pt-2 text-xs font-medium uppercase leading-3 tracking-wider text-gray-5'
      }
    >
      <div className="hidden items-center md:flex">
        <MedalIcon /> Ethos is the #1 term life insurance provider in the U.S.
      </div>
      <div className="block md:hidden">
        #1 term life insurance provider in the U.S.
      </div>
    </div>
    <div
      className="floatingContainer relative flex w-full justify-center bg-forest"
      ref={updateScrollNode}
    >
      <div
        className={
          'z-1 flex w-full max-w-screen-xl flex-col flex-wrap items-center justify-center p-3 lg:flex-row lg:flex-nowrap lg:items-start lg:justify-between lg:pb-20 xl:pb-16'
        }
      >
        <div className={'pb-2 pt-3 md:pb-8 md:pt-8 lg:pb-8 lg:pl-9 lg:pt-16'}>
          <div
            className={
              'max-w-sm text-center lg:w-144 lg:max-w-none lg:pr-4 lg:text-left xl:pr-0'
            }
          >
            <div className="flex items-center justify-center pb-4 pt-4 md:max-w-sm md:justify-between md:pt-0 md:opacity-80">
              <EthosLogo />
              <CoBrandedX />
              <div className={styles.image}>
                {moduleData.supportingImage &&
                  moduleData.supportingImageAlt && (
                    <CloudinaryImage
                      publicId={moduleData.supportingImage}
                      alt={moduleData.supportingImageAlt}
                      className=""
                      crop={CloudinaryImage.CROP_METHODS.FILL}
                      height={[33, 33, 41, 41]}
                      width={[141, 141, 173, 173]}
                    />
                  )}
              </div>
            </div>
            <div className="hidden lg:block">
              <TitleXLarge2.Serif.Book500 color={COLORS.WHITE}>
                {moduleData.heading}
              </TitleXLarge2.Serif.Book500>
            </div>
          </div>
          <div
            className={
              'max-w-sm text-center lg:w-144 lg:max-w-none lg:pr-4 lg:text-left xl:pr-0'
            }
          >
            {moduleData.displaySubHeading && (
              <>
                <div className="mt-8 hidden md:block">
                  <TitleSmall2.Sans.Regular400 color={COLORS.WHITE}>
                    {moduleData.subHeading}
                  </TitleSmall2.Sans.Regular400>
                </div>
                <div className="block md:hidden">
                  <Body2.Regular400 color={COLORS.WHITE}>
                    {moduleData.subHeading}
                  </Body2.Regular400>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className={
            'max-w-full sm:max-w-sm md:z-1 md:max-w-md lg:pr-9 lg:pt-16'
          }
        >
          {formManager}
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 z-0 hidden overflow-hidden md:block lg:max-h-80">
        {moduleData.groupImage && moduleData.imageAlt && (
          <CloudinaryImage
            publicId={moduleData.groupImage}
            alt={moduleData.imageAlt}
            className=""
            crop={CloudinaryImage.CROP_METHODS.FILL}
            height={[0, 256, 480, 640]}
            width={[0, 768, 1440, 1920]}
          />
        )}
      </div>
    </div>
  </>
)

export default CoBranded
