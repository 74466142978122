import React from 'react'

import { COLORS, Caption2, Spacer } from 'ethos-design-system'
import CountUp from 'react-countup'

import { AVG_MONTHLY_DAYS } from '../../constants'

interface DailyDollarsProps {
  start: number
  end: number
}

/**
 * Displays an estimated daily dollars price.
 *
 * Countup is used for animation where the component goes from
 * minRangeStart to minRangeEnd.
 *
 * This price is hidden above $10/day.
 */
export const DailyDollars: React.FC<DailyDollarsProps> = ({ start, end }) => {
  // Determine daily price
  const dailyValueStart = start / AVG_MONTHLY_DAYS
  const dailyValueEnd = end / AVG_MONTHLY_DAYS

  // Only show decimals below $2
  const decimals = dailyValueEnd >= 2 ? 0 : 2

  const dollarValue = (
    <CountUp
      start={dailyValueStart}
      end={Math.max(0, dailyValueEnd)}
      decimals={decimals}
      duration={2}
      prefix={'$'}
    />
  )
  return (
    <div className="mt-5 flex w-full justify-center text-center">
      {dailyValueEnd > 10 ? (
        <Spacer.H32 />
      ) : (
        <div className="rounded-full bg-gray-1 px-3 py-2">
          <Caption2.Regular400 color={COLORS.GRAY_SECONDARY}>
            As little as {dollarValue} per day
          </Caption2.Regular400>
        </div>
      )}
    </div>
  )
}
