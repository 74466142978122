import { InputHTMLAttributes, ReactNode } from 'react'

import classnames from 'classnames'

import CheckboxIcon from './CheckboxIcon'
import styles from './CheckboxInput.module.scss'

export const inputSizes = {
  sm: 'eds2-p-[12px] eds2-gap-[8px]',
  md: 'eds2-gap-[12px]',
}

export const textSize = {
  sm: 'body-s-bold',
  md: 'body-m-med',
}

export const supportSize = {
  sm: 'body-s-reg',
  md: 'body-m-reg',
}

type InputSize = keyof typeof inputSizes

export interface CheckboxInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  content?: ReactNode
  label?: string
  inputSize: InputSize
  supportText?: string
}

const defaultStyles = `
  eds2-cursor-pointer
`

const checkedStyles = `
  eds2-cursor-pointer
  text-black
`

const disabledStyles = `
  eds2-text-[--theme-fg-on-disabled]  
  eds2-cursor-not-allowed
`

const CheckboxInput = ({
  content,
  label,
  inputSize,
  supportText,
  disabled = false,
  ...rest
}: CheckboxInputProps) => {
  const isChecked = Boolean(rest.checked)
  const checkboxStyles = disabled
    ? disabledStyles
    : isChecked
    ? checkedStyles
    : defaultStyles

  return (
    <label
      tabIndex={-1}
      className={classnames(
        'eds2-flex eds2-select-none eds2-border-[2px] eds2-rounded-[--radii-1] border-none px-0',
        supportText && !content ? 'eds2-items-start' : 'eds2-items-center',
        checkboxStyles,
        {
          [styles.checkboxContainer]: !disabled && !isChecked,
        },
        inputSizes[inputSize],
        styles.focusStyles
      )}
    >
      <div
        className={classnames(
          'eds2-flex eds2-col eds2-rounded-[--radii-4]',
          supportText && !content ? 'eds2-items-start' : 'eds2-items-center'
        )}
      >
        <input
          id={rest.id}
          type="checkbox"
          checked={isChecked}
          disabled={disabled}
          className="eds2-sr-only"
          {...rest}
        />
        <CheckboxIcon
          isChecked={isChecked}
          isDisabled={disabled}
          size={inputSize}
        />
      </div>
      <div className="eds2-w-full eds2-m-[-3px] eds2-flex eds2-flex-col eds2-items-start eds2-self-stretch eds2-gap-[8px]">
        {content && content}
        {!content && label && (
          <span className={classnames('eds2-w-full', textSize[inputSize])}>
            {label}
          </span>
        )}
        {!content && supportText && (
          <span className={classnames('eds2-w-full', supportSize[inputSize])}>
            {supportText}
          </span>
        )}
      </div>
    </label>
  )
}

export default CheckboxInput
