import { FC, useEffect } from 'react'

import { useSendAnalytics } from '@/shared/Estimate/useSendAnalytics'
import { Button, Input } from '@getethos/ethos-design-system-v2'
import { yupResolver } from '@hookform/resolvers/yup'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { Controller, useForm } from 'react-hook-form'

import { useEstimateWidgetContext } from '@/components/EstimateWidget/contexts/EstimateWidgetContext'
import { UserData } from '@/components/EstimateWidget/types/user'

import { GENDER_OPTIONS } from '../FinalExpenseInfoForm/utils/constants'
import styles from './DirectToAppInfoForm.module.scss'
import { ButtonGroup } from './components/ButtonGroup'
import CheckboxInput from './components/CheckboxInput/CheckboxInput'
import { ageMask, zipCodeMask } from './utils/masks'
import { validationSchema } from './utils/validation'

interface IDirectToAppInfoForm {
  onSubmit: (data: UserData) => void
}

type FormValues = {
  gender: string
  birthDate: string
  zipCode: string
  smoker?: boolean
}

const GENDER_OPTS = GENDER_OPTIONS.reverse().map((item) => ({
  copy: item.label,
  value: item.value,
}))

export const DirectToAppInfoForm: FC<IDirectToAppInfoForm> = ({ onSubmit }) => {
  const {
    userDataControls: { userData },
  } = useEstimateWidgetContext()

  const { sendAnalyticsEvent } = useSendAnalytics(estimateWidgetAnalytics)

  const {
    setValue,
    watch,
    handleSubmit,
    control,
    formState: { touchedFields, errors },
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      gender: userData.gender || 'Male',
      smoker: userData.smoker || false,
      birthDate: userData.birthDate || '',
      zipCode: userData.zipCode || '',
    },
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    mode: 'onBlur',
  })

  const values = watch()

  const arrayOfTouchFields = Object.keys(touchedFields)

  useEffect(() => {
    arrayOfTouchFields.forEach((field) => {
      if (!errors[field as keyof FormValues]) {
        const value = getValues()[field as keyof FormValues]
        sendAnalyticsEvent(field, value as string)
      }
    })
  }, [arrayOfTouchFields, errors, sendAnalyticsEvent, getValues])

  return (
    <div className="mx-auto mt-6 max-w-[420px] px-4 md:max-w-[500px] lg:mt-10">
      <h1 className={styles.header}>Get my instant estimate</h1>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit({
            ...data,
            smoker: data.smoker || false,
          })
        })}
        key={JSON.stringify(userData)}
      >
        <div className="flex flex-col gap-6">
          <ButtonGroup
            options={GENDER_OPTS}
            labelCopy="Gender"
            initialValue={values.gender}
            onChange={(value) => {
              setValue('gender', value, {
                shouldDirty: true,
                shouldTouch: true,
              })
            }}
          />
          <div className="flex gap-[13px]">
            <Controller
              control={control}
              name="birthDate"
              render={({
                field: { ref, ...fieldRest },
                fieldState: { error },
              }) => (
                <Input
                  {...fieldRest}
                  label="Age"
                  type="number"
                  mask={ageMask}
                  pattern="\d*"
                  errorMessage={error?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="zipCode"
              render={({
                field: { ref, ...fieldRest },
                fieldState: { error },
              }) => (
                <Input
                  {...fieldRest}
                  type="number"
                  label="Zip Code"
                  name="zipCode"
                  value={values.zipCode}
                  pattern="\d*"
                  mask={zipCodeMask}
                  errorMessage={error?.message}
                />
              )}
            />
          </div>
          <Controller
            control={control}
            name="smoker"
            render={({ field: { ref, value, ...fieldRest } }) => (
              <CheckboxInput
                {...fieldRest}
                label="I currently use nicotine products"
                inputSize="md"
                name="smoker"
                checked={value}
              />
            )}
          />
        </div>
        <Button
          buttonTitle="Calculate now"
          variant="salamander"
          type="submit"
          size="lg"
          className="mt-12"
        />
      </form>
    </div>
  )
}
