import { useEffect, useState } from 'react'

import isEmpty from 'lodash.isempty'
import qs from 'qs'

import { decryptData } from '../../../lib/@getethos/api/api'
import { FeatureFlagConfig, UserData } from '../../../lib/@getethos/interfaces'
import { getEncryptionSource } from '../../../lib/@getethos/urls/utils'
import { mapQueryToUserData } from '../../../lib/@getethos/utils/mapQueryToUserData'
import { initialize as initializeCustomerIo } from '../../../src/components/global/CustomerIo'

// health does not affect the estimate rate and does not map nicely from quote engines
// making it optional for prefill
const requiredFields = ['birthDate', 'gender', 'smoker', 'zipCode']

/**
 * Determines if all required fields are present
 */
const isUserDataComplete = (
  userData: Partial<UserData> | null,
  useLean?: boolean
): boolean => {
  if (!userData) {
    return false
  }

  if (useLean) {
    if (typeof userData.smoker === 'undefined') {
      userData.smoker = 'false'
    } else if (userData.smoker === true) {
      userData.smoker = 'true'
    }
  }

  for (const field of requiredFields) {
    if (!userData[field as keyof UserData]) {
      return false
    }
  }

  return true
}

interface HookReturn {
  userData: Partial<UserData> | null
  isUserDataComplete: boolean
  setUserData: (userData: any) => void
  clearUserData: () => void
}

export const useUserData = (
  analytics: any,
  queryParams: any,
  featureFlagConfig?: FeatureFlagConfig,
  apiUrl?: string
): HookReturn => {
  const queryParamData = mapQueryToUserData(queryParams)
  const readyToRender =
    typeof window !== 'undefined'
      ? !Object.keys(queryParams).includes('martech_data')
      : false
  const initialData = isEmpty(queryParamData)
    ? { readyToRender }
    : { ...queryParamData, readyToRender }
  // user data entered in the first screen
  const [userData, setUserData] = useState(initialData)

  const [analyticsFired, setAnalyticsFired] = useState(false)

  const clearUserData = (): void => {
    setUserData({ readyToRender: true })
  }

  const priceElasticityEnabled =
    featureFlagConfig && featureFlagConfig.usePriceElasticity

  useEffect(() => {
    if (!userData.readyToRender) {
      const source = getEncryptionSource(queryParams)
      const encryptedData = queryParams['martech_data'] ?? ''
      if (encryptedData && !isUserDataComplete(userData)) {
        decryptData(apiUrl || '', encryptedData, source).then((res) => {
          const parsedDecryptedData = mapQueryToUserData(qs.parse(res))
          setUserData({
            ...userData,
            ...parsedDecryptedData,
            readyToRender: true,
          })
        })
      } else {
        setUserData({
          ...queryParamData,
          ...userData,
          readyToRender: true,
        })
      }
    }
  }, [apiUrl, queryParamData, queryParams, userData])

  useEffect(() => {
    // fire analytics and create a customer
    if (userData != null) {
      if (!analyticsFired) {
        initializeCustomerIo()
        setAnalyticsFired(true)
      }
    }
  }, [userData, analyticsFired, analytics, priceElasticityEnabled])

  return {
    userData,
    isUserDataComplete: isUserDataComplete(
      userData,
      featureFlagConfig && featureFlagConfig.useLean
    ),
    setUserData,
    clearUserData,
  }
}
