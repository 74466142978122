import { useEffect, useState } from 'react'

import { INVALID_STATES, STATE_ERROR } from '@/constants'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { convertAgeToBirthDateISO } from 'lib/@getethos/api/api'
import {
  CreditScore,
  Product,
  QuoteFunnelCreditScore,
} from 'lib/@getethos/constants'
import { UserData } from 'lib/@getethos/interfaces'
import { apiRoutes } from 'lib/@getethos/urls/apiRoutes'
import { browserLogger } from 'lib/Logger/browserLogs'

import { EstimateWidgetProgress } from '../types/widget'

type Coverage = {
  coverage: number
  term: number
}

interface IUseFetchQuotes {
  userSelectedValues: Coverage | null
  product: Product
  region: string | null
  userData: UserData
  progress: EstimateWidgetProgress
  hasFetchedInitialQuotes: boolean
  setError: (error: string) => void
  setHasFetchedInitialQuotes: (initialFetch: boolean) => void
  ignoreFetch?: boolean
}

interface Fetcher {
  userData: UserData
  coverageData: Coverage
  region: string
  product: Product
}

const getBirthDateISO = (birthDate?: string) => {
  if (!birthDate) return null
  return !Number.isNaN(Number(birthDate))
    ? convertAgeToBirthDateISO(birthDate || '').substring(0, 10)
    : new Date(birthDate || '').toISOString().substring(0, 10)
}

const getParamsForQuotes = (
  userData: UserData,
  userValues: Coverage | null,
  region: string,
  product: Product
) => {
  const birthDateISO = getBirthDateISO(userData?.birthDate)

  let quoteFunnelCreditScore
  switch (userData?.estimatedCredit) {
    case CreditScore.Average:
      quoteFunnelCreditScore = QuoteFunnelCreditScore.Average
      break
    case CreditScore.Low:
      quoteFunnelCreditScore = QuoteFunnelCreditScore.Low
      break
    case CreditScore.High:
    case CreditScore.NoAnswer:
    default:
      quoteFunnelCreditScore = QuoteFunnelCreditScore.High
      break
  }
  if (!birthDateISO || !userValues?.coverage || !userValues?.term) return null
  return {
    gender: userData.gender as string,
    birthDate: birthDateISO,
    region: region == null ? 'AL' : region,
    targetAmount: userValues?.coverage,
    creditScore: quoteFunnelCreditScore,
    term: userValues?.term,
    tobacco: !!userData?.smoker,
    product,
  }
}
const fetchQuotes = async ({
  userData,
  region,
  coverageData,
  product,
}: Fetcher) => {
  const params = getParamsForQuotes(userData, coverageData, region, product)
  if (!params) throw new Error('Invalid params')

  try {
    const response = await apiRoutes.quotes(params)
    return response
  } catch (error: any) {
    throw new Error(error)
  }
}

export const useFetchQuotes = ({
  userSelectedValues,
  product,
  region,
  userData,
  progress,
  hasFetchedInitialQuotes,
  setError,
  setHasFetchedInitialQuotes,
  ignoreFetch,
}: IUseFetchQuotes) => {
  const [ranges, setRanges] = useState({ min: 0, max: 0 })
  const [previousRanges, setPreviousRanges] = useState({ min: 0, max: 0 })

  const fetcher = async (hasFetchedInitialQuotes?: boolean) => {
    if (region && INVALID_STATES.includes(region)) {
      setError(STATE_ERROR)
      return
    }
    if (!userSelectedValues?.coverage || !userSelectedValues?.term || !region)
      return
    try {
      const response = await fetchQuotes({
        userData,
        coverageData: userSelectedValues!,
        region,
        product,
      })

      if (response?.error) {
        browserLogger.error(
          '[useFetchQuotes] - Error fetching quotes',
          {
            response,
          },
          response?.error
        )
        setError(
          'Something went wrong on our end! Please try again or contact us for assistance.'
        )
        return
      }

      browserLogger.info('[useFetchQuotes] - Quotes fetched', {
        response,
      })

      setPreviousRanges({
        min: ranges.min,
        max: ranges.max,
      })
      setRanges({
        min: response?.minPrice,
        max: response?.maxPrice,
      })

      if (!hasFetchedInitialQuotes) {
        estimateWidgetAnalytics.initialQuotes({
          properties: {
            coverage: userSelectedValues?.coverage,
            term: userSelectedValues?.term,
            minPrice: response?.minPrice,
            maxPrice: response?.maxPrice,
          },
        })
      }
    } catch (error) {
      console.error(`Error fetching quotes: ${error}`)
    }
  }

  useEffect(() => {
    if (
      progress === EstimateWidgetProgress.INFO ||
      !userSelectedValues?.coverage ||
      !userSelectedValues?.term ||
      !region ||
      ignoreFetch
    )
      return
    if (!hasFetchedInitialQuotes) {
      fetcher(hasFetchedInitialQuotes)
      setHasFetchedInitialQuotes(true)
      return
    }
    const timeout = setTimeout(() => {
      fetcher(hasFetchedInitialQuotes)
    }, 500)

    return () => clearTimeout(timeout)
  }, [
    userSelectedValues?.coverage,
    userSelectedValues?.term,
    progress,
    hasFetchedInitialQuotes,
    region,
    ignoreFetch,
  ])

  return { ranges, previousRanges }
}
