import { useEffect } from 'react'

import { useRouter } from 'next/router'

import Cookies from 'js-cookie'
import { BEST_MONEY_COBRANDED_HEADER } from 'lib/@getethos/experiments/feature_constants'
import { useFeatureVariable } from 'lib/FeaturesAndExperiments/getFeatureVariable'

export const useBestMoneyHeader = (): {
  isEnabled: boolean
  isLoading: boolean
} => {
  const { asPath } = useRouter()

  const pathnameWithoutQuery = asPath.split('?')[0]
  const isValidAudiencePath =
    BEST_MONEY_COBRANDED_HEADER.audiences?.includes(pathnameWithoutQuery)

  const {
    variables: { isEnabled },
    isLoading,
  } = useFeatureVariable(BEST_MONEY_COBRANDED_HEADER.name, !isValidAudiencePath)

  if (isEnabled) {
    Cookies.set(
      BEST_MONEY_COBRANDED_HEADER.cookieName,
      BEST_MONEY_COBRANDED_HEADER.variations.on,
      {
        expires: 365,
      }
    )
  }

  return { isEnabled, isLoading }
}
