import get from 'lodash.get'
import qs from 'qs'

/**
 * Sets up a closure that records the current count of modules by type.
 * Returns a function that gives a module id
 * @return {function(*): string}
 */
//eslint-disable-next-line
export const getModuleIdClosure = (): ((type: string) => string) => {
  const countsByType: any = {}

  /**
   * Given a type, returns the a string of module id based on the type
   * and the current count (i.e. QuoteWidget-2)
   * @param {module.type} type
   */
  return (type: string): string => {
    // Updates the count by type if it exists
    if (countsByType[type]) {
      countsByType[type] = countsByType[type] + 1
    } else {
      countsByType[type] = 1
    }

    return `${type}-${countsByType[type]}`
  }
}

/**
 * Changes a number to a nicely formatted dollars, with thousand separators. Optional decimals.
 * (e.g. 100.35487 => $100.35 or 143.348 => $143)
 * @param n {number|string}
 * @param decimal {number=2} - Number of decimals to use (defaults to 2)
 * @return {string}
 */
export const numberToDollars = (n: any, decimal = 2): string => {
  // If string, and not empty string, convert to a number
  if (typeof n === 'string' && n !== '') {
    n = Number(n)
  }

  // If null, undefined, NaN, Infinity, return empty string
  if (!Number.isFinite(n)) {
    return ''
  }

  const isNegative = n < 0
  if (isNegative) {
    n *= -1
  }

  return `${isNegative ? '-' : ''}$${n
    .toFixed(decimal)
    .replace(/./g, (c: any, i: any, a: any) =>
      i && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c
    )}`
}

/**
 * Determines if an age is within the min and max age inclusive.
 * isWithinRange(age, 20, 50) will return true if the user is 20 or 50
 */
export const isWithinRange = (
  age: number,
  min: number,
  max: number
): boolean => {
  return age >= min && age <= max
}

/**
 * Returns the query string associated with the current page
 */
export const getQueryString = (): Record<string, any> | undefined => {
  if (typeof window === 'undefined') {
    return
  }

  const queryString = window.location.search.substring(1)
  // returns query in object form
  return qs.parse(queryString)
}

/**
 * Extracts data if query param is from an affiliate such as Impact Radius
 */
export const parseAffiliateQueryParam = (
  affiliateQueryParam: object,
  affiliateQueryParamKey: string
): object => {
  const affiliateQueryParamValue = get(
    affiliateQueryParam,
    affiliateQueryParamKey
  )
  const allParams = affiliateQueryParamValue.split('_')
  return allParams.reduce((accumulatedValue: object, currentValue: string) => {
    const [key, value] = currentValue.split('=')
    //@ts-ignore
    accumulatedValue[key] = value
    return accumulatedValue
  }, {})
}
