interface useShouldHideModuleProps {
  isTreatment: boolean
  isLoading: boolean
  pageKeys: Record<any, any>
  expKey: string
  demoKey?: string
}

export const useShouldHideModule = ({
  isTreatment,
  isLoading,
  pageKeys,
  expKey,
  demoKey = 'demo',
}: useShouldHideModuleProps) => {
  // Show all sections used for demo.
  if (expKey === demoKey) {
    return false
  }

  return isTreatment && !isLoading
    ? expKey?.includes(pageKeys.control)
    : expKey?.includes(pageKeys.treatment)
}
