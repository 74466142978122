import {
  ButtonSelectGroup,
  NumberInput,
  OPTION_BUTTON_STYLES,
  ZipInput,
} from 'ethos-design-system'

import styles from './FormComponents.module.scss'

interface FormProps {
  name: string
  currentValue: string | boolean | undefined
  onChange: (...event: any[]) => void
}

export const getButtonSelectGroup = (
  formProps: FormProps,
  options = {} as any
) => {
  const { values, renderAsColumn = true } = options
  const { onChange, name, currentValue } = formProps

  return (
    <div className={styles.buttonSelectGroup}>
      <ButtonSelectGroup
        labelCopy=""
        column={renderAsColumn}
        name={name}
        initialValue={currentValue}
        currentValue={currentValue}
        buttonStyle={OPTION_BUTTON_STYLES.WHITE}
        onSelect={(value: Record<string, unknown>) => {
          if (!currentValue) return
          onChange(value?.value)
        }}
      >
        {values.map((x: Record<string, unknown>) => (
          <ButtonSelectGroup.Option
            value={x.value}
            key={x.value}
            onClick={() => {
              if (currentValue) return
              onChange(x.value)
            }}
          >
            {x.copy}
          </ButtonSelectGroup.Option>
        ))}
      </ButtonSelectGroup>
    </div>
  )
}

export const getNumberInputComponent = (
  formProps: FormProps,
  options = {} as any
) => {
  const { placeholder, maxLength, disabled, mask } = options
  const { onChange, name, currentValue } = formProps
  return (
    <>
      <NumberInput
        labelCopy={''}
        placeholder={placeholder}
        mask={mask}
        maxLength={maxLength}
        initialValue={currentValue}
        disabled={disabled}
        formChangeHandler={(value: unknown) => {
          onChange(value)
        }}
        currentValue={currentValue}
        name={name}
      />
    </>
  )
}

export const getZipInputComponent = (
  formProps: FormProps,
  options = {} as any
) => {
  const { placeholder, prefill, disabled, mask } = options
  const { onChange, name, currentValue } = formProps
  return (
    <>
      <ZipInput
        labelCopy={''}
        initialValue={prefill}
        disabled={disabled}
        // @ts-ignore
        currentValue={currentValue}
        formChangeHandler={(value: unknown) => {
          onChange(value)
        }}
        name={name}
      />
    </>
  )
}
