import React from 'react'

import { TitleSmall2 } from 'ethos-design-system'

import Markdown from '../../global/Markdown'

export const Subheading = (props: {
  children: any
  className?: string
  color?: string
  displaySubHeading?: boolean
}): JSX.Element =>
  props.children && props.displaySubHeading ? (
    <div className={props.className}>
      <TitleSmall2.Sans.Regular400 color={props.color} element="h2">
        <Markdown input={props.children} />
      </TitleSmall2.Sans.Regular400>
    </div>
  ) : (
    <></>
  )
