import { ArrowLeft } from '@/icons'
import { PriceRangeDisplay } from '@/shared/Estimate/PriceRangeDisplay'
import { Button, Dropdown } from '@getethos/ethos-design-system-v2'

import { EstimateWidgetModuleData } from '@/components/EstimateWidget/EstimateWidget'
import { useEstimateQuotesContext } from '@/components/EstimateWidget/contexts/EstimateQuotesContext'
import { useEstimateWidgetContext } from '@/components/EstimateWidget/contexts/EstimateWidgetContext'

import styles from './FinalExpenseRangeForm.module.scss'
import { getAvailableOptions } from './helpers/getAvailableOptions'

export const FinalExpenseRangeForm = ({
  moduleData,
}: {
  moduleData: EstimateWidgetModuleData
}) => {
  const {
    userDataControls: { userData },
    widgetProgressControls: { editAnswers },
  } = useEstimateWidgetContext()

  const {
    userSelectedValues,
    handleCoverageChange,
    previousRanges,
    ranges,
    handleGoToMainApp,
  } = useEstimateQuotesContext()
  const options = getAvailableOptions({ age: Number(userData.birthDate) })

  const handleClick = () =>
    handleGoToMainApp({
      flow: 'final-expense',
    })

  return (
    <div className={styles.container}>
      <p className="body-l-bold m-0 mb-8 !leading-3 text-[rgba(5,89,79,1)] md:mb-12">
        YOUR ESTIMATE
      </p>
      <div className="mb-8">
        <p className="display-l-med m-0 !leading-[19px] text-[rgba(82,82,82,1)]">
          Starting from
        </p>
        <PriceRangeDisplay
          styles={styles}
          minRangeStart={previousRanges.min}
          minRangeEnd={ranges.min}
          maxRangeStart={previousRanges.max}
          maxRangeEnd={ranges.max}
          finalExpense
        />
        <span className="body-m-reg">per month</span>
      </div>
      <div className={styles.dropdownWrapper}>
        <Dropdown
          label="Coverage (tax free)"
          isSearchable={false}
          menuShouldScrollIntoView
          onChange={(options) => handleCoverageChange(options?.value as number)}
          options={options}
          value={options.find(
            (option) => option.value === userSelectedValues?.coverage
          )}
        />
        <p className="body-m-reg mb-0 mt-3 !leading-[11px]">
          Select that amount that fits your budget
        </p>
      </div>
      <Button
        className={styles.button}
        buttonTitle="GET MY FINAL RATE"
        type="button"
        variant="salamander"
        onClick={handleClick}
        size="lg"
        leadingIcon="arrowForward"
      />
      <button className={styles.backButton} onClick={editAnswers}>
        <span>Back</span>
        <ArrowLeft />
      </button>
    </div>
  )
}
