import { useRouter } from 'next/router'

import Cookies from 'js-cookie'

import { useGetQueryParam } from '../useGetQueryParam'

const audiencePaths = ['/life/quick-online-quote/']

const cookie = {
  name: 'FORBES-USA-TODAY-NAVBAR',
  value: 'on',
  expires: 365,
}

export const useForbesUsaTodayNavbar = () => {
  const { asPath } = useRouter()
  const queryParams = useGetQueryParam('utm_term')

  const pathnameWithoutQuery = asPath.split('?')[0]
  const isValidAudiencePath = audiencePaths.includes(pathnameWithoutQuery)

  const isForbesUsaTodayTerm = queryParams === '4012387'

  const isEnabled = isValidAudiencePath && isForbesUsaTodayTerm

  if (isEnabled) {
    // cookie used by PreInterview
    Cookies.set(cookie.name, cookie.value, {
      expires: cookie.expires,
    })
  }

  return isEnabled
}
