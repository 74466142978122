import React from 'react'

import { Body, COLORS, CloudinaryImage, TitleLarge } from 'ethos-design-system'

import { LayoutProps } from './index'

const MultiImage = ({
  updateScrollNode,
  moduleData,
  formManager,
}: LayoutProps): JSX.Element => (
  <>
    <div className="flex w-full flex-col items-center bg-buttercup px-2">
      <div className="flex w-full max-w-screen-xl flex-col items-center">
        <div className="w-5/6 pt-6 text-center sm:pt-10 md:w-2/3">
          <TitleLarge.Serif.Book500 color={COLORS.GRAY_PRIMARY}>
            {moduleData.heading}
          </TitleLarge.Serif.Book500>
        </div>
        <div className="w-5/6 pb-4 pt-4 text-center sm:pt-8 md:w-7/12 lg:pb-8">
          <Body.Regular400 color={COLORS.GRAY_PRIMARY}>
            {moduleData.subHeading}
          </Body.Regular400>
        </div>
      </div>
      <div
        className="w-full pt-2 sm:flex sm:justify-between sm:pt-6"
        ref={updateScrollNode}
      >
        <div className="relative hidden flex-1 sm:block">
          <div className="absolute right-0 w-120 translate-y-14 transform">
            {moduleData.supportingImage && moduleData.supportingImageAlt && (
              <CloudinaryImage
                publicId={moduleData.supportingImage}
                alt={moduleData.supportingImageAlt}
                className=""
                crop={CloudinaryImage.CROP_METHODS.FIT}
                height={[0, 376, 376, 376]}
                width={[0, 480, 480, 480]}
              />
            )}
          </div>
        </div>
        <div className="floatingContainer flex w-full justify-center pb-14 sm:w-auto sm:flex-shrink-0 sm:px-10 sm:pb-0">
          {formManager}
        </div>
        <div className="relative hidden flex-1 sm:block">
          <div className="absolute left-0 w-120 translate-y-14 transform">
            {moduleData.groupImage && moduleData.imageAlt && (
              <CloudinaryImage
                publicId={moduleData.groupImage}
                alt={moduleData.imageAlt}
                className=""
                crop={CloudinaryImage.CROP_METHODS.FILL}
                height={[0, 376, 376, 376]}
                width={[0, 480, 480, 480]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </>
)

export default MultiImage
