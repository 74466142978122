import { WILLS_LP_V2_2 } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useWillsLPV2_2Experiment = () => {
  const { isLoading, variation } = useDecisionForFlag({
    name: WILLS_LP_V2_2.EXPERIMENT_KEY,
    fallbackVariation: WILLS_LP_V2_2.VARIATIONS.CONTROL,
  })

  return {
    isLoading,
    isTreatment1: variation === WILLS_LP_V2_2.VARIATIONS.TREATMENT_1,
    isOnTreatment: variation === WILLS_LP_V2_2.VARIATIONS.TREATMENT_1,
    pageKeys: WILLS_LP_V2_2.PAGE_VALUES,
  }
}
