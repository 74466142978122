import { EW_COPY } from 'lib/@getethos/experiments/feature_constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useEwCopy = () => {
  const { isLoading, variation } = useDecisionForFlag({
    name: EW_COPY.name,
    fallbackVariation: EW_COPY.variations.off,
  })

  const copy = {
    TREATMENT_1: {
      headline_1: 'Instant Estimate',
      cta_1: 'See my estimate ',
      headline_2: 'Your estimate',
      cta_2: 'Get my final rate',
    },
    TREATMENT_2: {
      headline_1: 'Instant Estimate',
      cta_1: 'See my estimate',
      headline_2: 'Your estimate',
      cta_2: 'Lock in my rate',
    },
    TREATMENT_3: {
      headline_1: 'Instant Estimate',
      cta_1: 'Calculate my estimate',
      headline_2: 'Your estimate',
      cta_2: 'Get my final rate',
    },
    TREATMENT_4: {
      headline_1: 'Instant Estimate',
      cta_1: 'Calculate now',
      headline_2: 'Your estimate',
      cta_2: 'Get my final rate',
    },
  }

  return {
    isLoading,
    copy,
    isTreatment1: variation === EW_COPY.variations.curiosity,
    isTreatment2: variation === EW_COPY.variations.curiosity_fear,
    isTreatment3: variation === EW_COPY.variations.doing_work,
    isTreatment4: variation === EW_COPY.variations.urgency,
  }
}
