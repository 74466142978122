import { useCallback, useRef } from 'react'

import { useModuleContext } from '@/contexts/ModuleContext'
import { StorageKeys, Store } from '@/services/storage'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { createLead } from 'lib/@getethos/api/createLead'

import { useEstimateQuotesContext } from '@/components/EstimateWidget/contexts/EstimateQuotesContext'
import { useEstimateWidgetContext } from '@/components/EstimateWidget/contexts/EstimateWidgetContext'
import { EstimateWidgetProgress } from '@/components/EstimateWidget/types/widget'

import { useUpdatedCpFeLp } from '@/hooks/features/useUpdatedCpFeLp'
import useIsCentralTimeWeekdayBetween9And5 from '@/hooks/useIsCentralTimeWeekdayBetween9And5'

import LeadForm from './LeadForm'
import {
  LeadFormInputFields,
  LeadSource,
  prepareDataForSalesForce,
} from './utils'

export const LeadFormWrapper = () => {
  const { isTreatment1, isTreatment2 } = useUpdatedCpFeLp()
  const touchedFieldsRef = useRef<Array<string>>([])

  const store = new Store('localStorage')
  const isCentralTimeWeekdayBetween9And5 = useIsCentralTimeWeekdayBetween9And5()

  const {
    userDataControls: { setUserData },
    widgetProgressControls: { setWidgetProgress },
  } = useEstimateWidgetContext()
  const { handleGoToMainApp } = useEstimateQuotesContext()
  const {
    moduleControls: { setHideModules },
    footerControls: { hideFooter },
  } = useModuleContext()

  const handleCPToSalesSubmit = () => {
    setHideModules((module) => module.onePageCandidate)
    hideFooter()
    setWidgetProgress(EstimateWidgetProgress.SALES)
    estimateWidgetAnalytics.continueToSales({
      properties: {
        triedToEditAnswers: false,
      },
    })
  }

  const handleSubmit = async (values: LeadFormInputFields) => {
    if (values.primaryAddress.region === 'NY') {
      window.location.assign(`/life/youre-in-new-york/`)
      return
    }

    const formType = isTreatment2
      ? LeadSource.CP_WITH_SALES
      : LeadSource.CP_WITHOUT_QUOTE

    const data = prepareDataForSalesForce(values, formType)

    const wasLeadFormSubmitted = store.getItem<boolean>(
      StorageKeys.LeadFormSubmitted
    )

    setUserData({ ...values })

    estimateWidgetAnalytics.leadFormSubmitted({
      properties: {
        formType,
        triedToEditAnswers: false,
      },
    })
    try {
      if (wasLeadFormSubmitted) {
        console.warn('Lead form already submitted')
        return
      }
      await createLead({ data })
      store.setItem(StorageKeys.LeadFormSubmitted, true)
    } catch (error) {
      console.error('Error submitting form', error) // TODO: we need DD logs
    } finally {
      if (isTreatment1) {
        handleGoToMainApp({
          flow: 'final-expense',
          prefillValues: values,
        })
      }
      if (isTreatment2) {
        if (isCentralTimeWeekdayBetween9And5) {
          handleCPToSalesSubmit()
        } else {
          // If already in treatment and outside of working hours we go to main app
          handleGoToMainApp({
            flow: 'final-expense',
            prefillValues: values,
          })
        }
      }
    }
  }

  const handleOnBlur = useCallback((field: string) => {
    if (touchedFieldsRef.current.includes(field)) return
    estimateWidgetAnalytics.userInteractedWithField({
      properties: {
        field,
        context: 'lead-form',
      },
    })
    touchedFieldsRef.current.push(field)
  }, [])

  return (
    <LeadForm
      onSubmit={handleSubmit}
      title="Guaranteed coverage*"
      subtitle="This is coverage lasts your whole life. You can't be turned down due to your health, and your rate will never go up!"
      ctaLabel="CONTINUE"
      handleOnBlur={handleOnBlur}
    />
  )
}
