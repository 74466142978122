//@ts-nocheck
import { useEffect, useState } from 'react'

import {
  getStateFromGoogleApi,
  useGoogleMaps,
} from '../../../lib/GoogleMapsApi/utils'

interface HookReturn {
  region: string | null
}

export const useRegionFromZipCode = (
  analytics: any,
  zipCode?: string
): HookReturn => {
  const [region, setRegion] = useState<string | null>(null)
  const [regionState, setRegionState] = useState({})

  useGoogleMaps()

  useEffect((): void => {
    // Fetches the state from Google API
    async function fetchStateFromZipCallback(): Promise<void> {
      if (!zipCode) {
        return
      }

      let updatedRegion
      // Check to see if Region has already been fetched
      if (regionState[zipCode]) {
        updatedRegion = regionState[zipCode]
      } else {
        // Fetch the state from the Google Maps API
        const stateFromZip = await getStateFromGoogleApi(zipCode, analytics)

        // Put region into state to prevent a second call
        setRegionState((regionState) => ({
          ...regionState,
          [zipCode]: stateFromZip,
        }))

        updatedRegion = stateFromZip
      }

      setRegion(updatedRegion)
    }

    fetchStateFromZipCallback()
  }, [zipCode, region, regionState, analytics])

  return { region }
}
