export enum EstimateWidgetVariations {
  HERO = 'hero',
  BOXED = 'boxed',
  FLOATING_LEAN = 'floating_lean',
}

export enum PriceCompetitiveness {
  ORIGINAL = 'original',
  SINGLE_PRICE = 'singlePrice',
}
