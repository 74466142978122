import React, { type SVGProps } from 'react'

export default function Checkmark(
  props: React.PropsWithChildren<SVGProps<SVGSVGElement>>
) {
  return (
    <svg
      width="18"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.6 10.6 1.4 6.4 0 7.8l5.6 5.6 12-12L16.2 0 5.6 10.6Z"
        fill={props.fill || '#FA640A'}
      />
    </svg>
  )
}
