import { useRouter } from 'next/router'

import { SOFI_DEMO_LANDING_PAGE_URL, SOFI_LANDING_PAGE_URL } from '@/constants'
import Cookies from 'js-cookie'

export const useIsSofi = () => {
  const { asPath } = useRouter()

  const pathWithoutQuery = asPath.split('?')[0]
  const isSofiPage =
    pathWithoutQuery === SOFI_DEMO_LANDING_PAGE_URL ||
    pathWithoutQuery === SOFI_LANDING_PAGE_URL

  if (isSofiPage) {
    // cookie used by PreInterview
    Cookies.set('SOFI-HEADER-FEATURE', 'on', {
      expires: 365,
    })
  }

  return isSofiPage
}
