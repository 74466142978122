import React, { ReactNode, useCallback, useEffect, useState } from 'react'

import {
  Body,
  Body2,
  Button,
  Caption2,
  TitleLarge2,
  TitleMedium2,
} from 'ethos-design-system'

import { Product } from '../../../../lib/@getethos/constants'
import { COVERAGE_SLIDER } from '../../../constants'
import { PriceRangeDisplay } from '../../../shared/Estimate/PriceRangeDisplay'
import { CoverageSlider, TermSlider } from '../../../shared/Estimate/Sliders'
import { FinalExpenseCoverageSlider } from '../common/FinalExpenseCoverageSlider'
import { DATA_TID } from '../common/data-tid'
import { EstimateWidgetVariations } from '../common/variations'

const saveIcon = (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6.625V7.875V15.875H14V7.875V6.625H15.25V17.125H0.75V6.625H2ZM8.63449 2.39277V11.875H7.38429V2.39277L4.91529 4.86136L4.03127 3.97748L8.00939 0L11.9875 3.97748L11.1035 4.86136L8.63449 2.39277Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
)

const FormButtons = ({
  styles,
  openEmailModal,
  saveLabel,
  handleNavigateToApp,
  continueLabel,
}: any) => {
  return (
    <div className={styles.buttons}>
      <Button.Medium.BlackOutline onClick={openEmailModal}>
        {saveLabel}
      </Button.Medium.BlackOutline>
      <Button.Unstyled onClick={openEmailModal}>
        {saveIcon}
        {saveLabel}
      </Button.Unstyled>
      <Button.Medium.Black
        arrowIcon
        data-tid={DATA_TID.SUBMIT_BUTTON2}
        type="submit"
        onClick={handleNavigateToApp}
      >
        {continueLabel}
      </Button.Medium.Black>
    </div>
  )
}

interface HeroBoxedRangeFormProps {
  styles: any
  showError: boolean
  editAnswers: () => void
  heading: string
  displaySubHeading: string
  subHeading: string
  estimateCopy: ReactNode
  toggleSlider: ({
    newCoverage,
    newTerm,
  }: {
    newCoverage: number
    newTerm: number
  }) => void
  coverage: number
  term: number
  terms: number[]
  ranges: any
  prevRanges: any
  coverageLabel: string
  maxCoverage: number
  product: string
  termLabel: string
  backgroundColor: string
  variation: string
  disclaimerText: string
  openEmailModal: () => void
  saveLabel: string
  handleNavigateToApp: () => void
  continueLabel: string
}

export const HeroBoxedRangeForm = ({
  styles,
  showError,
  editAnswers,
  heading,
  displaySubHeading,
  subHeading,
  estimateCopy,
  toggleSlider,
  coverage,
  term,
  terms,
  ranges,
  prevRanges,
  coverageLabel,
  maxCoverage,
  product,
  termLabel,
  backgroundColor,
  variation,
  disclaimerText,
  openEmailModal,
  saveLabel,
  handleNavigateToApp,
  continueLabel,
}: HeroBoxedRangeFormProps) => {
  const [genericCoverage, setGenericCoverage] = useState(
    coverage >= COVERAGE_SLIDER.MIN && coverage <= maxCoverage
      ? coverage
      : COVERAGE_SLIDER.MIN
  )

  const termChange = (event: any): void => {
    const closest = terms.reduce(function (prev, curr) {
      return Math.abs(curr - event) < Math.abs(prev - event) ? curr : prev
    })

    toggleSlider({ newCoverage: coverage, newTerm: closest })
  }

  const handleGenericCoverageChange = useCallback(
    (event: any) => {
      setGenericCoverage(event)
      toggleSlider({ newCoverage: event, newTerm: term })
    },
    [term, toggleSlider]
  )

  useEffect(() => {
    if (genericCoverage !== coverage && product !== Product.FE) {
      handleGenericCoverageChange(genericCoverage)
    }
  }, [coverage, genericCoverage, handleGenericCoverageChange, product])

  const subHeadingClass = displaySubHeading ? styles.subHeading : 'hidden'

  const buttonProps = {
    styles,
    openEmailModal,
    saveLabel,
    handleNavigateToApp,
    continueLabel,
  }
  return (
    <div className={styles.rangeForm}>
      <div className={styles.rangeFormInner}>
        {showError ? (
          <>
            <div className={styles.editAnswers}>
              <Button.Unstyled onClick={editAnswers}>
                <Caption2.Regular400>← Edit answers</Caption2.Regular400>
              </Button.Unstyled>
            </div>
            <Body2.Regular400>{showError}</Body2.Regular400>
          </>
        ) : (
          <>
            <div className={styles.copy}>
              <TitleMedium2.Serif.Book500>{heading}</TitleMedium2.Serif.Book500>
              <div className={subHeadingClass}>
                <Body.Regular400>{subHeading}</Body.Regular400>
              </div>
            </div>
            <div className={styles.rangeFormFieldsContainer}>
              <div className={styles.rangeFormFields}>
                <div className={styles.prices}>
                  <div className={styles.editAnswers}>
                    <Button.Unstyled onClick={editAnswers}>
                      <Caption2.Regular400>← Edit answers</Caption2.Regular400>
                    </Button.Unstyled>
                  </div>
                  <h3 className={styles.estimateCopy}>
                    <TitleLarge2.Sans.Regular400>
                      {estimateCopy}
                    </TitleLarge2.Sans.Regular400>
                  </h3>
                  <PriceRangeDisplay
                    styles={styles}
                    minRangeStart={prevRanges.min}
                    minRangeEnd={ranges.min}
                    maxRangeStart={prevRanges.max}
                    maxRangeEnd={ranges.max}
                  />
                </div>
                {product === Product.FE && (
                  <FinalExpenseCoverageSlider
                    coverageLabel={coverageLabel}
                    styles={styles}
                    variation={variation}
                    termLabel={termLabel}
                    coverage={coverage}
                    maxCoverage={maxCoverage}
                    handleCoverageChange={(newCoverage): void =>
                      toggleSlider({ newCoverage, newTerm: term })
                    }
                  />
                )}

                {product !== Product.GI && product !== Product.FE && (
                  <div className={styles.sliders}>
                    <CoverageSlider
                      coverageLabel={coverageLabel}
                      coverage={genericCoverage}
                      minCoverage={COVERAGE_SLIDER.MIN}
                      maxCoverage={maxCoverage}
                      coverageStep={COVERAGE_SLIDER.STEP}
                      handleCoverageChange={handleGenericCoverageChange}
                      dataTid={DATA_TID.COVERAGE_INPUT}
                      styles={styles}
                    />
                    <TermSlider
                      termLabel={termLabel}
                      term={term}
                      minTerm={terms[0]}
                      maxTerm={terms[terms.length - 1]}
                      handleTermChange={termChange}
                      dataTid={DATA_TID.TERM_INPUT}
                      availableTerms={terms}
                      backgroundColor={backgroundColor}
                      styles={styles}
                    />
                  </div>
                )}
              </div>
              {variation === EstimateWidgetVariations.HERO ? (
                <div className={styles.heroButtonContainer}>
                  <div className={styles.disclaimer}>
                    <Caption2.Regular400>{disclaimerText}</Caption2.Regular400>
                  </div>
                  <FormButtons {...buttonProps} />
                </div>
              ) : (
                <FormButtons {...buttonProps} />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
