import { NAVBAR_HEIGHT } from '../../../constants'

/**
 * @param ref node to scroll to top of
 * @returns function callable "scrollToTop" bound to ref
 */
export function scrollToTop(ref: Element): () => void {
  return (): void => {
    // ref may not exists if the header is not there such as mono-template
    if (ref) {
      const { top } = ref.getBoundingClientRect()

      window.scroll({
        top: window.pageYOffset + top - NAVBAR_HEIGHT,
        behavior: 'smooth',
      })
    }
  }
}
