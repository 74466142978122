interface CreateLeadInput {
  data: Record<string, any>
}

export const createLead = async ({ data }: CreateLeadInput) => {
  try {
    const response = await fetch('/api/sendSalesForceData', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    return response
  } catch (error: any) {
    throw new Error(error)
  }
}
