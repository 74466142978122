//@ts-nocheck

import React, { ReactNode, useCallback, useEffect, useState } from 'react'

import dynamic from 'next/dynamic'
import Link from 'next/link'

import {
  Body2,
  Button,
  Caption2,
  Spacer,
  TitleLarge2,
  TitleMedium2,
} from 'ethos-design-system'

import { Product } from '../../../../lib/@getethos/constants'
import {
  COVERAGE_SLIDER,
  DAILY_PREMIUM_DEFAULTS,
  FINAL_EXPENSE_COVERAGE,
  TOOLTIP,
  WHOLE_LIFE_BLURB,
} from '../../../constants'
import { BackAngularIcon, SaveIcon } from '../../../icons'
import { PriceRangeDisplay } from '../../../shared/Estimate/PriceRangeDisplay'
import { CoverageSlider, TermSlider } from '../../../shared/Estimate/Sliders'
import { getMedianTerm } from '../../../shared/Estimate/utils'
import { FinalExpenseCoverageSlider } from '../common/FinalExpenseCoverageSlider'
import { DATA_TID } from '../common/data-tid'
import { Disclaimer } from './Disclaimer'
import { TermButtons } from './TermButtons'

const EstimateWidgetTooltip = dynamic(
  () =>
    import('../../../shared/Estimate/EstimateWidgetTooltip').then(
      (mod) => mod.EstimateWidgetTooltip
    ),
  { ssr: false }
)

type FormFooterButtonType = 'EDIT_ANSWER' | 'SAVE_ESTIMATE'

type FormFooterButtonProps = {
  label: string
  onClick: () => void
  labelStyle: string
  buttonStyle: string
  buttonType: FormFooterButtonType
  salamanderForm?: boolean
  cypressForm?: boolean
}

const FormFooterButton = (props: FormFooterButtonProps) => (
  <button onClick={props.onClick} className={props.buttonStyle}>
    <div className={props.buttonStyle}>
      {props.buttonType == 'EDIT_ANSWER' ? <BackAngularIcon /> : <SaveIcon />}
      <div className={props.labelStyle}>
        <Caption2.Regular400>{props.label}</Caption2.Regular400>
      </div>
    </div>
  </button>
)

const FormButtons = ({
  styles,
  openEmailModal,
  saveLabel,
  handleNavigateToApp,
  continueLabel,
  editAnswers,
  progressFunction,
  editAnswersLabel,
  product,
  disableSave = false,
  salamanderForm = false,
  cypressForm = false,
  asta = false,
}) => {
  let CTAButton = salamanderForm
    ? Button.Medium.Salamander
    : cypressForm
    ? Button.Medium.Cypress
    : Button.Medium.Black

  if (asta) {
    CTAButton = Button.Unstyled
  }

  const goToApp = () => handleNavigateToApp(disableSave)
  // Living expenses experiment end

  return (
    <div className={styles.buttons}>
      <div className={asta ? styles.astaButton : styles.submitButton}>
        <CTAButton
          data-tid={DATA_TID.SUBMIT_BUTTON2}
          type="submit"
          onClick={goToApp}
        >
          {asta ? <>{continueLabel}</> : <>{continueLabel}</>}
        </CTAButton>
      </div>

      <div className={styles.rangeFormFieldsBottom}>
        <FormFooterButton
          label={editAnswersLabel}
          onClick={(): void => {
            progressFunction()
            editAnswers()
          }}
          labelStyle={styles.editAnswersLabel}
          buttonStyle={styles.editAnswersButton}
          buttonType="EDIT_ANSWER"
        />
        {!disableSave && (
          <FormFooterButton
            label={saveLabel}
            onClick={openEmailModal}
            labelStyle={styles.saveLabel}
            buttonStyle={asta ? styles.asta : styles.saveButton}
            buttonType="SAVE_ESTIMATE"
          />
        )}
      </div>
    </div>
  )
}

interface FloatingRangeFormProps {
  styles: any
  showError: boolean
  editAnswers: () => void
  progressFunction: () => void
  asta?: boolean
  estimateCopy: ReactNode
  toggleSlider: ({
    newCoverage,
    newTerm,
  }: {
    newCoverage: number
    newTerm: number
  }) => void
  initialFetch: boolean
  setInitialFetch: () => void
  coverage: number
  term: number
  terms: number[]
  ranges: any
  prevRanges: any
  coverageLabel: string
  maxCoverage: number
  minCoverage: number
  product: string
  termLabel: string
  backgroundColor: string
  variation: string
  disclaimerText: string
  disclaimerColor: string
  openEmailModal: () => void
  saveLabel: string
  handleNavigateToApp: () => void
  continueLabel: string
  boxShadow: boolean
  dailyDollars?: boolean
  dailyPremium?: boolean
  salamanderForm?: boolean
  cypressForm?: boolean
  showSinglePrice?: boolean
  disablePresets?: boolean
  disableSave?: boolean
  showMedianCoverage?: boolean
  isUserDataComplete: boolean
}

export const FloatingRangeForm = ({
  styles,
  showError,
  editAnswers,
  progressFunction,
  asta,
  estimateCopy,
  toggleSlider,
  initialFetch,
  setInitialFetch,
  coverage,
  term,
  terms,
  ranges,
  prevRanges,
  coverageLabel,
  maxCoverage,
  minCoverage,
  product,
  termLabel,
  backgroundColor,
  variation,
  disclaimerText,
  disclaimerColor,
  openEmailModal,
  saveLabel,
  handleNavigateToApp,
  continueLabel,
  boxShadow,
  dailyDollars,
  dailyPremium,
  salamanderForm,
  cypressForm,
  showSinglePrice = false,
  disablePresets = false,
  disableSave = false,
  showMedianCoverage,
  isUserDataComplete,
}: FloatingRangeFormProps) => {
  const singleDailyPremium = showSinglePrice && dailyPremium
  const maxTerm = terms[terms.length - 1]

  const getGenericCoverageDefaultValue = (product: Product) => {
    switch (product) {
      case Product.Term:
      case Product.GI:
      case Product.SI:
        return coverage >= COVERAGE_SLIDER.MIN && coverage <= maxCoverage
          ? coverage
          : COVERAGE_SLIDER.MIN
      case Product.FE:
        return coverage >= FINAL_EXPENSE_COVERAGE.MIN && coverage <= maxCoverage
          ? coverage
          : FINAL_EXPENSE_COVERAGE.MIN
    }
  }

  const [genericCoverage, setGenericCoverage] = useState(
    getGenericCoverageDefaultValue(product)
  )

  const termChange = (event: any): void => {
    const closest = terms.reduce(function (prev, curr) {
      return Math.abs(curr - event) < Math.abs(prev - event) ? curr : prev
    })

    toggleSlider({ newCoverage: coverage, newTerm: closest })
  }

  const handleGenericCoverageChange = useCallback(
    (event: any) => {
      if (!initialFetch) {
        const experimentTerm = DAILY_PREMIUM_DEFAULTS.TERM
        const experimentCoverage = DAILY_PREMIUM_DEFAULTS.COVERAGE
        if (product === Product.Term && singleDailyPremium && !disablePresets) {
          if (experimentTerm < maxTerm) {
            toggleSlider({ newCoverage: coverage, newTerm: experimentTerm })
          }
          if (experimentCoverage < maxCoverage) {
            setGenericCoverage(experimentCoverage)
          } else {
            if (event < maxCoverage) {
              setGenericCoverage(event)
            } else {
              setGenericCoverage(maxCoverage)
            }
          }
        }
        if (showMedianCoverage) {
          const medianTerm = getMedianTerm(terms)
          toggleSlider({ newCoverage: coverage, newTerm: medianTerm })
          setGenericCoverage((maxCoverage + minCoverage) / 2)
        }
        setInitialFetch()
      } else {
        if (event < maxCoverage) {
          setGenericCoverage(event)
        } else {
          setGenericCoverage(maxCoverage)
        }
      }
      toggleSlider({ newCoverage: event, newTerm: term })
    },
    [
      term,
      coverage,
      toggleSlider,
      singleDailyPremium,
      disablePresets,
      initialFetch,
      maxCoverage,
      maxTerm,
      product,
      setInitialFetch,
    ]
  )

  useEffect(() => {
    if (genericCoverage !== coverage) {
      handleGenericCoverageChange(genericCoverage)
    }
  }, [coverage, genericCoverage, handleGenericCoverageChange, product])

  const editAnswersLabel = asta ? 'Edit my answers' : 'Edit answers'

  useEffect(() => {
    if (showMedianCoverage) {
      toggleSlider({
        newCoverage: showMedianCoverage
          ? (maxCoverage + minCoverage) / 2
          : coverage,
        newTerm: showMedianCoverage ? getMedianTerm(terms) : term,
      })
      setGenericCoverage((maxCoverage + minCoverage) / 2)
    } else {
      setGenericCoverage(getGenericCoverageDefaultValue(product))
    }
  }, [product, showMedianCoverage, isUserDataComplete, initialFetch])

  const buttonProps = {
    styles,
    openEmailModal,
    saveLabel,
    handleNavigateToApp,
    continueLabel,
    editAnswers,
    progressFunction,
    editAnswersLabel,
    product,
    salamanderForm,
    cypressForm,
    disableSave,
    asta,
  }

  const singlePriceClasses = [styles.estimateCopy]

  if (showSinglePrice) {
    singlePriceClasses.push(styles.estimateCopySinglePrice)
  }

  const pricesClassName = asta ? [styles.astaPrices] : [styles.prices]
  pricesClassName.push(styles.pricesMarginLean)

  const floatingLeanPadding = { paddingBottom: 16 }

  let containerStyles = 'p-6 bg-white rounded-lg'
  if (boxShadow) {
    containerStyles = containerStyles + ' shadow-md'
  }
  containerStyles += asta
    ? '  max-w-full md:max-w-sm xl:max-w-xl w-144 border border-solid border-1 border-gray-2 rounded-sm p-6'
    : ' max-w-sm'

  const formStyles = [styles.rangeForm]
  if (asta) {
    formStyles.push(styles.asta)
  }

  return (
    <div className={formStyles.join(' ')}>
      {asta && (
        <div className={'block w-full pb-6 pt-2 text-center md:pt-20'}>
          <TitleMedium2.Serif.Book500>Your estimate</TitleMedium2.Serif.Book500>
          <Caption2.Regular400 elementClasses={styles.priceDisclaimer}>
            This is an initial estimate. You’ll get your final rate and approval
            decision in just a few minutes.
          </Caption2.Regular400>
        </div>
      )}
      <div className={containerStyles} style={floatingLeanPadding}>
        <div className={styles.rangeFormInner}>
          {showError ? (
            <div className="flex flex-col flex-wrap">
              <Spacer.H80 />
              <Spacer.H24 />
              <TitleLarge2.Serif.Book500>
                We&apos;re sorry!
              </TitleLarge2.Serif.Book500>
              <Spacer.H32 />
              <Body2.Regular400>{showError}</Body2.Regular400>
              <Spacer.H80 />
              <Spacer.H24 />
              <Button.Medium.Black
                fullWidth
                refreshIcon
                onClick={(): void => {
                  progressFunction()
                  editAnswers()
                }}
              >
                Edit Answers
              </Button.Medium.Black>
              <Spacer.H80 />
            </div>
          ) : (
            <>
              <div className={styles.rangeFormFieldsContainer}>
                <div className={styles.rangeFormFields}>
                  <div className={styles.rangeFormFieldsTopLean}>
                    {!asta && (
                      <h3 className={singlePriceClasses.join(' ')}>
                        {showSinglePrice ? (
                          <Body2.Medium500>{estimateCopy}</Body2.Medium500>
                        ) : (
                          <Caption2.Medium500>
                            {estimateCopy}
                          </Caption2.Medium500>
                        )}
                        <EstimateWidgetTooltip
                          details={
                            dailyPremium
                              ? TOOLTIP.ESTIMATE_RATE_DAILY.MESSAGE
                              : TOOLTIP.ESTIMATE_RATE.MESSAGE
                          }
                          label={
                            dailyPremium
                              ? TOOLTIP.ESTIMATE_RATE_DAILY.LABEL
                              : TOOLTIP.ESTIMATE_RATE.LABEL
                          }
                          popperBoxStyles={styles.tooltipPopperBoxStyles}
                          placement="right"
                          softCorners
                        />
                      </h3>
                    )}
                  </div>
                  <div className={pricesClassName.join(' ')}>
                    <PriceRangeDisplay
                      styles={styles}
                      minRangeStart={prevRanges.min}
                      minRangeEnd={ranges.min}
                      maxRangeStart={prevRanges.max}
                      maxRangeEnd={ranges.max}
                      variation={variation}
                      dailyDollars={dailyDollars}
                      dailyPremium={dailyPremium}
                      showSinglePrice={showSinglePrice}
                      asta={asta}
                    />
                  </div>
                  {product === Product.FE && (
                    <FinalExpenseCoverageSlider
                      coverageLabel={coverageLabel}
                      styles={styles}
                      variation={variation}
                      termLabel={termLabel}
                      coverage={genericCoverage}
                      maxCoverage={maxCoverage}
                      handleCoverageChange={handleGenericCoverageChange}
                    />
                  )}
                  {product === Product.GI && (
                    <>
                      <div className="text-gray-3">
                        <Caption2.Regular400>{termLabel}</Caption2.Regular400>
                      </div>
                      <Spacer.H4 />
                      <Body2.Regular400>Whole Life</Body2.Regular400>
                      <Spacer.H32 />
                      <div className="text-gray-3">
                        <Caption2.Regular400>
                          {WHOLE_LIFE_BLURB.INTRO}
                        </Caption2.Regular400>
                      </div>
                      <Spacer.H24 />
                      <Link href={WHOLE_LIFE_BLURB.LINK}>
                        <span className="text-forest underline">
                          {WHOLE_LIFE_BLURB.CTA}
                        </span>
                      </Link>
                    </>
                  )}

                  {product !== Product.GI && product !== Product.FE && (
                    <div className={styles.sliders}>
                      <CoverageSlider
                        coverageLabel={coverageLabel}
                        coverage={genericCoverage}
                        minCoverage={COVERAGE_SLIDER.MIN}
                        maxCoverage={maxCoverage}
                        coverageStep={COVERAGE_SLIDER.STEP}
                        handleCoverageChange={handleGenericCoverageChange}
                        dataTid={DATA_TID.COVERAGE_INPUT}
                        styles={styles}
                        variation={variation}
                        enableTooltip
                      />
                      {asta ? (
                        <TermButtons
                          maxTerm={maxTerm}
                          initialTerm={term}
                          termToggle={termChange}
                        />
                      ) : (
                        <TermSlider
                          termLabel={termLabel}
                          term={term}
                          minTerm={terms[0]}
                          maxTerm={maxTerm}
                          handleTermChange={termChange}
                          dataTid={DATA_TID.TERM_INPUT}
                          availableTerms={terms}
                          backgroundColor={backgroundColor}
                          styles={styles}
                          variation={variation}
                          enableTooltip
                        />
                      )}
                    </div>
                  )}
                </div>
                <FormButtons {...buttonProps} />
              </div>
            </>
          )}
        </div>
      </div>
      {!asta && (
        <Disclaimer color={disclaimerColor}>{disclaimerText}</Disclaimer>
      )}
    </div>
  )
}
