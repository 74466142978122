import React from 'react'

import { Button, CloudinaryImage, Spacer } from 'ethos-design-system'
import { cn } from 'lib/client-utils'

import PriorityImage from '../../global/PriorityImage'
import styles from '../SingleCTA.module.scss'
import { SingleCTAInterface } from '../interface'
import { InsuranceInfo, trustImageGroupsV2 } from './FinalExpense'

const FinalExpenseWithPhoneNumber = ({
  moduleData,
  ctaClick,
  phoneNumber,
}: {
  moduleData: SingleCTAInterface
  ctaClick: () => void
  phoneNumber: {
    key: string
    value: string
  } | null
}) => {
  const { groupImage, imageAlt } = moduleData

  const navbarPadding =
    moduleData?.navbarPadding === '64' ? (
      <div className="hidden w-full md:block">
        <Spacer.H64 />
      </div>
    ) : (
      <div className="w-full">
        <Spacer.H80 />
      </div>
    )

  const TrustImageGroups = () => {
    const trustImagesClassName = cn(styles.trustImages, '!mb-28  lg:!mb-52')

    return (
      <div className={trustImagesClassName}>
        {trustImageGroupsV2?.map((trustImageGroup, index) => (
          <div
            key={`trustImage-${index}`}
            className={styles.trustImageContainer}
          >
            <CloudinaryImage
              publicId={trustImageGroup.groupImage}
              alt={trustImageGroup.imageAlt}
              className={styles.trustImageV2}
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[142, 142, 142, 142]}
              width={[85, 85, 85, 85]}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={styles.finalExpense}>
      <div className={styles.container}>
        {moduleData.navbarPadding !== '0' && <>{navbarPadding}</>}
        <div className={styles.mainContent}>
          <h1 className={styles.header}>Final expense insurance</h1>
          <InsuranceInfo />
          <div className={'flex flex-col-reverse lg:flex-col'}>
            <div className={styles.ctaPhoneContainer}>
              <Button.Medium.DarkSalamander
                fullWidth
                onClick={() => ctaClick()}
              >
                APPLY ONLINE
              </Button.Medium.DarkSalamander>
              <div className={styles.phoneContainer}>
                <p className="m-0 text-xl">Or call for dedicated support</p>
                <a
                  className={styles.phoneNumber}
                  href={phoneNumber?.value}
                  target="_blank"
                >
                  {phoneNumber?.key}
                </a>
              </div>
            </div>
            <TrustImageGroups />
          </div>
        </div>
      </div>
      <div className={styles.bgContainer}>
        <div className={styles.bgImage}>
          {groupImage && imageAlt && (
            <PriorityImage
              publicId={groupImage}
              fetchpriority="high"
              alt={imageAlt}
              crop={CloudinaryImage.CROP_METHODS.CROP}
              height={[0, 384, 729, 972]}
              width={[0, 768, 1440, 1920]}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default FinalExpenseWithPhoneNumber
