import { ENCRYPTION_TERM_SOURCES, TERM_KEY } from './constants'

// Extract encryption / decryption source from request params for PII prefill
export const getEncryptionSource = (params: any): string => {
  if (
    Object.keys(params).includes(TERM_KEY) &&
    Object.keys(ENCRYPTION_TERM_SOURCES).includes(params[TERM_KEY])
  ) {
    return ENCRYPTION_TERM_SOURCES[params[TERM_KEY]]
  }
  return params['utm_source'] ?? params['utmSource'] ?? 'martech'
}
