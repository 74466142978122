import { Product } from '../constants'
import { ageNearestBirthDate } from './ageNearestBirthDate'
import { isWithinRange } from './utils'

const TWENTY_THOUSAND = 20000
const TEN_THOUSAND = 10000
const TWO_MILLION = 2000000
const ONE_MILLION = 1000000
const HALF_MILLION = 500000
const THREE_HUNDRED_FIFTY = 350000
const FALLBACK_MAX_COVERAGE = 100000

/**
 * Returns the max coverage for certain LGA ages.
 */
const getTermMaxCoverage = (age: number): number => {
  if (isWithinRange(age, 20, 50)) {
    return TWO_MILLION
  } else if (isWithinRange(age, 51, 60)) {
    return ONE_MILLION
  } else if (isWithinRange(age, 61, 65)) {
    return HALF_MILLION
  } else {
    return FALLBACK_MAX_COVERAGE
  }
}

/**
 *
 * @returns
 */
const getSIMaxCoverage = (age: number): number => {
  if (isWithinRange(age, 20, 45)) {
    return HALF_MILLION
  }

  return THREE_HUNDRED_FIFTY
}

/**
 * Returns the max coverage for Final Expense ages.
 */
const getFEMaxCoverage = (age: number): number => {
  if (isWithinRange(age, 65, 80)) {
    return TWENTY_THOUSAND
  }
  return TEN_THOUSAND
}

const getFinalExpenseMaxCoverage = (age: number) => {
  if (isWithinRange(age, 18, 70)) {
    return 100000
  }
  if (isWithinRange(age, 71, 75)) {
    return 50000
  }
  return 25000
}

export const maxCoverageDetermination = (
  birthDate: string | undefined,
  product: Product,
  finalExpense?: boolean
): number => {
  const age = !Number.isNaN(Number(birthDate))
    ? Number(birthDate)
    : ageNearestBirthDate({ birthDate })

  if (finalExpense)
    return age ? getFinalExpenseMaxCoverage(age) : FALLBACK_MAX_COVERAGE
  switch (product) {
    case Product.Term:
      return age ? getTermMaxCoverage(age) : FALLBACK_MAX_COVERAGE
    case Product.SI:
      return age ? getSIMaxCoverage(age) : FALLBACK_MAX_COVERAGE
    case Product.FE:
      return age ? getFEMaxCoverage(age) : FALLBACK_MAX_COVERAGE
    case Product.GI:
    default:
      return FALLBACK_MAX_COVERAGE
  }
}
