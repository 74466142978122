import React from 'react'

import { Button, CloudinaryImage, Spacer } from 'ethos-design-system'
import { cn } from 'lib/client-utils'

import { useFeLPNewHeader } from '@/hooks/features/useFeLPNewHeader'

import Markdown from '../../global/Markdown'
import PriorityImage from '../../global/PriorityImage'
import styles from '../SingleCTA.module.scss'
import { SingleCTAInterface } from '../interface'
import { InsuranceInfo, trustImageGroupsV2 } from './FinalExpense'

const FinalExpenseDefault = ({
  moduleData,
  ctaClick,
}: {
  moduleData: SingleCTAInterface
  ctaClick: () => void
}) => {
  const {
    heading: defaultHeading,
    subHeading,
    ctaLabel,
    arrowIcon,
    trustImageGroups,
    groupImage,
    imageAlt,
  } = moduleData

  const {
    isTreatment1: isFeLpNewHeaderTreatment1,
    isTreatment2: isFeLpNewHeaderTreatment2,
  } = useFeLPNewHeader()

  const demoNewHeaderTreatment1 =
    moduleData.treatmentVariant === 'fe_lp_vs_new_header-treatment-1'
  const demoNewHeaderTreatment2 =
    moduleData.treatmentVariant === 'fe_lp_vs_new_header-treatment-2'

  const isTreatment1 = demoNewHeaderTreatment1 || isFeLpNewHeaderTreatment1
  const isTreatment2 = demoNewHeaderTreatment2 || isFeLpNewHeaderTreatment2

  const navbarPadding =
    moduleData?.navbarPadding === '64' ? (
      <div className="hidden w-full md:block">
        <Spacer.H64 />
      </div>
    ) : (
      <div className="w-full">
        <Spacer.H80 />
      </div>
    )

  const getHeading = () => {
    return isTreatment1 || isTreatment2
      ? 'Final expense insurance'
      : defaultHeading
  }

  const heading = getHeading()

  const TrustImageGroups = () => {
    const images = isTreatment2 ? trustImageGroupsV2 : trustImageGroups

    const trustImagesClassName = cn(styles.trustImages, {
      'pb-0 lg:pb-48': isTreatment1,
      'pb-8 lg:pb-36': isTreatment2,
    })

    return (
      <div className={trustImagesClassName}>
        {images?.map((trustImageGroup, index) => (
          <div
            key={`trustImage-${index}`}
            className={styles.trustImageContainer}
          >
            <CloudinaryImage
              publicId={trustImageGroup.groupImage}
              alt={trustImageGroup.imageAlt}
              className={isTreatment2 ? styles.trustImageV2 : styles.trustImage}
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[142, 142, 142, 142]}
              width={[85, 85, 85, 85]}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={styles.finalExpense}>
      <div className={styles.container}>
        {moduleData.navbarPadding !== '0' && <>{navbarPadding}</>}
        <div className={styles.mainContent}>
          <h1 className={styles.header}>
            <Markdown input={heading || ''}></Markdown>
          </h1>
          {isTreatment1 || isTreatment2 ? (
            <InsuranceInfo />
          ) : (
            <div className={styles.subText}>{subHeading}</div>
          )}
          <div className={styles.cta}>
            <Button.Medium.DarkSalamander
              fullWidth
              arrowIcon={!!arrowIcon}
              onClick={() => ctaClick()}
            >
              {ctaLabel}
            </Button.Medium.DarkSalamander>
          </div>
          <TrustImageGroups />
        </div>
      </div>
      <div className={styles.bgContainer}>
        <div className={styles.bgImage}>
          {groupImage && imageAlt && (
            <PriorityImage
              publicId={groupImage}
              fetchpriority="high"
              alt={imageAlt}
              crop={CloudinaryImage.CROP_METHODS.CROP}
              height={[0, 384, 729, 972]}
              width={[0, 768, 1440, 1920]}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default FinalExpenseDefault
