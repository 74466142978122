import React from 'react'

import dynamic from 'next/dynamic'

import { Slider, SliderMarker } from '@reach/slider'
import classNames from 'classnames'
import { Body2, Caption2, TitleLarge2 } from 'ethos-design-system'

import { EstimateWidgetVariations } from '../../components/EstimateWidget/common/variations'
import { TOOLTIP } from '../../constants'

const EstimateWidgetTooltip = dynamic(
  () =>
    import('./EstimateWidgetTooltip').then((mod) => mod.EstimateWidgetTooltip),
  { ssr: false }
)

interface CoverageSliderProps {
  coverageLabel: string
  coverage: number
  minCoverage: number
  coverageStep: number
  maxCoverage: number
  handleCoverageChange: (e: any) => void
  dataTid: string
  // the current SCSS is a mess and difficult to refactor
  // allowing passing in styles instead
  styles: any
  variation?: string
  enableTooltip?: boolean
  directToApp?: boolean
}

/**
 * Coverage Slider in the Estimate Experience
 */
export const CoverageSlider: React.FC<CoverageSliderProps> = ({
  coverageLabel,
  coverage,
  minCoverage,
  coverageStep,
  maxCoverage,
  handleCoverageChange,
  dataTid,
  styles,
  variation,
  enableTooltip,
  directToApp,
}) => {
  const middleMarker = (maxCoverage + minCoverage) / 2
  const coveragesMarkers = [minCoverage, middleMarker, maxCoverage]
  const labelStyle = enableTooltip ? styles.labelWithTooltip : styles.label
  const isFloatingVariation =
    variation === EstimateWidgetVariations.FLOATING_LEAN

  return (
    <div
      className={
        variation === EstimateWidgetVariations.FLOATING_LEAN
          ? styles.sliderLean
          : styles.slider
      }
    >
      <div
        className={classNames(
          directToApp ? 'mb-3 mt-8 flex justify-between lg:mb-5' : ''
        )}
      >
        <div
          className={classNames(labelStyle, {
            'items-center': directToApp,
          })}
        >
          {directToApp ? (
            <div className="body-m-med text-[#525252]">{coverageLabel}</div>
          ) : (
            <Caption2.Regular400>{coverageLabel}</Caption2.Regular400>
          )}
          {enableTooltip && (
            <EstimateWidgetTooltip
              details={TOOLTIP.COVERAGE.MESSAGE}
              label={TOOLTIP.COVERAGE.LABEL}
              popperBoxStyles={styles.tooltipPopperBoxStyles}
              placement="right"
              softCorners
              directToApp
            />
          )}
        </div>
        <div className={styles.value}>
          {isFloatingVariation ? (
            <Body2.Regular400>
              ${coverage.toLocaleString('en-US')}
            </Body2.Regular400>
          ) : (
            <TitleLarge2.Sans.Regular400>
              ${coverage.toLocaleString('en-US')}
            </TitleLarge2.Sans.Regular400>
          )}
        </div>
      </div>
      <Slider
        min={minCoverage}
        max={maxCoverage}
        step={coverageStep}
        handleAlignment="contain"
        value={coverage}
        onChange={(e: any): void => {
          handleCoverageChange(e)
        }}
        data-tid={dataTid}
      >
        {coveragesMarkers.map((x, index) => {
          return (
            <SliderMarker value={x} key={`marker_${x.toString()}_${index}`}>
              <span>&nbsp;</span>
            </SliderMarker>
          )
        })}
      </Slider>
    </div>
  )
}

interface TermSliderProps {
  termLabel: string
  term: number
  minTerm: number
  maxTerm: number
  handleTermChange: (e: any) => void
  dataTid: string
  availableTerms: number[]
  backgroundColor?: string
  // the current SCSS is a mess and difficult to refactor
  // allowing passing in styles instead
  styles: any
  variation?: string
  enableTooltip?: boolean
  directToApp?: boolean
}

/**
 * Term slider for the estimate experience
 */
export const TermSlider: React.FC<TermSliderProps> = ({
  termLabel,
  term,
  minTerm,
  maxTerm,
  handleTermChange,
  dataTid,
  availableTerms,
  backgroundColor,
  styles,
  variation,
  enableTooltip,
}) => {
  const labelStyle = enableTooltip ? styles.labelWithTooltip : styles.label
  const isFloatingVariation =
    variation === EstimateWidgetVariations.FLOATING_LEAN

  const toJSX = (data: string | string[]) => {
    if (Array.isArray(data)) {
      const initialJsxElement = <></>
      return data.reduce(
        (jsxElement, eachEntry) =>
          jsxElement === initialJsxElement ? (
            <>{eachEntry}</>
          ) : (
            <>
              {jsxElement}
              <br />
              <br />
              {eachEntry}
            </>
          ),
        initialJsxElement
      )
    }
    return <>{data}</>
  }

  return (
    <div className={[styles.term, styles.slider].join(' ')}>
      <div className={labelStyle}>
        <Caption2.Regular400>{termLabel}</Caption2.Regular400>
        {enableTooltip && (
          <EstimateWidgetTooltip
            details={toJSX(TOOLTIP.TERM_LENGTH.MESSAGE)}
            label={TOOLTIP.TERM_LENGTH.LABEL}
            popperBoxStyles={styles.tooltipPopperBoxStyles}
            placement="right"
            softCorners
          />
        )}
      </div>
      <div className={styles.value}>
        {isFloatingVariation ? (
          <Body2.Regular400>{term} years</Body2.Regular400>
        ) : (
          <TitleLarge2.Sans.Regular400>
            {term} years
          </TitleLarge2.Sans.Regular400>
        )}
      </div>
      <Slider
        min={minTerm}
        max={maxTerm}
        step={5}
        value={term}
        onChange={(e: any): void => {
          handleTermChange(e)
        }}
        data-tid={dataTid}
      >
        {availableTerms.map((x: number, index: number) => (
          <SliderMarker
            value={x}
            key={`term_${x.toString()}_${index}`}
            className={backgroundColor ? styles[backgroundColor] : ''}
          >
            <span>&nbsp;</span>
          </SliderMarker>
        ))}
      </Slider>
    </div>
  )
}
