import { zipCodeMask } from '../../DirectToAppInfoForm/utils/masks'
import {
  getButtonSelectGroup,
  getNumberInputComponent,
} from '../components/FormComponents/FormComponents'

const gender = {
  prompt: "Let's get your instant estimate! First, what’s your gender?",
  field: {
    component: getButtonSelectGroup,
    name: 'gender',
    options: {
      autoSubmit: true,
      values: [
        {
          copy: 'Male',
          value: 'Male',
        },
        {
          copy: 'Female',
          value: 'Female',
        },
      ],
    },
  },
}

const age = {
  prompt: "What's your age?",
  field: {
    options: {
      autoSubmit: false,
      placeholder: 'Age',
      maxLength: 3,
    },
    name: 'birthDate',
    component: getNumberInputComponent,
  },
}

const zipCode = {
  prompt: 'What’s your home zip code?',
  field: {
    options: {
      autoSubmit: false,
      placeholder: 'Zip code',
      mask: zipCodeMask,
    },
    name: 'zipCode',
    component: getNumberInputComponent,
  },
}

const smoker = {
  prompt: 'Do you use nicotine products?',
  field: {
    component: getButtonSelectGroup,
    name: 'smoker',
    options: {
      autoSubmit: true,
      values: [
        {
          copy: 'Yes',
          value: true,
        },
        {
          copy: 'No',
          value: false,
        },
      ],
    },
  },
}

export const questionsConfig = [gender, age, zipCode, smoker]
