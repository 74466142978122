import React from 'react'

import { Button } from '@getethos/ethos-design-system-v2'

import styles from './FloatingCallInfoWidget.module.scss'

export const FloatingCallInfoWidget = ({
  phoneNumber,
  onClick,
  ctaLabel,
}: {
  phoneNumber: { key: string; value: string } | null
  onClick: () => void
  ctaLabel: string | undefined
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.floatingWidget}>
        <div>
          <h4 className={styles.heading}>Apply in less than 10 minutes</h4>
          <ul className={styles.list}>
            <li>Simple 3 step process</li>
            <li>Set your beneficiaries</li>
            <li>
              Benefit amounts: <strong>$2,000 - $100,000</strong>
            </li>
          </ul>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => onClick()}
            variant="salamander"
            buttonTitle={ctaLabel || ''}
            size="lg"
            className={styles.cta}
          />
          <div className="my-6 flex items-center justify-center">
            <div className="h-[1px] w-full bg-border-default" />
            <span className={styles.separatorText}>OR</span>
            <div className="h-[1px] w-full bg-border-default" />
          </div>
          <div className="flex flex-col items-center">
            <p className={styles.phoneText}>For more guidance</p>
            <a
              className={styles.phoneNumber}
              href={phoneNumber?.value}
              target="_blank"
            >
              {phoneNumber?.key}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
