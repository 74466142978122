import React from 'react'

import styles from './PreviousButton.module.scss'

interface Props {
  onClick: () => void
  disabled?: boolean
}

const PreviousButton = ({ onClick, disabled }: Props) => (
  <button
    onClick={onClick}
    className={styles.button}
    disabled={disabled}
    type="button"
  >
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.13168 9.82836L5.57362 9.38964C5.67822 9.28581 5.67822 9.11745 5.57362 9.01359L2.1109 5.57617L9.73214 5.57617C9.88007 5.57617 10 5.45711 10 5.31027L10 4.68983C10 4.54298 9.88007 4.42393 9.73214 4.42393L2.1109 4.42393L5.57362 0.986482C5.67822 0.882647 5.67822 0.714287 5.57362 0.61043L5.13168 0.171715C5.02708 0.0678802 4.85748 0.0678802 4.75286 0.171715L0.0784487 4.81201C-0.0261493 4.91584 -0.0261493 5.0842 0.0784487 5.18806L4.75288 9.82836C4.85748 9.93221 5.02708 9.93221 5.13168 9.82836Z"
        fill="#054742"
      />
    </svg>

    <span className={styles.sr}>Back</span>
  </button>
)

export default PreviousButton
