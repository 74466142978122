import { COLORS } from 'ethos-design-system'

export const colorCombos = {
  buttercup: {
    bgColor: 'bg-buttercup',
    h1Color: COLORS.GRAY_PRIMARY,
    h2Color: COLORS.GRAY_PRIMARY,
    h3Color: COLORS.GRAY_PRIMARY,
  },
  duckegg: {
    bgColor: 'bg-duckegg',
    h1Color: COLORS.GRAY_PRIMARY,
    h2Color: COLORS.GRAY_PRIMARY,
    h3Color: COLORS.GRAY_PRIMARY,
  },
  forest: {
    bgColor: 'bg-forest',
    h1Color: COLORS.WHITE,
    h2Color: COLORS.GRAY_LIGHT_HOVER,
    h3Color: COLORS.GRAY_LIGHT_HOVER,
  },
  gray: {
    bgColor: 'bg-gray-1',
    h1Color: COLORS.GRAY_PRIMARY,
    h2Color: COLORS.GRAY_PRIMARY,
    h3Color: COLORS.GRAY_PRIMARY,
  },
  moss: {
    bgColor: 'bg-moss',
    h1Color: COLORS.GRAY_PRIMARY,
    h2Color: COLORS.GRAY_PRIMARY,
    h3Color: COLORS.GRAY_PRIMARY,
  },
  sand: {
    bgColor: 'bg-sand',
    h1Color: COLORS.GRAY_PRIMARY,
    h2Color: COLORS.GRAY_PRIMARY,
    h3Color: COLORS.GRAY_PRIMARY,
  },
  white: {
    bgColor: 'bg-white',
    h1Color: COLORS.GRAY_PRIMARY,
    h2Color: COLORS.GRAY_PRIMARY,
    h3Color: COLORS.GRAY_PRIMARY,
  },
}
