/**
 *
 * @export
 * @interface PartnerRef
 */
export interface PartnerRef {
  /**
   *
   * @type {number}
   * @memberof PartnerRef
   */
  id?: number
  /**
   *
   * @type {PartnerDBEnum}
   * @memberof PartnerRef
   */
  partnerType?: PartnerDBEnum
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  code?: string
  /**
   *
   * @type {number}
   * @memberof PartnerRef
   */
  parentId?: number
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  parentCode?: string
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  contactEmail?: string
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  contactPhoneNo?: string
  /**
   *
   * @type {any}
   * @memberof PartnerRef
   */
  phoneNumbers?: any | null
  /**
   *
   * @type {any}
   * @memberof PartnerRef
   */
  addresses?: any | null
  /**
   *
   * @type {any}
   * @memberof PartnerRef
   */
  licenses?: any | null
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  agencyName?: string
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  dba?: string
  /**
   *
   * @type {boolean}
   * @memberof PartnerRef
   */
  hasEoCoverage?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof PartnerRef
   */
  emailInvites?: Array<string>
  /**
   *
   * @type {Date}
   * @memberof PartnerRef
   */
  createdAt?: Date
  /**
   *
   * @type {Date}
   * @memberof PartnerRef
   */
  updatedAt?: Date
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  producerQuantity?: string
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  policyQuantity?: string
  /**
   *
   * @type {boolean}
   * @memberof PartnerRef
   */
  hasValidInsLicense?: boolean
  /**
   *
   * @type {Date}
   * @memberof PartnerRef
   */
  agreeTermsAt?: Date
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  agreeTermsIp?: string
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  referredBy?: string
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  recaptchaToken?: string
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  externalRefCode?: string
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  externalTrainerCode?: string
  /**
   *
   * @type {boolean}
   * @memberof PartnerRef
   */
  isAuthzUsa?: boolean
  /**
   *
   * @type {AgentTitleEnum}
   * @memberof PartnerRef
   */
  agentSegment?: AgentTitleEnum
  /**
   *
   * @type {boolean}
   * @memberof PartnerRef
   */
  showWelcomeModal?: boolean
  /**
   * BalanceOwing is the amount of money the partner owes us.
   * @type {number}
   * @memberof PartnerRef
   */
  balanceOwing?: number
  /**
   * LeadUUID is the lead associated with the partner
   * @type {string}
   * @memberof PartnerRef
   */
  leadUuid?: string
  /**
   *
   * @type {boolean}
   * @memberof PartnerRef
   */
  isSoloAgent?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PartnerRef
   */
  isSubagent?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PartnerRef
   */
  isAgencyHead?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PartnerRef
   */
  isNetworkHead?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PartnerRef
   */
  isNetworkAgent?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PartnerRef
   */
  isLicensed?: boolean
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  npn?: string
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  signupType?: string
  /**
   *
   * @type {string}
   * @memberof PartnerRef
   */
  signupChannel?: string
}

/**
 * Check if a given object implements the PartnerRef interface.
 */
export function instanceOfPartnerRef(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value

  return isInstance
}

export function exists(json: any, key: string): boolean {
  const value = json[key]
  return value !== null && value !== undefined
}

/**
 *
 * @export
 * @enum {string}
 */
export enum AgentTitleEnum {
  IndependentAgent = 'Independent Agent',
  AgencyOwner = 'Agency Owner',
  AgencyProducer = 'Agency Producer',
  Other = 'Other',
  Empty = '',
}

export function AgentTitleEnumFromJSON(json: any): AgentTitleEnum {
  return json as AgentTitleEnum
}

export function AgentTitleEnumToJSON(value?: AgentTitleEnum | null): any {
  return value as any
}

/**
 *
 * @export
 * @enum {string}
 */
export enum SignupForm {
  Agency = 'agency',
  Network = 'network',
  Agent = 'agent',
}

export function SignupFormFromJSON(json: any): SignupForm {
  return json as SignupForm
}

export function SignupFormToJSON(value?: SignupForm | null): any {
  return value as any
}

export enum SignupType {
  Enterprise = 'enterprise',
  SMB = 'smb',
  Unknown = 'unknown',
}

export function PartnerDBEnumFromJSON(json: any): PartnerDBEnum {
  return json as PartnerDBEnum
}

export function PartnerDBEnumToJSON(value?: PartnerDBEnum | null): any {
  return value as any
}

export function PartnerRefFromJSON(json: any): PartnerRef {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    partnerType: !exists(json, 'partner_type')
      ? undefined
      : PartnerDBEnumFromJSON(json['partner_type']),
    code: !exists(json, 'code') ? undefined : json['code'],
    parentId: !exists(json, 'parent_id') ? undefined : json['parent_id'],
    parentCode: !exists(json, 'parent_code') ? undefined : json['parent_code'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    contactEmail: !exists(json, 'contact_email')
      ? undefined
      : json['contact_email'],
    contactPhoneNo: !exists(json, 'contact_phone_no')
      ? undefined
      : json['contact_phone_no'],
    phoneNumbers: !exists(json, 'phone_numbers')
      ? undefined
      : json['phone_numbers'],
    addresses: !exists(json, 'addresses') ? undefined : json['addresses'],
    licenses: !exists(json, 'licenses') ? undefined : json['licenses'],
    agencyName: !exists(json, 'agency_name') ? undefined : json['agency_name'],
    dba: !exists(json, 'dba') ? undefined : json['dba'],
    hasEoCoverage: !exists(json, 'has_eo_coverage')
      ? undefined
      : json['has_eo_coverage'],
    emailInvites: !exists(json, 'email_invites')
      ? undefined
      : json['email_invites'],
    createdAt: !exists(json, 'created_at')
      ? undefined
      : new Date(json['created_at']),
    updatedAt: !exists(json, 'updated_at')
      ? undefined
      : new Date(json['updated_at']),
    producerQuantity: !exists(json, 'producer_quantity')
      ? undefined
      : json['producer_quantity'],
    policyQuantity: !exists(json, 'policy_quantity')
      ? undefined
      : json['policy_quantity'],
    hasValidInsLicense: !exists(json, 'has_valid_ins_license')
      ? undefined
      : json['has_valid_ins_license'],
    agreeTermsAt: !exists(json, 'agree_terms_at')
      ? undefined
      : new Date(json['agree_terms_at']),
    agreeTermsIp: !exists(json, 'agree_terms_ip')
      ? undefined
      : json['agree_terms_ip'],
    referredBy: !exists(json, 'referred_by') ? undefined : json['referred_by'],
    recaptchaToken: !exists(json, 'recaptchaToken')
      ? undefined
      : json['recaptchaToken'],
    externalRefCode: !exists(json, 'external_ref_code')
      ? undefined
      : json['external_ref_code'],
    externalTrainerCode: !exists(json, 'external_trainer_code')
      ? undefined
      : json['external_trainer_code'],
    isAuthzUsa: !exists(json, 'is_authz_usa')
      ? undefined
      : json['is_authz_usa'],
    agentSegment: !exists(json, 'agent_segment')
      ? undefined
      : AgentTitleEnumFromJSON(json['agent_segment']),
    showWelcomeModal: !exists(json, 'show_welcome_modal')
      ? undefined
      : json['show_welcome_modal'],
    balanceOwing: !exists(json, 'balance_owing')
      ? undefined
      : json['balance_owing'],
    leadUuid: !exists(json, 'lead_uuid') ? undefined : json['lead_uuid'],
    isSoloAgent: !exists(json, 'isSoloAgent') ? undefined : json['isSoloAgent'],
    isSubagent: !exists(json, 'isSubagent') ? undefined : json['isSubagent'],
    isAgencyHead: !exists(json, 'isAgencyHead')
      ? undefined
      : json['isAgencyHead'],
    isNetworkHead: !exists(json, 'isNetworkHead')
      ? undefined
      : json['isNetworkHead'],
    isNetworkAgent: !exists(json, 'isNetworkAgent')
      ? undefined
      : json['isNetworkAgent'],
    isLicensed: !exists(json, 'isLicensed') ? undefined : json['isLicensed'],
    npn: !exists(json, 'npn') ? undefined : json['npn'],
    signupType: !exists(json, 'signup_type') ? undefined : json['signup_type'],
    signupChannel: !exists(json, 'signup_channel')
      ? undefined
      : json['signup_channel'],
  }
}

export function PartnerRefToJSON(value?: PartnerRef | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    partner_type: PartnerDBEnumToJSON(value.partnerType),
    code: value.code,
    parent_id: value.parentId,
    parent_code: value.parentCode,
    first_name: value.firstName,
    last_name: value.lastName,
    contact_email: value.contactEmail,
    contact_phone_no: value.contactPhoneNo,
    phone_numbers: value.phoneNumbers,
    addresses: value.addresses,
    licenses: value.licenses,
    agency_name: value.agencyName,
    dba: value.dba,
    has_eo_coverage: value.hasEoCoverage,
    email_invites: value.emailInvites,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    producer_quantity: value.producerQuantity,
    policy_quantity: value.policyQuantity,
    has_valid_ins_license: value.hasValidInsLicense,
    agree_terms_at:
      value.agreeTermsAt === undefined
        ? undefined
        : value.agreeTermsAt.toISOString(),
    agree_terms_ip: value.agreeTermsIp,
    referred_by: value.referredBy,
    recaptchaToken: value.recaptchaToken,
    agent_segment: AgentTitleEnumToJSON(value.agentSegment),
    show_welcome_modal: value.showWelcomeModal,
    balance_owing: value.balanceOwing,
    lead_uuid: value.leadUuid,
    isSoloAgent: value.isSoloAgent,
    isSubagent: value.isSubagent,
    isAgencyHead: value.isAgencyHead,
    isNetworkHead: value.isNetworkHead,
    isNetworkAgent: value.isNetworkAgent,
    isLicensed: value.isLicensed,
    npn: value.npn,
    signup_type: value.signupType,
    signup_channel: value.signupChannel,
    external_ref_code: value.externalRefCode,
    external_trainer_code: value.externalTrainerCode,
    is_authz_usa: value.isAuthzUsa,
  }
}

/**
 *
 * @export
 * @enum {string}
 */
export enum PartnerDBEnum {
  Agency = 'agency',
  Agent = 'agent',
  Carrier = 'carrier',
  Platform = 'platform',
  Network = 'network',
  Other = 'other',
  Affiliate = 'affiliate',
}
