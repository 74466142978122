import React from 'react'

import { CloudinaryImage } from 'ethos-design-system'

import PriorityImage from '@/components/global/PriorityImage'

import styles from './SpeakWithExpert.module.scss'

export const SpeakWithExpert = ({
  phoneNumber,
}: {
  phoneNumber: { key: string; value: string } | null
}) => {
  return (
    <section className={styles.speakWithExpert}>
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <h2 className={styles.heading}>Prefer to speak with an expert?</h2>
          <p className={styles.subHeading}>
            Speak with one of our highly trained specialists and get advice on
            choosing a plan that’s right for you.
          </p>
          <a className={styles.phoneButton} href={phoneNumber?.value}>
            <div className="h-8 w-8">
              <CloudinaryImage
                publicId={
                  'https://res.cloudinary.com/getethos/image/upload/v1719320408/call_phone_left_zngalz.svg'
                }
                crop={CloudinaryImage.CROP_METHODS.FILL}
                width={[32, 32, 32, 32]}
                height={[32, 32, 32, 32]}
                alt={'alt'}
              />
            </div>
            <span>{phoneNumber?.key}</span>
          </a>
          <ul className={styles.list}>
            <li>
              <div className="mr-1.5 h-1.5 w-1.5 rounded bg-[#056257]" />
              900 families covered today
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.bgContainer}>
        <div className={styles.bgImage}>
          <PriorityImage
            publicId={
              'https://res.cloudinary.com/getethos/image/upload/v1719320368/AdobeStock_213190803_cutout_1_bs19hh.png'
            }
            fetchpriority="high"
            alt={'alt'}
            crop={CloudinaryImage.CROP_METHODS.FIT}
            height={[258, 258, 1443, 1443]}
            width={[253, 253, 1435, 1435]}
          />
        </div>
      </div>
    </section>
  )
}
