import React from 'react'

import {
  Body2,
  Button,
  COLORS,
  CloudinaryImage,
  Spacer,
  TitleMedium2,
  TitleXLarge2,
} from 'ethos-design-system'
import { cn } from 'lib/client-utils'

import { ImageGroup } from '../../Banner/BannerLogos'
import Markdown from '../../global/Markdown'
import PriorityImage from '../../global/PriorityImage'
import styles from '../SingleCTA.module.scss'
import { SingleCtaAlignment } from '../enums'
import { SingleCTAInterface } from '../interface'

interface TrustImages extends ImageGroup {
  trustClasses: Array<any>
}

const TrustImage = (trustImageGroup: TrustImages) => {
  if (!trustImageGroup.clickthroughUrl || !trustImageGroup.enableClickthrough)
    return (
      <CloudinaryImage
        publicId={trustImageGroup.groupImage}
        alt={trustImageGroup.imageAlt}
        className={trustImageGroup.trustClasses.join(' ')}
        crop={CloudinaryImage.CROP_METHODS.FIT}
        height={[55, 55, 55, 55]}
        width={[90, 90, 90, 90]}
      />
    )

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={trustImageGroup.clickthroughUrl}
    >
      <CloudinaryImage
        publicId={trustImageGroup.groupImage}
        alt={trustImageGroup.imageAlt}
        className={trustImageGroup.trustClasses.join(' ')}
        crop={CloudinaryImage.CROP_METHODS.FIT}
        height={[55, 55, 55, 55]}
        width={[90, 90, 90, 90]}
      />
    </a>
  )
}

const ForegroundImage = ({
  moduleData,
  policiesApproved,
  ctaClick,
}: {
  moduleData: SingleCTAInterface
  policiesApproved: number
  ctaClick: () => void
}) => {
  const { hideMediaMobile } = moduleData

  const headingElementString = moduleData.enableH1 ? 'h1' : 'div'

  const backgroundClasses = ['w-full flex justify-center relative']
  hideMediaMobile && backgroundClasses.push(styles.short)

  const containerClasses = [
    'w-full flex flex-wrap flex-col lg:flex-row lg:flex-nowrap z-0 md:z-2',
  ]
  containerClasses.push(styles.singleCTAForegroundContainer)

  const contentClasses = ['flex pb-2 md:pb-3 pt-3 px-3 max-w-screen-xl']

  const alignment =
    moduleData.singleCtaAlignment === SingleCtaAlignment.LEFT
      ? 'justify-center lg:justify-start'
      : moduleData.singleCtaAlignment === SingleCtaAlignment.CENTER
      ? 'justify-center'
      : 'sm:justify-center md:justify-end'
  contentClasses.push(alignment)

  const textClasses = ['pt-5 pb-6 md:pt-16 md:pb-16']

  moduleData.singleCtaAlignment === SingleCtaAlignment.LEFT &&
    textClasses.push('lg:pl-20 xl:pl-9')

  const headingClasses = ['hidden md:block']
  moduleData.singleCtaAlignment === SingleCtaAlignment.CENTER &&
    headingClasses.push('text-center')

  const subheadingClasseses = ['mt-6']
  moduleData.singleCtaAlignment === SingleCtaAlignment.CENTER &&
    subheadingClasseses.push('text-center')

  const subHeadingWrapperClass = [
    'w-full sm:w-96 md:w-120 lg:w-136 text-center md:mx-auto',
  ]
  moduleData.singleCtaAlignment !== SingleCtaAlignment.CENTER &&
    subHeadingWrapperClass.push('lg:m-0 lg:text-left')

  const singleCtaClasses = [
    'hidden md:block mt-6 md:w-[450px]',
    styles.singleCtaButton,
    styles.numberHeaderCtaButton,
  ]
  moduleData.singleCtaAlignment === SingleCtaAlignment.LEFT &&
    singleCtaClasses.push('mx-auto lg:mx-0')
  moduleData.singleCtaAlignment === SingleCtaAlignment.CENTER &&
    singleCtaClasses.push('container mx-auto')
  moduleData.singleCtaAlignment === SingleCtaAlignment.RIGHT &&
    singleCtaClasses.push('md:mx-auto lg:mx-0 ')

  const shouldShowTrustImages =
    moduleData.trustImageGroups &&
    moduleData.trustImageGroups.length > 0 &&
    moduleData.singleCtaAlignment === SingleCtaAlignment.LEFT

  const imageWidthArray = hideMediaMobile
    ? [0, 0, 1440, 1920]
    : [600, 768, 1440, 1920]
  const imageHeightArray = hideMediaMobile
    ? [0, 0, 729, 972]
    : [300, 384, 729, 972]

  const imageClasses = [
    styles.foregroundImageSingleCTA,
    'lg:absolute lg:bottom-0 lg:right-0',
    styles.numbersHeaderCta,
  ]
  moduleData.treatment === 'treatment2' && imageClasses.push(styles.treatment2)

  const trustClasses = [styles.trustImage]
  hideMediaMobile && trustClasses.push('filter grayscale')

  const CTAButton =
    moduleData.ctaColor === 'cypress'
      ? Button.Medium.Cypress
      : moduleData.ctaColor === 'darkSalamander'
      ? Button.Medium.DarkSalamander
      : moduleData.ctaColor === 'salamander' || moduleData.salamanderForm
      ? Button.Medium.Salamander
      : Button.Medium.Stateful.White

  const copyColor =
    moduleData.headingColor === 'cypress'
      ? COLORS.BRAND_CYPRESS
      : moduleData.headingColor === 'gray'
      ? COLORS.GRAY_PRIMARY
      : COLORS.BRAND_FOREST

  const headingClass = [styles.boldHeader]
  if (copyColor === COLORS.BRAND_CYPRESS) {
    headingClass.push(styles.cypressText)
  }

  const heading = moduleData.heading && <Markdown input={moduleData.heading} />
  const headingElementDesktop =
    moduleData.headingWeight === 'bold' ? (
      <h1 className={headingClass.join(' ')}>{heading}</h1>
    ) : (
      <TitleXLarge2.Serif.Book500
        color={copyColor}
        element={headingElementString}
      >
        {heading}
      </TitleXLarge2.Serif.Book500>
    )
  const headingElementMobile =
    moduleData.headingWeight === 'bold' ? (
      <h1 className={headingClass.join(' ')}>{heading}</h1>
    ) : (
      <TitleMedium2.Serif.Book500
        color={copyColor}
        element={headingElementString}
      >
        {heading}
      </TitleMedium2.Serif.Book500>
    )

  const numbersHeadersImages = {
    mobile:
      'https://res.cloudinary.com/getethos/image/upload/v1693894689/00_CMS/parents_with_baby_mobile_ceioze.png',
    desktop:
      'https://res.cloudinary.com/getethos/image/upload/v1693894689/00_CMS/parents_with_baby_desktop_fbd54h.png',
  }

  const policiesApprovedFormatted =
    policiesApproved?.toLocaleString('en-US') || '1,018'

  const cardsInfo = [
    '**$2M** in coverage starts at **$2/day**',
    `**${policiesApprovedFormatted}** families approved today`,
    'No medical exams or blood tests',
  ]

  const heroDesktopImage = numbersHeadersImages.desktop

  const foregroundSectioWrapper = [
    styles.singleCTAForeground,
    styles.numberHeaderCtaBackground,
    styles.bgNumberHeader,
  ]

  const navbarPadding =
    moduleData?.navbarPadding === '64' ? (
      <div className="hidden w-full md:block">
        <Spacer.H64 />
      </div>
    ) : (
      <div className="w-full">
        <Spacer.H80 />
      </div>
    )

  return (
    <div className="relative">
      <div className={styles.singleCtaPlaceholder} />
      <div className={foregroundSectioWrapper.join(' ')}>
        {moduleData.navbarPadding !== '0' && <>{navbarPadding}</>}
        <div className={backgroundClasses.join(' ')}>
          <div className={containerClasses.join(' ')}>
            <div className={contentClasses.join(' ')}>
              <div className={textClasses.join(' ')}>
                <div
                  className={'w-full text-center sm:w-96 md:w-144 lg:text-left'}
                >
                  <div className={headingClasses.join(' ')}>
                    {headingElementDesktop}
                  </div>
                  <div className="block text-center md:hidden md:pl-9 md:pr-9">
                    {headingElementMobile}
                  </div>
                </div>
                <div className={styles.numberHeaderContainerWrapper}>
                  {cardsInfo.map((card, index) => (
                    <div key={card} className={styles.cardWrapper}>
                      <div
                        className={`${styles.content} ${
                          index === cardsInfo.length - 1 ? styles.last : ''
                        }`}
                      >
                        <Body2.Regular400 color={COLORS.Black}>
                          <Markdown input={card} />
                        </Body2.Regular400>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={singleCtaClasses.join(' ')}>
                  <CTAButton
                    fullWidth
                    onClick={(): void => ctaClick()}
                    arrowIcon={!!moduleData.arrowIcon}
                  >
                    <Body2.Medium500>{moduleData.ctaLabel}</Body2.Medium500>
                  </CTAButton>
                </div>
                {shouldShowTrustImages && (
                  <div className="hidden md:block">
                    <div className="mx-auto mt-8 max-w-[451px] flex-row justify-center md:flex md:w-full lg:mx-0 lg:justify-start">
                      {moduleData.trustImageGroups!.map(
                        (trustImageGroup, index) => (
                          <div
                            style={{
                              marginLeft: index === 0 ? 0 : 42,
                            }}
                            key={`trustImage-${index}`}
                          >
                            <CloudinaryImage
                              publicId={trustImageGroup.groupImage}
                              alt={trustImageGroup.imageAlt}
                              className={styles.trustImage}
                              crop={CloudinaryImage.CROP_METHODS.FIT}
                              height={[55, 55, 55, 55]}
                              width={[90, 90, 90, 90]}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
                {moduleData.subText && (
                  <div className="mx-auto hidden text-center md:block md:w-64 lg:mx-0">
                    <Spacer.H16 />
                    <Body2.Regular400 color={COLORS.BRAND_FOREST}>
                      {moduleData.subText}
                    </Body2.Regular400>
                  </div>
                )}
                <div
                  className={[
                    'container mx-auto mt-8 block md:hidden',
                    styles.singleCtaButton,
                    styles.numberHeaderCtaButton,
                    'w-full',
                    hideMediaMobile ? 'w-3/5' : 'w-4/5',
                  ].join(' ')}
                >
                  <CTAButton
                    fullWidth
                    onClick={(): void => ctaClick()}
                    arrowIcon={!!moduleData.arrowIcon}
                  >
                    <Body2.Medium500>{moduleData.ctaLabel}</Body2.Medium500>
                  </CTAButton>
                </div>
                {shouldShowTrustImages && (
                  <div className={'md:hidden'}>
                    {moduleData.extraTrustSpace && <Spacer.H16 />}
                    <div className="container mx-auto mt-4 flex w-full flex-row justify-center">
                      {moduleData.trustImageGroups!.map(
                        (trustImageGroup, index) => (
                          <div
                            style={{ marginLeft: index !== 0 ? 24 : 0 }}
                            key={`trustImage-${index}`}
                          >
                            <TrustImage
                              trustClasses={trustClasses}
                              {...trustImageGroup}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
                {moduleData.subText && (
                  <div className="container mx-auto mb-36 block w-4/5 text-center md:hidden">
                    <Spacer.H16 />
                    <Body2.Regular400 color={COLORS.WHITE}>
                      {moduleData.subText}
                    </Body2.Regular400>
                  </div>
                )}
              </div>
            </div>
            <div
              className={cn('relative hidden w-full max-w-screen-xl lg:block', {
                'hidden md:block': hideMediaMobile,
              })}
              style={shouldShowTrustImages ? { marginBottom: -0.5 } : {}}
            >
              {heroDesktopImage && moduleData.imageAlt && (
                <PriorityImage
                  publicId={heroDesktopImage}
                  fetchpriority="high"
                  alt={moduleData.imageAlt}
                  className={imageClasses.join(' ')}
                  crop={CloudinaryImage.CROP_METHODS.FIT}
                  height={imageHeightArray}
                  width={imageWidthArray}
                />
              )}
            </div>
            <div
              className={cn('relative w-full max-w-screen-xl lg:hidden', {
                'hidden md:block': hideMediaMobile,
              })}
              style={shouldShowTrustImages ? { marginBottom: -0.5 } : {}}
            >
              {heroDesktopImage && moduleData.imageAlt && (
                <PriorityImage
                  publicId={numbersHeadersImages.mobile}
                  fetchpriority="high"
                  alt={moduleData.imageAlt}
                  className={imageClasses.join(' ')}
                  crop={CloudinaryImage.CROP_METHODS.FIT}
                  height={imageHeightArray}
                  width={imageWidthArray}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForegroundImage
