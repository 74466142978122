import React from 'react'

import {
  Body2,
  Button,
  COLORS,
  CloudinaryImage,
  Spacer,
  TitleMedium2,
  TitleSmall,
  TitleXLarge2,
} from 'ethos-design-system'

import styles from '../SingleCTA.module.scss'
import { Subheading } from '../common/Subheading'
import { SingleCtaAlignment } from '../enums'
import { SingleCTAInterface } from '../interface'

const headingColorMap = {
  cypress: COLORS.BRAND_CYPRESS,
  gray: COLORS.GRAY_SECONDARY,
  grayPrimary: COLORS.GRAY_PRIMARY,
  forest: COLORS.BRAND_FOREST,
  white: COLORS.WHITE,
}

type HeadingColor = keyof typeof headingColorMap

const BackgroundImage = ({
  moduleData,
  ctaClick,
}: {
  moduleData: SingleCTAInterface
  ctaClick: () => void
}) => {
  const headingElement = moduleData.enableH1 ? 'h1' : 'div'
  const CapitalizedBackgroundColor = moduleData.backgroundColor
    ? moduleData.backgroundColor.charAt(0).toUpperCase() +
      moduleData.backgroundColor.slice(1)
    : ''

  const backgroundClasses = [
    'floatingContainer w-full flex justify-center relative',
  ]
  backgroundClasses.push(
    styles.singleCTA,
    styles[`bg${CapitalizedBackgroundColor}`]
  )
  const containerClass = [
    'w-full z-1 pb-2 md:pb-3 pt-3 px-3 flex flex-wrap md:flex-nowrap max-w-screen-xl',
  ]
  const alignment =
    moduleData.singleCtaAlignment === SingleCtaAlignment.LEFT
      ? 'sm:justify-center lg:justify-start'
      : moduleData.singleCtaAlignment === SingleCtaAlignment.CENTER
      ? 'justify-center'
      : 'sm:justify-center md:justify-end'
  containerClass.push(alignment)

  const textClass = ['pt-5 pb-6 md:pt-16']
  moduleData.singleCtaAlignment === SingleCtaAlignment.LEFT &&
    textClass.push('md:pl-9')

  const headingClass = ['hidden md:block']
  moduleData.singleCtaAlignment === SingleCtaAlignment.CENTER &&
    headingClass.push('text-center')

  const subHeadingClass = ['hidden md:block mt-8']
  moduleData.singleCtaAlignment === SingleCtaAlignment.CENTER &&
    subHeadingClass.push('text-center')

  const subHeadingWrapperClass = [
    'w-full sm:w-96 md:w-120 text-center md:mx-auto',
  ]
  moduleData.singleCtaAlignment !== SingleCtaAlignment.CENTER &&
    subHeadingWrapperClass.push('lg:m-0 lg:text-left')

  const singleCtaClasses = [
    'hidden md:block md:w-80 mt-12 rounded bg-white',
    styles.singleCtaButton,
  ]
  moduleData.singleCtaAlignment === SingleCtaAlignment.LEFT &&
    singleCtaClasses.push('mx-auto lg:mx-0')
  moduleData.singleCtaAlignment === SingleCtaAlignment.CENTER &&
    singleCtaClasses.push('container mx-auto')
  moduleData.singleCtaAlignment === SingleCtaAlignment.RIGHT &&
    singleCtaClasses.push('md:mx-auto lg:mx-0 ')

  const CTAButton = moduleData.salamanderForm
    ? Button.Medium.Salamander
    : Button.Medium.Stateful.White

  return (
    <>
      <div className={backgroundClasses.join(' ')}>
        <div className={containerClass.join(' ')}>
          <div className={textClass.join(' ')}>
            <div className={'w-full text-center sm:w-96 md:w-144 lg:text-left'}>
              <div className={headingClass.join(' ')}>
                <TitleXLarge2.Serif.Book500
                  color={
                    moduleData.headingColor
                      ? headingColorMap[moduleData.headingColor as HeadingColor]
                      : 'GraySecondary'
                  }
                  element={headingElement}
                >
                  {moduleData.heading}
                </TitleXLarge2.Serif.Book500>
              </div>
              <div className="block pt-4 text-center md:hidden md:pl-9 md:pr-9">
                <TitleMedium2.Serif.Book500
                  color={
                    moduleData.headingColor
                      ? headingColorMap[moduleData.headingColor as HeadingColor]
                      : 'GraySecondary'
                  }
                  element={headingElement}
                >
                  {moduleData.heading}
                </TitleMedium2.Serif.Book500>
              </div>
            </div>
            <div className={subHeadingWrapperClass.join(' ')}>
              {moduleData.displaySubHeading && (
                <div className={subHeadingClass.join(' ')}>
                  <TitleSmall.Sans.Regular400
                    color={
                      moduleData.headingColor
                        ? headingColorMap[
                            moduleData.headingColor as HeadingColor
                          ]
                        : 'GraySecondary'
                    }
                  >
                    {moduleData.subHeading}
                  </TitleSmall.Sans.Regular400>
                </div>
              )}
              <div className="mt-8 block md:hidden">
                <Subheading
                  displaySubHeading={moduleData.displaySubHeading}
                  color={
                    moduleData.headingColor
                      ? headingColorMap[moduleData.headingColor as HeadingColor]
                      : 'GraySecondary'
                  }
                >
                  {moduleData.subHeading}
                </Subheading>
              </div>
            </div>
            <div className={singleCtaClasses.join(' ')}>
              <CTAButton fullWidth onClick={(): void => ctaClick()}>
                {moduleData.ctaLabel}
              </CTAButton>
            </div>
            {moduleData.subText && (
              <div className="mx-auto hidden text-center md:block md:w-80 lg:mx-0">
                <Spacer.H16 />
                <Body2.Regular400 color={COLORS.WHITE}>
                  {moduleData.subText}
                </Body2.Regular400>
              </div>
            )}
            <div
              className={[
                'container mx-auto mt-8 block w-4/5 md:hidden',
                styles.singleCtaButton,
              ].join(' ')}
            >
              <CTAButton fullWidth onClick={(): void => ctaClick()}>
                {moduleData.ctaLabel}
              </CTAButton>
            </div>
            {moduleData.subText && (
              <div className="container mx-auto mb-36 block w-4/5 text-center md:hidden">
                <Spacer.H16 />
                <Body2.Regular400 color={COLORS.WHITE}>
                  {moduleData.subText}
                </Body2.Regular400>
              </div>
            )}
          </div>
        </div>
        <div className="absolute left-0 right-0 top-0 z-0 block overflow-hidden">
          {moduleData.groupImage && moduleData.imageAlt && (
            <CloudinaryImage
              publicId={moduleData.groupImage}
              alt={moduleData.imageAlt}
              className={styles.backgroundImageSingleCTA}
              crop={CloudinaryImage.CROP_METHODS.FILL}
              height={[0, 384, 729, 972]}
              width={[0, 768, 1440, 1920]}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default BackgroundImage
