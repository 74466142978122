import React from 'react'

import { useIncomingCallsLP } from '@/hooks/features/useIncomingCallsLP'

import Checkmark from '../icons/Checkmark'
import { SingleCTAInterface } from '../interface'
import FinalExpenseDefault from './FinalExpenseDefault'
import FinalExpenseWithCallSection from './FinalExpenseWithCallSection'
import FinalExpenseWithFloatingInfo from './FinalExpenseWithFloatingInfo'
import FinalExpenseWithPhoneNumber from './FinalExpenseWithPhoneNumber'

export const trustImageGroupsV2 = [
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1718285819/forbes_instant_yufhcj.svg',
    imageAlt: 'forbes instant',
  },
  {
    groupImage:
      'https://res.cloudinary.com/getethos/image/upload/v1718285819/business_insider_best_sychez.svg',
    imageAlt: 'business insider',
  },
]

export const insuranceInfoItems = [
  'Guaranteed acceptance whole life insurance',
  'Coverage in less than 10 minutes',
  'No medical exam. It’s easy!',
]

export const InsuranceInfo = () => {
  return (
    <div>
      {insuranceInfoItems.map((insuranceInfo) => (
        <div
          className="mb-4 flex flex-row items-center last:mb-8 md:mb-5 md:last:mb-16"
          key={insuranceInfo}
        >
          <Checkmark fill="#272727" className="mb-1 mr-4 min-w-min" />
          <p className="m-0 text-xl md:text-2xl">{insuranceInfo}</p>
        </div>
      ))}
    </div>
  )
}

const FinalExpense = ({
  moduleData,
  ctaClick,
}: {
  moduleData: SingleCTAInterface
  ctaClick: () => void
}) => {
  const { isTreatment1, isTreatment2, isTreatment3, treatmentPhoneNumber } =
    useIncomingCallsLP({ treatmentVariant: moduleData.treatmentVariant })

  if (isTreatment1) {
    return (
      <FinalExpenseWithPhoneNumber
        moduleData={moduleData}
        ctaClick={ctaClick}
        phoneNumber={treatmentPhoneNumber}
      />
    )
  }

  if (isTreatment2) {
    return (
      <FinalExpenseWithCallSection
        moduleData={moduleData}
        ctaClick={ctaClick}
        phoneNumber={treatmentPhoneNumber}
      />
    )
  }

  if (isTreatment3) {
    return (
      <FinalExpenseWithFloatingInfo
        moduleData={moduleData}
        ctaClick={ctaClick}
        phoneNumber={treatmentPhoneNumber}
      />
    )
  }

  return <FinalExpenseDefault moduleData={moduleData} ctaClick={ctaClick} />
}

export default FinalExpense
