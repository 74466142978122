import { FC, ReactNode, useCallback, useRef } from 'react'

import classNames from 'classnames'
import clsx from 'clsx'
import { CloudinaryImage } from 'ethos-design-system'

import { EstimateWidgetModuleData } from '@/components/EstimateWidget/EstimateWidget'
import { useEstimateWidgetContext } from '@/components/EstimateWidget/contexts/EstimateWidgetContext'
import { EstimateWidgetProgress } from '@/components/EstimateWidget/types/widget'
import Markdown from '@/components/global/Markdown'
import PriorityImage from '@/components/global/PriorityImage'

import { useFeEstWidgetWithPhone } from '@/hooks/features/useFeEstWidgetWithPhone'
import { useUpdatedCpFeLp } from '@/hooks/features/useUpdatedCpFeLp'

import styles from './FinalExpense.module.scss'

interface FinalExpenseInterface {
  moduleData: EstimateWidgetModuleData
  formManager: ReactNode
}

const maxContainerHeight = 698

export const FinalExpense: FC<FinalExpenseInterface> = ({
  moduleData,
  formManager,
}) => {
  const { heading, subHeading, groupImage, imageAlt, trustImageGroups } =
    moduleData

  const {
    widgetProgressControls: { widgetProgress },
  } = useEstimateWidgetContext()

  const wrapperRef = useRef<HTMLDivElement>(null)

  const { isTreatment1, isTreatment2 } = useUpdatedCpFeLp()

  const isUpdatedCpFeTreatment = isTreatment1 || isTreatment2

  const { isTreatment1: isEstWidgetWithPhoneTreatment1 } =
    useFeEstWidgetWithPhone({
      demoTreatmentVariation: moduleData.treatmentVariant,
    })

  const renderTrustImages = () => {
    return (
      <div
        className={classNames(styles.trustImages, {
          '!max-w-[400px]': isEstWidgetWithPhoneTreatment1,
        })}
      >
        {trustImageGroups?.map((trustImageGroup, index) => (
          <div
            key={`trustImage-${index}`}
            className={styles.trustImageContainer}
          >
            <CloudinaryImage
              publicId={trustImageGroup.groupImage}
              alt={trustImageGroup.imageAlt}
              className={styles.trustImage}
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[55, 55, 55, 55]}
              width={[90, 90, 90, 90]}
            />
          </div>
        ))}
      </div>
    )
  }

  const elementRef = useCallback((node: HTMLDivElement) => {
    if (!node) return
    const resizeObserver = new ResizeObserver(() => {
      const widgetHeight = node.clientHeight

      const containerHeight = widgetHeight + 50
      if (containerHeight < maxContainerHeight) return
      wrapperRef.current?.style.setProperty(
        '--form-height',
        `${containerHeight}px`
      )
    })
    resizeObserver.observe(node)
  }, [])

  if (
    widgetProgress === EstimateWidgetProgress.LEAD_FORM ||
    widgetProgress === EstimateWidgetProgress.PRICES ||
    widgetProgress === EstimateWidgetProgress.SALES
  ) {
    return <div className={styles.formManagerWrapper}>{formManager}</div>
  }

  const getTitle = () => {
    if (isUpdatedCpFeTreatment || isEstWidgetWithPhoneTreatment1) {
      return 'Final expense life insurance for you'
    }

    return heading
  }

  const getSubTitle = () => {
    if (isUpdatedCpFeTreatment || isEstWidgetWithPhoneTreatment1) {
      return 'This is a guaranteed acceptance whole life insurance product to leave your family money, when you pass.'
    }

    return subHeading
  }

  const title = getTitle()
  const subTitle = getSubTitle()

  return (
    <div
      className={clsx(styles.finalExpense, {
        '!max-h-[unset]':
          isUpdatedCpFeTreatment &&
          widgetProgress === EstimateWidgetProgress.INFO,
      })}
      ref={wrapperRef}
    >
      <div className={styles.container}>
        <div className={styles.spacer}></div>
        <div
          className={clsx(styles.wrapper, {
            'items-center':
              isUpdatedCpFeTreatment &&
              widgetProgress === EstimateWidgetProgress.INFO,
          })}
        >
          <div className={styles.mainContent}>
            <h1
              className={clsx(styles.header, {
                'sm:mb-8':
                  isUpdatedCpFeTreatment &&
                  widgetProgress === EstimateWidgetProgress.INFO,
              })}
            >
              <Markdown input={title || ''}></Markdown>
            </h1>
            <div
              className={clsx(styles.subText, {
                'lg:!mb-32':
                  isUpdatedCpFeTreatment &&
                  widgetProgress === EstimateWidgetProgress.INFO,
              })}
            >
              {subTitle}
            </div>
            <div className={styles.desktopOnly}>{renderTrustImages()}</div>
          </div>
          <div className={styles.desktopOnly} ref={elementRef}>
            {formManager}
          </div>
        </div>
      </div>
      <div className={styles.bgImage}>
        {groupImage && imageAlt && (
          <PriorityImage
            publicId={groupImage}
            fetchpriority="high"
            alt={imageAlt}
            crop={CloudinaryImage.CROP_METHODS.FILL}
            height={[0, 384, 729, 972]}
            width={[0, 768, 1440, 1920]}
          />
        )}
      </div>
      <div className={styles.mobileOnly}>
        <div className={styles.formManagerWrapper}>{formManager}</div>
        {renderTrustImages()}
      </div>
    </div>
  )
}
