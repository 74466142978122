//@ts-nocheck

import React, { JSX } from 'react'

import { COLORS, Caption2, Select } from 'ethos-design-system'

import { CreditScore } from '../../../lib/@getethos/constants'
import { EstimateWidgetVariations } from '../../components/EstimateWidget/common/variations'

export const estimatedCreditOptions = [
  { label: 'High (740+)', value: CreditScore.High },
  { label: 'Average (670-739)', value: CreditScore.Average },
  { label: 'Low (below 670)', value: CreditScore.Low },
  { label: `I don't know`, value: CreditScore.NoAnswer },
]

export const estimatedCreditOptionsWithoutNumericValue = [
  { label: 'High', value: CreditScore.High },
  { label: 'Average', value: CreditScore.Average },
  { label: 'Low', value: CreditScore.Low },
  { label: `I don't know`, value: CreditScore.NoAnswer },
]

export type EstimatedCreditProps = {
  currentValue?: CreditScore
  initialValue?: CreditScore
  estimatedCreditFootnote: string
  heroBoxed: boolean
  variation?: string
}

export const EstimatedCredit = (props: EstimatedCreditProps): JSX.Element => {
  const creditOptions =
    props.variation === EstimateWidgetVariations.FLOATING_LEAN
      ? estimatedCreditOptionsWithoutNumericValue
      : estimatedCreditOptions

  const defaultValue =
    (props.currentValue &&
      creditOptions.find((option) => option.value === props.currentValue)) ||
    creditOptions.find((option) => option.value === props.initialValue)

  const selectProps = {
    ...props,
    defaultValue,
    options: creditOptions,
    placeholder: 'Select...',
    allCaps: false,
    capitalize: true,
    labelColor: COLORS.GRAY_SECONDARY,
    labelWeight: 'regular',
    isSearchable: false,
    styles: {
      control: (style: any): object => ({
        ...style,
        backgroundColor: '#f5f5f5',
      }),
      indicatorSeparator: (): object => ({
        display: 'none',
      }),
    },
  }

  if (props.heroBoxed) {
    // use defaults for all of the following
    delete selectProps.allCaps
    delete selectProps.capitalize
    delete selectProps.labelColor
    delete selectProps.labelWeight
    delete selectProps.styles
  }

  const captionColor = props.heroBoxed ? undefined : 'GrayStrokeAndDisabled'

  return props.variation === EstimateWidgetVariations.FLOATING_LEAN ? (
    <>
      <div style={{ float: 'right' }}>
        <Caption2.Regular400 color={captionColor}>
          {props.estimatedCreditFootnote}
        </Caption2.Regular400>
      </div>
      <Select {...selectProps} />
    </>
  ) : (
    <>
      <Select {...selectProps} />
      <div className={'p-1'} />
      <Caption2.Regular400 color={captionColor}>
        {props.estimatedCreditFootnote}
      </Caption2.Regular400>
    </>
  )
}
