import { WILLS_MODAL_DUAL_CTA } from 'lib/@getethos/experiments/feature_constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useIsWillsModalDualCtaEnabled = () => {
  const { isEnabled, variation } = useDecisionForFlag({
    name: WILLS_MODAL_DUAL_CTA.name,
    fallbackVariation: WILLS_MODAL_DUAL_CTA.variations.off,
  })

  return isEnabled && variation === WILLS_MODAL_DUAL_CTA.variations.on
}
