import { convertAgeToBirthDateISO } from 'lib/@getethos/api/api'
import { Gender } from 'lib/@getethos/constants'
import { UserData } from 'lib/@getethos/interfaces'

const getBirthDateISO = (birthDate?: string) => {
  if (!birthDate) return null
  return !Number.isNaN(Number(birthDate))
    ? convertAgeToBirthDateISO(birthDate || '').substring(0, 10)
    : new Date(birthDate || '').toISOString().substring(0, 10)
}

interface Props {
  userData: UserData
  coverage: number
  region?: string | null
  term?: number
}

export const getParamsForQuotes = ({
  userData,
  coverage,
  region,
  term,
}: Props) => {
  const birthDateISO = getBirthDateISO(userData?.birthDate)

  if (!birthDateISO || !coverage) return null

  const params = {
    gender: userData.gender || Gender.Female,
    birthDate: birthDateISO,
    region: region || 'AL',
    targetAmount: coverage,
    term: term,
    tobacco: userData?.smoker ? 'yes' : 'no',
  }

  return params
}
