export const getModuleIdClosure = (): ((type: string) => string) => {
  const countsByType: { [key: string]: number } = {}

  /**
   * Given a type, returns the a string of module id based on the type
   * and the current count (i.e. QuoteWidget-2)
   * @param {module.type} type
   */
  return (type: string): string => {
    // Updates the count by type if it exists
    if (countsByType[type]) {
      countsByType[type] = countsByType[type] + 1
    } else {
      countsByType[type] = 1
    }

    const id = `${type}-${countsByType[type]}`
    return id
  }
}
