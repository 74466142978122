import React from 'react'

export const MedalIcon = (): JSX.Element => {
  return (
    <div className="mr-2">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0266 5.14284L14.2354 1.42856H10.4427L9.52699 4.29784C9.39735 4.30213 9.26771 4.30642 9.14014 4.31784L9.55388 3.10356L8.71881 1.42856H5.27096L6.47909 5.14284C5.12805 5.89773 4.05881 7.098 3.44075 8.55349C2.82269 10.009 2.69116 11.6365 3.06698 13.1782C3.4428 14.7199 4.30449 16.0876 5.51554 17.0646C6.7266 18.0417 8.21776 18.5723 9.75282 18.5723C11.2879 18.5723 12.779 18.0417 13.9901 17.0646C15.2012 16.0876 16.0628 14.7199 16.4387 13.1782C16.8145 11.6365 16.6829 10.009 16.0649 8.55349C15.4468 7.098 14.3776 5.89773 13.0266 5.14284ZM9.75316 17.275C8.63686 17.275 7.54563 16.9321 6.61745 16.2897C5.68928 15.6473 4.96586 14.7342 4.53867 13.6659C4.11148 12.5976 3.99971 11.4221 4.21749 10.288C4.43527 9.15388 4.97282 8.11215 5.76216 7.29451C6.55151 6.47687 7.5572 5.92005 8.65205 5.69447C9.7469 5.46888 10.8817 5.58466 11.9131 6.02716C12.9444 6.46967 13.8259 7.21902 14.4461 8.18046C15.0663 9.1419 15.3973 10.2722 15.3973 11.4286C15.3955 12.9785 14.8002 14.4645 13.7422 15.5605C12.6841 16.6565 11.2495 17.2731 9.75316 17.275Z"
          fill="#272727"
        />
        <path
          d="M9.75314 5.93924C8.70503 5.93924 7.68046 6.26118 6.80899 6.86435C5.93751 7.46752 5.25828 8.32483 4.85719 9.32787C4.45609 10.3309 4.35115 11.4346 4.55562 12.4994C4.7601 13.5642 5.26481 14.5423 6.00594 15.31C6.74707 16.0777 7.69132 16.6005 8.71929 16.8123C9.74726 17.0241 10.8128 16.9154 11.7811 16.5C12.7494 16.0845 13.5771 15.3809 14.1594 14.4782C14.7417 13.5755 15.0525 12.5142 15.0525 11.4285C15.0508 9.9732 14.492 8.57797 13.4985 7.54889C12.5051 6.51982 11.1581 5.94094 9.75314 5.93924ZM11.1606 12.0864C10.7372 12.6061 10.2453 13.0616 9.69936 13.4392C9.6173 13.4392 9.48007 13.4392 8.34987 12.0907C8.11541 11.8876 7.95108 11.611 7.88167 11.3026C7.81226 10.9943 7.84154 10.671 7.96509 10.3814C8.05852 10.2274 8.18635 10.099 8.33778 10.0069C8.4892 9.91491 8.65979 9.86197 8.83512 9.85258C9.01046 9.8432 9.1854 9.87765 9.34518 9.95302C9.50496 10.0284 9.64489 10.1425 9.75314 10.2857C9.86126 10.1423 10.0011 10.028 10.1609 9.9525C10.3206 9.87697 10.4956 9.84239 10.671 9.85171C10.8464 9.86103 11.017 9.91396 11.1685 10.006C11.32 10.0981 11.4478 10.2266 11.5412 10.3807C11.6644 10.6694 11.6939 10.9917 11.6252 11.2992C11.5566 11.6068 11.3936 11.883 11.1606 12.0864Z"
          fill="#272727"
        />
        <path
          d="M10.893 11.8607C11.2564 11.4 11.4929 11.0365 11.2378 10.5514C11.157 10.4126 11.0337 10.3056 10.8875 10.2477C10.7414 10.1897 10.5807 10.184 10.431 10.2314C10.292 10.2584 10.1661 10.3337 10.074 10.4449C9.98197 10.5561 9.92934 10.6965 9.92484 10.8429H9.58006C9.57616 10.6964 9.52373 10.5557 9.43157 10.4445C9.33941 10.3333 9.21313 10.2583 9.07391 10.2322C9.00635 10.2141 8.93683 10.2049 8.86704 10.205C8.74585 10.1999 8.62566 10.2296 8.51969 10.2907C8.41372 10.3518 8.32608 10.4421 8.26643 10.5514C8.01129 11.0386 8.24919 11.4015 8.61121 11.86C8.95123 12.2774 9.31262 12.6756 9.69384 13.0529C10.1227 12.6882 10.5236 12.2896 10.893 11.8607Z"
          fill="#272727"
        />
      </svg>
    </div>
  )
}
