export const DATA_TID = {
  // View 1 (UserInfoForm)
  FIELDS1: 'user-info-fields1',
  SUBMIT_BUTTON: 'user-info-submit-button',
  BIRTHDATE_INPUT: 'user-info-birthdate-input',
  GENDER_INPUT: 'user-info-gender-input',
  DESIRED_COVERAGE_INPUT: 'desired-coverage-input',
  HEALTH_INPUT: 'user-info-health-input',
  NICOTINE_INPUT: 'user-info-nicotine-input',
  ZIPCODE_INPUT: 'user-info-zipcode-input',
  ESTIMATED_CREDIT_INPUT: 'user-info-estimated-credit-input',
  DATE_FORMAT: 'mm/dd/yyyy',
  // View 2 (RangeForm)
  TERM_INPUT: 'range-form-term-input',
  COVERAGE_INPUT: 'range-form-coverage-input',
  SUBMIT_BUTTON2: 'range-form-submit-button2',
  // Email modal
  EMAIL_INPUT: 'save-estimates-email-input',
  EMAIL_BUTTON: 'save-estimates-email-button',
}
