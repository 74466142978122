import { BackAngularIcon, SaveIcon } from '@/icons'
import { Button, Caption2 } from 'ethos-design-system'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'

import { DATA_TID } from '@/components/EstimateWidget/common/data-tid'
import { useEstimateQuotesContext } from '@/components/EstimateWidget/contexts/EstimateQuotesContext'
import { useEstimateWidgetContext } from '@/components/EstimateWidget/contexts/EstimateWidgetContext'

type Flags = {
  asta?: boolean
  disableSave?: boolean
  cypressForm?: boolean
  salamanderForm?: boolean
  useRatesCopy?: boolean
}

interface IFormButtons {
  styles: any
  openEmailModal: () => void
  labels: Record<string, string>
  flags: Flags
}

type FormFooterButtonType = 'EDIT_ANSWER' | 'SAVE_ESTIMATE'

type FormFooterButtonProps = {
  label: string
  onClick: () => void
  labelStyle: string
  buttonStyle: string
  buttonType: FormFooterButtonType
  salamanderForm?: boolean
  cypressForm?: boolean
}

const FormFooterButton = (props: FormFooterButtonProps) => (
  <button onClick={props.onClick} className={props.buttonStyle}>
    <div className={props.buttonStyle}>
      {props.buttonType == 'EDIT_ANSWER' ? <BackAngularIcon /> : <SaveIcon />}
      <div className={props.labelStyle}>
        <Caption2.Regular400>{props.label}</Caption2.Regular400>
      </div>
    </div>
  </button>
)

export const FormButtons = ({
  flags,
  labels,
  openEmailModal,
  styles,
}: IFormButtons) => {
  const { continueLabel, editAnswersLabel, saveLabel } = labels

  const {
    asta = false,
    cypressForm = false,
    disableSave = false,
    salamanderForm = false,
    useRatesCopy = false,
  } = flags

  let CTAButton = salamanderForm
    ? Button.Medium.Salamander
    : cypressForm
    ? Button.Medium.Cypress
    : Button.Medium.Black

  if (asta) {
    CTAButton = Button.Unstyled
  }

  const {
    widgetProgressControls: { editAnswers },
  } = useEstimateWidgetContext()

  const { handleGoToMainApp } = useEstimateQuotesContext()

  const goToApp = () =>
    handleGoToMainApp({
      openInNewTab: disableSave,
      ratesCopy: useRatesCopy,
    })

  const handleEditAnswers = () => {
    estimateWidgetAnalytics.editAnswers()
    editAnswers()
  }

  return (
    <div className={styles.buttons}>
      <div className={asta ? styles.astaButton : styles.submitButton}>
        <CTAButton
          data-tid={DATA_TID.SUBMIT_BUTTON2}
          type="submit"
          onClick={goToApp}
        >
          {continueLabel}
        </CTAButton>
      </div>

      <div className={styles.rangeFormFieldsBottom}>
        <FormFooterButton
          label={editAnswersLabel}
          onClick={handleEditAnswers}
          labelStyle={styles.editAnswersLabel}
          buttonStyle={styles.editAnswersButton}
          buttonType="EDIT_ANSWER"
        />
        {!disableSave && (
          <FormFooterButton
            label={saveLabel}
            onClick={openEmailModal}
            labelStyle={styles.saveLabel}
            buttonStyle={asta ? styles.asta : styles.saveButton}
            buttonType="SAVE_ESTIMATE"
          />
        )}
      </div>
    </div>
  )
}
