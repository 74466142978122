import { FC } from 'react'

import Link from 'next/link'

import { COVERAGE_SLIDER, TOOLTIP, WHOLE_LIFE_BLURB } from '@/constants'
import { useLegalBannerContext } from '@/contexts/LegalBannerContext'
import { EstimateWidgetTooltip } from '@/shared/Estimate/EstimateWidgetTooltip'
import { PriceRangeDisplay } from '@/shared/Estimate/PriceRangeDisplay'
import { CoverageSlider } from '@/shared/Estimate/Sliders'
import { Button } from '@getethos/ethos-design-system-v2'
import classNames from 'classnames'
import { Body2, Caption2, Spacer } from 'ethos-design-system'
import { Product } from 'lib/@getethos/constants'

import { EstimateWidgetModuleData } from '@/components/EstimateWidget/EstimateWidget'
import { FinalExpenseCoverageSlider } from '@/components/EstimateWidget/common/FinalExpenseCoverageSlider'
import { DATA_TID } from '@/components/EstimateWidget/common/data-tid'
import { EstimateWidgetVariations } from '@/components/EstimateWidget/common/variations'
import { useEstimateQuotesContext } from '@/components/EstimateWidget/contexts/EstimateQuotesContext'

import { useDirectToAppUx } from '@/hooks/features/useDirectToAppUx'
import useDetectScreenSize from '@/hooks/useDetectScreenSize'

import floatingStyles from '../../../Floating.module.scss'
import { getStyles } from '../../FloatingRangeForm/FloatingRangeFormRefactor'
import styles from './DirectToAppRangeForm.module.scss'
import { TermButtons } from './components/TermButtons/TermButton'
import { TrustSignals } from './components/TrustSignals/TrustSignals'

interface DirectToAppRangeFormProps {
  moduleData: EstimateWidgetModuleData
}

export const DirectToAppRangeForm: FC<DirectToAppRangeFormProps> = ({
  moduleData: { showSinglePrice },
}) => {
  const { isMobile } = useDetectScreenSize()

  const {
    userSelectedValues,
    maxCoverage,
    handleCoverageChange,
    handleTermChange,
    previousRanges,
    ranges,
    terms,
    product,
    handleGoToMainApp,
  } = useEstimateQuotesContext()

  const { pricesClassName } = getStyles({
    asta: false,
    showSinglePrice: false,
    finalExpense: false,
  })

  const goToApp = () => handleGoToMainApp({})

  const shouldRenderFinalExpenseSlider = product === Product.FE

  const isGI = product === Product.GI

  const { isDisclaimerOpen, ref } = useLegalBannerContext()

  const { isVariant2, isVariant3 } = useDirectToAppUx()

  const dislaimerHeight = (isDisclaimerOpen && ref?.current?.clientHeight) || 0

  return (
    <section
      className={classNames(
        'mt-6 px-4 lg:mt-10',
        'min-h-[calc(100vh-68px)] lg:min-h-[calc(100vh-88px)]'
      )}
      style={{ marginBottom: isDisclaimerOpen ? dislaimerHeight : '16px' }}
    >
      <div className={classNames('mx-auto max-w-[500px]')}>
        <div className="rounded-3xl border-[1px] border-solid border-[--primary-cypress-15] px-5 py-6 lg:px-10 lg:py-10">
          <div className="mb-2 flex items-center">
            <h3
              className={classNames(
                isMobile ? 'display-s-med' : 'display-m-med',
                'text-[#272727]'
              )}
            >
              Your estimate
            </h3>
            {!isMobile && (
              <EstimateWidgetTooltip
                popperBoxStyles={floatingStyles.tooltipPopperBoxStyles}
                placement="right"
                softCorners
                directToApp
                details={TOOLTIP.ESTIMATE_RATE_DAILY.MESSAGE}
                label={TOOLTIP.ESTIMATE_RATE_DAILY.LABEL}
              />
            )}
          </div>
          <div
            className={classNames(pricesClassName.join(' '), 'max-w-[300px]')}
          >
            <PriceRangeDisplay
              styles={floatingStyles}
              minRangeStart={previousRanges.min}
              minRangeEnd={ranges.min}
              maxRangeStart={previousRanges.max}
              maxRangeEnd={ranges.max}
              variation={EstimateWidgetVariations.FLOATING_LEAN}
              dailyPremium
              directToApp
              showSinglePrice={showSinglePrice}
            />
          </div>
          <div className={styles.sliderWrapper}>
            {!shouldRenderFinalExpenseSlider && !isGI && (
              <CoverageSlider
                coverage={userSelectedValues?.coverage || 0}
                minCoverage={COVERAGE_SLIDER.MIN}
                maxCoverage={maxCoverage}
                coverageStep={COVERAGE_SLIDER.STEP}
                handleCoverageChange={handleCoverageChange}
                dataTid={DATA_TID.COVERAGE_INPUT}
                styles={floatingStyles}
                variation={EstimateWidgetVariations.FLOATING_LEAN}
                enableTooltip
                coverageLabel="Coverage amount"
                directToApp
              />
            )}
            {shouldRenderFinalExpenseSlider && !isGI && (
              <FinalExpenseCoverageSlider
                coverage={userSelectedValues?.coverage || 0}
                maxCoverage={maxCoverage}
                handleCoverageChange={handleCoverageChange}
                variation={EstimateWidgetVariations.FLOATING_LEAN}
                styles={floatingStyles}
                coverageLabel="Coverage amount"
                termLabel="Term length"
                directToApp
              />
            )}
            {isGI && (
              <>
                <div className="text-gray-3">
                  <Caption2.Regular400>Term length</Caption2.Regular400>
                </div>
                <Spacer.H4 />
                <Body2.Regular400>Whole Life</Body2.Regular400>
                <Spacer.H32 />
                <div className="text-gray-3">
                  <Caption2.Regular400>
                    {WHOLE_LIFE_BLURB.INTRO}
                  </Caption2.Regular400>
                </div>
                <Spacer.H24 />
                <Link href={WHOLE_LIFE_BLURB.LINK}>
                  <span className="text-forest underline">
                    {WHOLE_LIFE_BLURB.CTA}
                  </span>
                </Link>
              </>
            )}
          </div>
          {!shouldRenderFinalExpenseSlider && !isGI && (
            <div className="mt-4 lg:mt-8">
              <TermButtons
                maxTerm={terms[terms.length - 1]}
                initialTerm={userSelectedValues?.term || terms[0]}
                termToggle={handleTermChange}
              />
            </div>
          )}
          <div
            className={classNames({
              [styles.ctaButtonVariant2]: isVariant2,
              [styles.ctaButton]: !isVariant2,
            })}
            style={{ bottom: dislaimerHeight }}
          >
            <Button
              buttonTitle="Get my final rate"
              variant="salamander"
              size="lg"
              onClick={goToApp}
            />
          </div>
        </div>
        <div className="body-s-reg mt-6 text-center">
          This tool is intended for estimation purposes only.
        </div>
        {!isVariant3 && (
          <div className="mt-8 lg:mt-14">
            <TrustSignals />
          </div>
        )}
      </div>
    </section>
  )
}
