//@ts-nocheck

import '@reach/slider/styles.css'

import React, { useEffect, useState } from 'react'

import dynamic from 'next/dynamic'

import { estimateWidgetAnalytics } from '../../../../lib/@getethos/analytics/analyticsEvents'
import { useIntersectionObserver } from '../../../../lib/@getethos/hooks/useIntersectionObserver'
import { wrapLastTwoWords } from '../../../helpers/words'
import { useEmailModal } from '../../../shared/Estimate/EmailModal/useEmailModal'
import { getFieldsQuote } from '../../../shared/Estimate/getFields'
import { useFormState } from '../../../shared/Estimate/useFormState'
import { DATA_TID } from '../common/data-tid'
import { scrollToTop } from '../common/scrollToTop'
import styles from './HeroBoxed.module.scss'
import { HeroBoxedRangeForm } from './HeroBoxedRangeForm'
import { UserInfoForm } from './UserInfoForm'

const EmailModal = dynamic(
  () => import('../../../shared/Estimate/EmailModal/EmailModal'),
  { ssr: false }
)
export const HeroBoxedFormManager = ({
  moduleData,
  minimumEligibleAge,
  maximumEligibleAge,
}) => {
  const { variation } = moduleData
  let { subHeading, subHeading2 } = moduleData
  if (typeof subHeading === 'string' && subHeading.trim() !== '') {
    subHeading = wrapLastTwoWords(subHeading)
  }
  if (typeof subHeading2 === 'string' && subHeading2.trim() !== '') {
    subHeading2 = wrapLastTwoWords(subHeading2)
  }

  //experiment configs that we use to configure tests
  const featureFlagConfig = {
    useFinalExpensePriceRange: moduleData.useFinalExpensePriceRange,
  }

  const formState = useFormState(estimateWidgetAnalytics, featureFlagConfig)

  let augmentedUserData = formState.userData
  if (
    formState.userData &&
    formState.userData.birthDate &&
    typeof formState.userData.birthDate === 'string' &&
    formState.userData.birthDate.length == 2 &&
    typeof formState.userData.age == 'undefined'
  ) {
    augmentedUserData = {
      ...formState.userData,
      age: formState.userData.birthDate,
    }
  }

  const {
    saveEmailFormCallback,
    emailModal,
    openEmailModal,
    email,
    emailSuccess,
    emailModalClose,
  } = useEmailModal({
    userData: augmentedUserData,
    minPrice: formState.ranges.min,
    maxPrice: formState.ranges.max,
    term: formState.term,
    coverage: formState.coverage,
  })

  const [isIntersecting, formNode, updateFormNode] = useIntersectionObserver()

  const heroBoxedFields = getFieldsQuote({
    minimumEligibleAge,
    maximumEligibleAge,
    genderQuestion: moduleData.genderLabel,
    birthdateQuestion: moduleData.birthLabel,
    nicotineQuestion: moduleData.nicotineLabel,
    healthQuestion: moduleData.healthLabel,
    zipcodeQuestion: moduleData.zipLabel,
    displayEstimatedCredit: moduleData.displayEstimatedCredit,
    estimatedCreditQuestion: moduleData.estimatedCreditLabel || 'Credit Score',
    estimatedCreditFootnote: moduleData.estimatedCreditFootnote,
    dateFormat: DATA_TID.DATE_FORMAT,
    sendAnalyticsEvent: formState.sendAnalyticsEvent,
    dataTid: DATA_TID,
    userData: formState.userData,
  })
  const container = [
    styles.estimateForm,
    styles[variation],
    styles[moduleData.backgroundColor],
    styles.hideRangeForm, // this must be last in array for .pop() to work below
  ]

  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  // updates in css only work post gatsby hydration on prod
  if (hasMounted && formState.isUserDataComplete) {
    // go to second screen if user data is set
    container.pop()
    container.push(styles.hideInfoForm)
  }
  return (
    <div ref={updateFormNode} className={container.join(' ')}>
      <UserInfoForm
        heading={moduleData.heading}
        enableH1={moduleData.enableH1}
        headingSize={moduleData.headingSize}
        displaySubHeading={moduleData.displaySubHeading}
        handleSubmit={formState.setUserData}
        subHeading={subHeading}
        variation={variation}
        disclaimerText={moduleData.disclaimerText}
        scrollToTop={scrollToTop(formNode)}
        displayEstimatedCredit={moduleData.displayEstimatedCredit}
        fields={heroBoxedFields}
        ctaText={moduleData?.firstButton || 'Continue'}
      />

      <HeroBoxedRangeForm
        styles={styles}
        showError={formState.error}
        editAnswers={formState.editAnswers}
        heading={moduleData.heading2}
        displaySubHeading={moduleData.displaySubHeading2}
        subHeading={subHeading2}
        estimateCopy={wrapLastTwoWords(
          'The estimated monthly rate for this policy is:'
        )}
        toggleSlider={formState.toggleSlider}
        coverage={formState.coverage}
        term={formState.term}
        terms={formState.terms}
        ranges={formState.ranges}
        prevRanges={formState.prevRanges}
        coverageLabel={moduleData.coverageLabel}
        maxCoverage={formState.maxCoverage}
        product={formState.product}
        termLabel={moduleData.termLabel}
        backgroundColor={moduleData.backgroundColor}
        variation={variation}
        disclaimerText={moduleData.disclaimerText}
        openEmailModal={openEmailModal}
        saveLabel={moduleData.saveLabel}
        handleNavigateToApp={formState.handleNavigateToApp}
        continueLabel={moduleData.continueLabel}
      />

      <EmailModal
        emailModal={emailModal}
        emailModalClose={emailModalClose}
        email={email}
        emailSuccess={emailSuccess}
        emailFieldDataTid={DATA_TID.EMAIL_INPUT}
        buttonTid={DATA_TID.EMAIL_BUTTON}
        saveEmailFormCallback={saveEmailFormCallback}
        emailHeading={moduleData.emailHeading}
        emailSubHeading={moduleData.emailSubHeading}
        emailSendLabel={moduleData.emailSendLabel}
        variation={variation}
      />
    </div>
  )
}
