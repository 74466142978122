import * as moment from 'moment-timezone'

export default function isCurrentTimeInCentralWeekdayBetween8And5(): boolean {
  // Get the current time in the local timezone
  const currentTime = new Date()

  // Convert the current time to Central Time (CT)
  const ctTime = moment.tz(currentTime, 'America/Chicago')

  // Check if it's a weekday (Monday to Friday) and between 8 AM to 5 PM CT
  const isWeekday = ctTime.isoWeekday() >= 1 && ctTime.isoWeekday() <= 5 // Monday to Friday
  const isBetween8And5 = ctTime.hours() >= 8 && ctTime.hours() < 17 // 8 AM to 5 PM

  return isWeekday && isBetween8And5
}
