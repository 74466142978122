import React from 'react'

import Link from 'next/link'

import { Body, COLORS } from 'ethos-design-system'

import { trimFaqQuestionPreview } from '../../helpers/trimFaqQuestionPreview'
import { IconIntegratedTitle } from '../../helpers/words'
import { QuestionCtaArrow } from '../../icons'
import styles from './FaqSingleQuestionPreview.module.scss'

interface SingleQuestionParam {
  question: {
    url: string
    faqQuestionPreview?: string
    faqAnswerPreview?: string
  }
  wrapperClass?: string
  questionClass?: string
  index?: number
  keys?: string
}

/**
 * SingleQuestion is a single frequently asked question and answer preview snippet, linked to the full content.
 * It's consumed by the FaqQuestionsFeed component.
 */
const SingleQuestion = ({
  question,
  wrapperClass,
  questionClass,
}: SingleQuestionParam): JSX.Element | null => {
  if (question.faqQuestionPreview) {
    // trim down the copy to a uniform length among all questions, add ellipsis if required
    const title = trimFaqQuestionPreview(
      question.faqQuestionPreview,
      'question'
    )
    const body =
      question.faqAnswerPreview &&
      trimFaqQuestionPreview(question.faqAnswerPreview, 'answer')
    return (
      <div className={[styles.Single, wrapperClass].join(' ')}>
        <Link href={question.url}>
          <div className={[styles.Question, questionClass].join(' ')}>
            {question.faqQuestionPreview && (
              <Body.Medium500>
                <IconIntegratedTitle title={title}>
                  {QuestionCtaArrow}
                </IconIntegratedTitle>
              </Body.Medium500>
            )}
          </div>
          {question.faqAnswerPreview && (
            <Body.Regular400 color={COLORS.GRAY_SECONDARY}>
              {body}
            </Body.Regular400>
          )}
        </Link>
      </div>
    )
  } else {
    console.warn('FAQ content missing for:')
    console.warn(question)
    return null
  }
}

export default SingleQuestion
