import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export const zipCodeMask = createNumberMask({
  allowDecimal: false,
  allowLeadingZeroes: true,
  guide: false,
  includeThousandsSeparator: false,
  integerLimit: 5,
  prefix: '',
})

export const ageMask = createNumberMask({
  allowDecimal: false,
  allowLeadingZeroes: true,
  guide: false,
  includeThousandsSeparator: false,
  integerLimit: 3,
  prefix: '',
})
