import { FE_ESTIMATE_WIDGET_WITH_PHONE } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useFeEstWidgetWithPhone = ({
  demoTreatmentVariation,
}: {
  demoTreatmentVariation?: string
}) => {
  const { isLoading, variation } = useDecisionForFlag({
    name: FE_ESTIMATE_WIDGET_WITH_PHONE.EXPERIMENT_KEY,
    fallbackVariation: FE_ESTIMATE_WIDGET_WITH_PHONE.VARIATIONS.CONTROL,
  })

  const isDemoTreatment1Enabled =
    demoTreatmentVariation === 'fe-est-widget-with-phone-treatment-1'

  const isTreatment1 =
    variation === FE_ESTIMATE_WIDGET_WITH_PHONE.VARIATIONS.TREATMENT_1

  const isTreatment1Enabled = isTreatment1 || isDemoTreatment1Enabled

  return {
    isLoading,
    isTreatment1: isTreatment1Enabled,
  }
}
